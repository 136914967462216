import { formatDate } from '@scalingworks/react-admin-ui';
import { format, parse } from 'date-fns';
import { createHelpers, createResource } from '@scalingworks/refine-react-admin';
import { resourceNames } from './resource-names';
import { ServiceProviderVerificationStatusTag } from '~/components/service-provider-verification-status-tag';
import { BusinessApplicationStatusTag } from '~/components/business-application-status-tag';

import {
  type ServiceProvider,
  type BusinessApplication,
  type BusinessApplicationStatus,
  ServiceProviderVerificationStatus,
  type Order,
  type Customer,
  type Transaction,
  TransactionType,
  type OrderState,
  CountryCode,
} from '~/api';
import { OrderStateTag } from '~/components/order-state-tag';
import { MdGroup } from 'react-icons/md';
import { COUNTRY_CODES_TO_NAMES } from '~/utils/constant';

const {
  defineDefaults,
  defineFields,
  defineCardSection,
  defineStatsSection,
  defineTabsSection,
  defineShowPage,
  defineRelatedResourceSection,
} = createHelpers<ServiceProvider>({
  resourceName: resourceNames.serviceProvider,
});

const addressDefaults = defineDefaults({
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'Singapore',
  },
});

const profileTab = {
  label: 'Profile',
  content: [
    defineCardSection({
      title: 'General',
      fields: [
        'fullName',
        'mobileNumber',
        'email',
        'countryCode',
        'verificationStatus',
        'createdAt',
        'updatedAt',
      ],
      editControls: {
        email: {
          type: 'text',
          config: {
            type: 'email',
            label: 'Email',
            required: false,
          },
        },
        mobileNumber: {
          type: 'phone',
          config: {
            label: 'Mobile number',
            defaultCountry: 'sg',
          },
        },
        verificationStatus: {
          type: 'select',
          config: {
            label: 'Status',
            options: Object.entries(ServiceProviderVerificationStatus).map(([label, value]) => ({
              label,
              value,
            })),
          },
        },
        rating: false,
        createdAt: false,
        updatedAt: false,
      },
      componentConfigDefaults: {
        required: true,
      },
      displays: {
        countryCode: {
          label: 'Country',
          render: (data) => COUNTRY_CODES_TO_NAMES[data.countryCode],
        },
        verificationStatus: {
          label: 'Verification status',
          render: (data) => (
            <ServiceProviderVerificationStatusTag status={data.verificationStatus} />
          ),
        },
        createdAt: {
          label: 'Created at',
          type: 'date',
          formatType: 'dateAndTime',
        },
        updatedAt: {
          label: 'Updated at',
          type: 'date',
          formatType: 'dateAndTime',
        },
      },
    }),
    defineCardSection({
      title: 'Identity Documents',
      fields: ['identityDocument'],
      componentConfigDefaults: {
        required: false,
        hideLabel: true,
      },
      displays: {
        identityDocument: {
          label: 'Images',
          render: (data) => {
            const identityDocument = data.identityDocument;
            if (!identityDocument) {
              return <span> - </span>;
            }
            return (
              <div className="grid grid-cols-3 gap-4">
                <div className="flex flex-col items-center">
                  <img
                    src={identityDocument.frontImage}
                    alt="Front"
                    className="w-32 h-32 object-cover"
                  />
                  <span className="text-gray-500 text-sm">Front ID</span>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    src={identityDocument.backImage}
                    alt="Back"
                    className="w-32 h-32 object-cover"
                  />
                  <span className="text-gray-500 text-sm">Back ID</span>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    src={identityDocument.selfieImage}
                    alt="Selfie"
                    className="w-32 h-32 object-cover"
                  />
                  <span className="text-gray-500 text-sm">Selfie</span>
                </div>
              </div>
            );
          },
        },
      },
    }),

    // defineCardSection({
    //   title: 'Address',
    //   fields: [
    //     {
    //       address: ['line1', 'line2', 'city', 'state', 'postalCode', 'country'],
    //     },
    //   ],
    //   editControls: {
    //     'address.line1': {
    //       type: 'text',
    //       config: {
    //         label: 'Line 1',
    //       },
    //     },
    //     'address.line2': {
    //       type: 'text',
    //       config: {
    //         label: 'Line 2',
    //         required: false,
    //       },
    //     },
    //   },
    //   displays: {
    //     'address.line1': {
    //       label: 'Line 1',
    //       render: (data) => data.address?.line1,
    //     },
    //     'address.line2': {
    //       label: 'Line 2',
    //       render: (data) => data.address?.line2,
    //     },
    //   },
    //   defaultValues: addressDefaults,
    //   componentConfigDefaults: {
    //     required: true,
    //   },
    // }),
  ],
};

const applicationsTab = {
  label: 'Applications',
  content: [
    defineRelatedResourceSection<BusinessApplication>({
      relatedResourceName: resourceNames.businessApplication,
      fields: ['id', { service: ['name'] }, 'createdAt', 'status', { mainFacet : ['name'] }],
      getInitialFilter: ({ id }) => [
        {
          field: 'serviceProviderId',
          operator: 'eq',
          value: id,
        },
      ],
      defaultSorter: [{ field: 'createdAt', order: 'desc' }],
      columns: ({ LinkToDetails }) => [
        {
          id: 'serviceName',
          accessorKey: 'service.name',
          header: 'Service name',
          cell: (data) => {
            const name = data.cell.getValue<string>();

            return (
              <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
                {name} - {data.row.original.mainFacet.name}
              </LinkToDetails>
            );
          },
        },
        {
          id: 'createdAt',
          accessorKey: 'createdAt',
          header: 'Created date',
          cell: (data) => {
            const dateValue = data.cell.getValue<string>();

            return (
              dateValue &&
              formatDate(dateValue, { formatType: 'dateAndTime', format: 'dd MMM yyyy, hh:mm a' })
            );
          },
        },
        {
          id: 'status',
          accessorKey: 'status',
          header: 'Status',
          cell: (data) => (
            <BusinessApplicationStatusTag
              status={data.cell.getValue<BusinessApplicationStatus>()}
            />
          ),
        },
      ],
    }),
  ],
};

const bookingsTab = {
  label: 'Bookings',
  content: [
    defineRelatedResourceSection<Order>({
      relatedResourceName: resourceNames.order,
      fields: [
        'id',
        'orderNumber',
        'status',
        'state',
        'createdAt',
        {
          customer: ['id', 'fullName', 'mobileNumber'],
          totalEarningAmount: ['formattedAmount'],
          offering: [
            'id',
            'serviceId',
            'serviceProviderId',
            'displayName',
            {
              service: ['name'],
            },
          ],
          sessionInfo: [
            'date',
            'formattedDate',
            {
              sessionSlots: ['startTime', 'endTime'],
            },
          ],
        },
      ],
      getInitialFilter: ({ id }) => [
        {
          field: 'serviceProviderId',
          operator: 'eq',
          value: id,
        },
      ],
      defaultSorter: [{ field: 'createdAt', order: 'desc' }],
      columns: ({ LinkToDetails }) => [
        {
          id: 'id',
          accessorKey: 'id',
          header: 'Booking ID',
          cell: (data) => {
            const id = data.cell.getValue<string>();

            return (
              <LinkToDetails resourceId={id} className="font-semibold">
                {data.row.original.orderNumber}
              </LinkToDetails>
            );
          },
        },
        {
          id: 'serviceType',
          accessorKey: 'serviceType',
          header: 'Service type',
          cell: (data) => {
            const { service, displayName } = data.row.original.offering;

            return (
              <>
                <span>{service.name}</span>
                <br />
                <span className="text-gray-500">by {displayName}</span>
              </>
            );
          },
        },
        {
          id: 'createdAt',
          accessorKey: 'createdAt',
          header: 'Date',
          cell: (data) => {
            const dateValue = data.cell.getValue<string>();

            return (
              dateValue && formatDate(dateValue, { formatType: 'dateOnly', format: 'dd MMM yyyy' })
            );
          },
        },
        {
          id: 'time',
          accessorKey: 'time',
          header: 'Time',
          cell: (data) => {
            const slots = data.row.original.sessionInfo.sessionSlots;

            return (
              <>
                {slots &&
                  slots.map((slot, index) => (
                    <span key={index}>
                      {format(parse(slot.startTime, 'H:mm:ss', new Date()), 'H:mm b')} -
                      {format(parse(slot.endTime, 'H:mm:ss', new Date()), 'H:mm b')}
                      <br />
                    </span>
                  ))}
              </>
            );
          },
        },
        {
          id: 'customer',
          accessorFn: (row) => row.customer,
          header: 'Customer name',
          cell: (data) => {
            const customer = data.cell.getValue<Customer>();

            return customer ? (
              <LinkToDetails resourceName={resourceNames.customer} resourceId={customer.id}>
                {customer.fullName}
                <br />
                {customer.mobileNumber}
              </LinkToDetails>
            ) : (
              '-'
            );
          },
        },
        {
          id: 'state',
          accessorKey: 'state',
          cell: (data) => {
            const state = data.cell.getValue<OrderState>();
            return <OrderStateTag state={state} />;
          },
        },
        {
          id: 'formattedAmount',
          accessorKey: 'totalEarningAmount.formattedAmount',
          header: 'Amount',
        },
      ],
    }),
  ],
};

const createTransactionTab = (label: string, transactionType?: TransactionType) => {
  const tab = {
    label,
    content: [
      defineRelatedResourceSection<Transaction>({
        relatedResourceName: resourceNames.transaction,
        fields: [
          'type',
          'referenceId',
          'status',
          'createdAt',
          {
            amount: ['formattedAmount'],
          },
        ],
        getInitialFilter: ({ id }) => [
          {
            field: 'userId',
            operator: 'eq',
            value: id,
          },
        ],
        defaultSorter: [{ field: 'createdAt', order: 'desc' }],
        columns: () => [
          {
            id: 'referenceId',
            accessorKey: 'referenceId',
          },
          {
            id: 'type',
            accessorKey: 'type',
          },
          {
            id: 'formattedAmount',
            accessorKey: 'amount.formattedAmount',
            header: 'Amount',
          },
          {
            id: 'createdAt',
            accessorKey: 'createdAt',
            header: 'Transaction Date',
            cell: (data) => {
              const dateValue = data.cell.getValue<string>();

              return (
                dateValue &&
                formatDate(dateValue, { formatType: 'dateAndTime', format: 'dd MMM yyyy, hh:mm a' })
              );
            },
          },
        ],
      }),
    ],
  };

  if (transactionType) {
    tab.content[0].getInitialFilter = ({ id }) => [
      {
        field: 'userId',
        operator: 'eq',
        value: id,
      },
      {
        field: 'type',
        operator: 'eq',
        value: transactionType,
      },
    ];
  } else {
    tab.content[0].getInitialFilter = ({ id }) => [
      {
        field: 'userId',
        operator: 'eq',
        value: id,
      },
    ];
  }

  return tab;
};

export const serviceProviderResource = createResource({
  name: resourceNames.serviceProvider,
  label: 'Service Providers',
  icon: <MdGroup />,
  fields: defineFields([
    'id',
    'fullName',
    'profilePicture',
    'mobileNumber',
    'email',
    'verificationStatus',
    'identityNumber',
    'createdAt',
    'updatedAt',
    'countryCode',
    {
      businessStates: ['name'],
    },
  ]),
  defaultValues: {
    fullName: '',
    mobileNumber: '',
    email: '',
    password: '',
    ...addressDefaults,
  },
  controls: {
    components: ({ action }) => ({
      mobileNumber: {
        type: 'phone',
        config: {
          defaultCountry: 'sg',
          label: 'Mobile number',
        },
      },
      password:
        action === 'edit'
          ? false
          : {
              type: 'password',
              config: {
                label: 'Password',
                autoComplete: 'new-password',
              },
            },
    }),
    sections: [
      {
        title: 'General',
        items: ['fullName', 'mobileNumber', 'email', 'password', 'colorRating'],
        componentConfigDefaults: {
          required: true,
        },
      },
    ],
  },
  show: defineShowPage({
    title: (data) => (
      <div className="inline-flex items-center gap-2">
        <h1 className="text-2xl">{data.fullName}</h1>
      </div>
    ),
    sections: [
      defineStatsSection({
        fields: [
          'ordersCount',
          'profilePicture',
          { totalEarnings: ['formattedAmount'] },
          'colorRating',
        ],
        imageField: 'profilePicture',
        stats: [
          {
            label: 'No. of Bookings',
            value: (data) => data.ordersCount,
          },
          {
            label: 'Total Earnings',
            value: (data) => data.totalEarnings.formattedAmount,
          },
          {
            label: 'Color Rating',
            value: (data) => (
              <div
                className={`bg-${
                  data.colorRating.toLowerCase() === 'black'
                    ? data.colorRating.toLowerCase()
                    : data.colorRating.toLowerCase() + '-500'
                } h-5 w-20`}
              ></div>
            ),
          },
        ],
        allowChangeImage: true,
      }),
      defineTabsSection({
        tabs: [
          profileTab,
          applicationsTab,
          bookingsTab,
          createTransactionTab('Earnings', TransactionType.Earning),
          createTransactionTab('Withdrawals', TransactionType.Withdrawal),
          createTransactionTab('Transaction History'),
        ],
      }),
    ],
  }),
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  allowSearch: true,
  allowEdit: false,
  allowCreate: false,
  filterControls: {
    verificationStatus: {
      type: 'multiselect',
      config: {
        label: 'Status',
        options: Object.entries(ServiceProviderVerificationStatus).map(([label, value]) => ({
          label,
          value,
        })),
        placeholder: 'All statuses',
      },
    },
    countryCode: {
      type: 'multiselect',
      config: {
        label: 'Country',
        options: Object.entries(CountryCode).map(([label, value]) => {
          const countryName = COUNTRY_CODES_TO_NAMES[value];
          return { label: countryName, value };
        }),
        placeholder: 'All countries',
      },
    },
  },
  columns: ({ LinkToDetails }) => [
    {
      id: 'name',
      accessorKey: 'fullName',
      header: 'Name',
      enableSorting: false,
      cell: (data) => {
        const name = data.cell.getValue<string>();
        const identityNumber = data.row.original.identityNumber;
        const id = data.row.original.id;

        return (
          <LinkToDetails resourceId={id}>
            <span>{name}</span>
            <br />
            <span className="text-gray-500">{identityNumber}</span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'serviceType',
      accessorKey: 'serviceType',
      header: 'Service Type',
      cell: (data) => {
        const serviceTypes = data.row.original.businessStates.map((business) => business.name);
        const uniqueServiceType = [...new Set(serviceTypes)];

        return (
          <ul className="list-disc">
            {uniqueServiceType.map((type) => (
              <li>{type}</li>
            ))}
          </ul>
        );
      },
    },
    {
      id: 'createdAt',
      accessorKey: 'createdAt',
      header: 'Created At',
      cell: (data) => {
        const dateValue = data.cell.getValue<string>();

        return (
          dateValue &&
          formatDate(dateValue, { formatType: 'dateAndTime', format: 'dd MMM yyyy, hh:mm a' })
        );
      },
    },
    {
      id: 'updatedAt',
      accessorKey: 'updatedAt',
      header: 'Updated At',
      cell: (data) => {
        const dateValue = data.cell.getValue<string>();

        return (
          dateValue &&
          formatDate(dateValue, { formatType: 'dateAndTime', format: 'dd MMM yyyy, hh:mm a' })
        );
      },
    },
    {
      id: 'country',
      accessorFn: (row) => row.countryCode,
      header: 'Country',
      cell: (data) => {
        const countryCode = data.cell.getValue<CountryCode>();
        return COUNTRY_CODES_TO_NAMES[countryCode];
      },
    },
    {
      id: 'verificationStatus',
      accessorKey: 'verificationStatus',
      header: 'Status',
      cell: (data) => {
        const status = data.row.original.verificationStatus;
        return <ServiceProviderVerificationStatusTag status={status} displayText={status} />;
      },
    },
  ],
});
