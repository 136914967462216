import * as React from 'react';
import { Button, Dialog } from '@scalingworks/react-admin-ui';
import { BusinessApplicationCancelForm } from './business-application-reject-form';
import { BusinessApplicationApproveForm } from './business-application-approve-form';

export const BusinessApplicationActions = (props: {
  applicationId: string;
	onSuccess: () => void;
}) => {
  const [showRejectDialog, setShowRejectDialog] = React.useState(false);
  const [showApproveDialog, setShowApproveDialog] = React.useState(false);

  return (
    <div>
			<Button
				type="button"
				variant="default"
				className="mr-2 rounded inline-block text-sm text-red-500 border-red-500"
				onClick={() => setShowRejectDialog(true)}
			>
				Reject
			</Button>

			<Button
				type="button"
				variant="solid"
				className="rounded inline-block text-sm border-orange-500"
				onClick={() => setShowApproveDialog(true)}
			>
				Approve
			</Button>
			<Dialog.Root open={showRejectDialog} onOpenChange={setShowRejectDialog}>
				<Dialog.Portal>
					<Dialog.Overlay />
					<Dialog.Content className="pl-0 pt-0 pb-0 pr-0 w-5/6 lg:w-2/4">
						<BusinessApplicationCancelForm
							applicationId={props.applicationId}
							onSuccess={() => {
								props.onSuccess();
								setShowRejectDialog(false);
							}}
							onClose={() => setShowRejectDialog(false)}
						/>
					</Dialog.Content>
				</Dialog.Portal>
			</Dialog.Root>
			<Dialog.Root open={showApproveDialog} onOpenChange={setShowApproveDialog}>
				<Dialog.Portal>
					<Dialog.Overlay />
					<Dialog.Content className="pl-0 pt-0 pb-0 pr-0 w-5/6 lg:w-2/4">
						<BusinessApplicationApproveForm
							applicationId={props.applicationId}
							onSuccess={() => {
								props.onSuccess();
								setShowRejectDialog(false);
							}}
							onClose={() => setShowApproveDialog(false)}
						/>
					</Dialog.Content>
				</Dialog.Portal>
			</Dialog.Root>
    </div>
  );
};