import { useGraphQLClient } from '@scalingworks/refine-react-admin';
import * as React from 'react';
import { getSdk } from '~/api';

export const EmbedDashboardViewer = (props: { id: string }) => {
  const client = useGraphQLClient();
  const [iframeUrl, setIframeUrl] = React.useState<string | null>(null);
  React.useEffect(() => {
    getSdk(client).getDashboardEmbedUrl({ id: props.id }).then((data) => {
      setIframeUrl(data.getDashboardEmbedUrl.iframeUrl);
    });
}, [props.id]);


  const iframeRef = React.useRef<HTMLIFrameElement>(null);

  return iframeUrl ? (
    <iframe
      src={iframeUrl}
      width="100%"
      height="100%"
      allowTransparency
      onLoad={() => {
        if (window.iFrameResize && iframeRef.current) {
          window.iFrameResize({}, iframeRef.current);
        }
      }}
      ref={iframeRef}
    />
  ) : null;
};
