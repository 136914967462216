import { WithdrawalRequestApproveForm } from './withdrawal-request-approve-form';
import { Button, Dialog } from '@scalingworks/react-admin-ui';
import * as React from 'react';

export const WithdrawalRequestActions = (props: {
  withdrawalId: string;
  onSuccess: () => void;
}) => {
  const [showApproveDialog, setShowApproveDialog] = React.useState(false);

  return (
    <div>
      <Button
        type="button"
        variant="solid"
        className="rounded inline-block text-sm border-orange-500"
        onClick={() => setShowApproveDialog(true)}
      >
        Mark as Process
      </Button>
      <Dialog.Root open={showApproveDialog} onOpenChange={setShowApproveDialog}>
        <Dialog.Portal>
          <Dialog.Overlay />
          <Dialog.Content className="pl-0 pt-0 pb-0 pr-0 w-5/6 lg:w-2/4">
            <WithdrawalRequestApproveForm
              withdrawalId={props.withdrawalId}
              onSuccess={() => {
                props.onSuccess();
                setShowApproveDialog(false);
              }}
              onClose={() => setShowApproveDialog(false)}
            />
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
};
