import { resourceNames } from './resource-names';
import { createHelpers, createResource } from '@scalingworks/refine-react-admin';
import { HiOutlineCog } from 'react-icons/hi';
import { type SystemSetting } from '~/api';
import { SystemSettings } from '~/modules/system-settings';

const { defineCustomPage, defineFields } = createHelpers<SystemSetting>({
  resourceName: resourceNames.systemSetting,
});

export const systemSettingResource = createResource({
  name: resourceNames.systemSetting,
  label: 'Platform Settings',
  icon: <HiOutlineCog />,
  defaultValues: {},
  fields: defineFields(['id', 'createdAt', 'updatedAt', 'description', 'group', 'key', 'value']),
  columns: () => [],
  list: defineCustomPage({
    title: 'Platform Settings',
    render: () => {
      return <SystemSettings />;
    },
  }),
});
