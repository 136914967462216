import { Tag, type TagProps } from '@scalingworks/react-admin-ui';
import type { PayoutStatus } from '~/api';

const colorByStatus: Record<PayoutStatus, TagProps['color']> = {
  Failed: 'red',
  Paid: 'green',
  Processing: 'gray',
  Scheduled: 'amber',
};

export const PayoutStatusTag = ({
  status,
  displayText = status,
}: {
  status: PayoutStatus;
  displayText?: string;
}) => <Tag color={colorByStatus[status]}>{displayText}</Tag>;
