import { Tag, type TagProps } from '@scalingworks/react-admin-ui';

import type { ServiceProviderVerificationStatus } from '~/api';

const colorByStatus: Record<ServiceProviderVerificationStatus, TagProps['color']> = {
  Pending: 'amber',
  Rejected: 'red',
  Submitted: 'blue',
  Verified: 'green',
};

export const ServiceProviderVerificationStatusTag = ({
	status,
	displayText = status,
}: {
	status: ServiceProviderVerificationStatus;
	displayText?: string;
}) => <Tag color={colorByStatus[status]}>{displayText}</Tag>;
