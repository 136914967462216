import {
  AllowCheckInBeforeInMinutes,
  AllowCheckInWithinInMinutes,
  MarkSesionAsLateAfterInMinutes,
  MinimumTimeBetweenSessionSlotInMinutes,
  SystemSettingsBooking,
} from './system-settings-booking';
import {
  AdministrationFeeForManualRefundRequestInPercentage,
  ServiceChargeForEarlyWithdrawalOfEarningsInPercentage,
  SystemSettingsServiceFee,
} from './system-settings-service-fee';
import { useList } from '@refinedev/core';
import { Card, Spinner, Tabs } from '@scalingworks/react-admin-ui';
import { Content } from '@scalingworks/refine-react-admin';
import { useState } from 'react';
import { type SystemSetting } from '~/api';
import { resourceNames } from '~/resources/resource-names';

// eslint-disable-next-line @typescript-eslint/ban-types
type SystemSettingsProps = {};

const options = [
  {
    label: 'Booking',
  },
  {
    label: 'Service fee',
  },
];

export const SystemSettings = (_props: SystemSettingsProps) => {
  const [activeTab, selectTab] = useState(0);
  const { data, isLoading } = useList<SystemSetting>({
    resource: resourceNames.systemSetting,
    metaData: {
      fields: ['id', 'group', 'value', 'description', 'key', 'createdAt', 'updatedAt'],
    },
  });

  const settingsMap = new Map<string, number>(
    data?.data?.map((item) => [item.key, item.value]) || []
  );
  return (
    <Content title="Platform settings">
      <Card>
        <Tabs.PlainList className={'@container/listTab'}>
          <div className="@xl/listTab:flex items-center">
            {options.map((tab, index) => {
              const active = activeTab === index;

              return (
                <Tabs.PlainButton onClick={() => selectTab(index)} active={active} key={index}>
                  {tab.label}
                </Tabs.PlainButton>
              );
            })}
          </div>
        </Tabs.PlainList>
        {isLoading ? (
          <Spinner size="xl" isSpining={isLoading} />
        ) : (
          <div className="p-4">
            {activeTab === 0 ? (
              <SystemSettingsBooking
                defaultValues={{
                  [AllowCheckInWithinInMinutes]: settingsMap.get(AllowCheckInWithinInMinutes),
                  [AllowCheckInBeforeInMinutes]: settingsMap.get(AllowCheckInBeforeInMinutes),
                  [MarkSesionAsLateAfterInMinutes]: settingsMap.get(MarkSesionAsLateAfterInMinutes),
                  [MinimumTimeBetweenSessionSlotInMinutes]: settingsMap.get(
                    MinimumTimeBetweenSessionSlotInMinutes
                  ),
                }}
              />
            ) : (
              <SystemSettingsServiceFee
                defaultValues={{
                  [ServiceChargeForEarlyWithdrawalOfEarningsInPercentage]: settingsMap.get(
                    ServiceChargeForEarlyWithdrawalOfEarningsInPercentage
                  ),
                  [AdministrationFeeForManualRefundRequestInPercentage]: settingsMap.get(
                    AdministrationFeeForManualRefundRequestInPercentage
                  ),
                }}
              />
            )}
          </div>
        )}
      </Card>
    </Content>
  );
};
