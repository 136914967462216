import { Tag, type TagProps } from '@scalingworks/react-admin-ui';

import { OrderState, OrderRefundStatus } from '~/api';

const colorByState: Record<OrderState, TagProps['color']> = {
	Confirmed: 'green',
	Completed: 'green',
	Cancelled: 'red',
  CheckedIn: 'blue',
  Created: 'gray',
  Failed: 'red',
  PendingConfirmation: 'amber',
  RefundProcessed: 'green',
  Rescheduled: 'blue'
};

export const OrderStateTag = ({
  state,
  refundStatus,
}: {
  state: OrderState;
  refundStatus?: OrderRefundStatus | null;
}) => (
  <Tag
    color={
			refundStatus &&
      (refundStatus === OrderRefundStatus.NotApplicable ||
      refundStatus === OrderRefundStatus.NotEligible)
     ? 'red' : colorByState[state]}
  >
    {OrderStateText(state, refundStatus)}
  </Tag>
);

export const OrderStateText = (state: OrderState, refundStatus?: OrderRefundStatus | null): string => {
	switch (state) {
		case OrderState.PendingConfirmation:
			return 'Pending Confirmation';
		case OrderState.RefundProcessed:
			if ((refundStatus === OrderRefundStatus.NotApplicable) || (refundStatus === OrderRefundStatus.NotEligible)) {
				return 'Forfeited';
			}
      return 'Refund Processed';
		default:
			return state;
	}
}