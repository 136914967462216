import { Tag, formatDate } from '@scalingworks/react-admin-ui';
import { createHelpers, createResource } from '@scalingworks/refine-react-admin';
import { FiUser } from 'react-icons/fi';
import {
  type Customer,
  CustomerStatus,
  type Order,
  OrderState,
  Transaction,
} from '~/api';

import { resourceNames } from './resource-names';
import { OrderStateTag } from '~/components/order-state-tag';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

const {
  defineFields,
  defineCardSection,
  defineStatsSection,
  defineTabsSection,
  defineRelatedResourceSection,
  defineShowPage,
} = createHelpers<Customer>({
  resourceName: resourceNames.customer,
});

export const customerResource = createResource({
  name: resourceNames.customer,
  label: 'Customers',
  icon: <FiUser />,
  fields: defineFields([
    'id',
    'fullName',
    'identityNumber',
    'mobileNumber',
    'dateOfBirth',
    'email',
    'status',
    'ordersCount',
    {
      wallets: ['id'],
    },
  ]),
  filterControls: {
    fullName: {
      type: 'text',
      config: {
        label: 'Name',
      },
      operator: 'contains',
    },
    mobileNumber: {
      type: 'text',
      config: {
        label: 'Phone number',
      },
      operator: 'contains',
    },
    email: {
      type: 'text',
      config: {
        label: 'Email',
      },
      operator: 'contains',
    },
    status: {
      type: 'multiselect',
      config: {
        label: 'Status',
        options: Object.entries(CustomerStatus).map(([label, value]) => ({
          label,
          value,
        })),
        placeholder: 'All statuses',
      },
    },
  },
  defaultValues: {
    fullName: '',
    identityNumber: '',
    mobileNumber: '',
    email: '',
    dateOfBirth: '',
  },
  allowCreate: false,
  allowEdit: false,
  allowSearch: true,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  columns: ({ LinkToDetails }) => [
    {
      id: 'fullName',
      accessorKey: 'fullName',
      header: 'Name',
      enableSorting: true,
      cell: (data) => {
        const name = data.cell.getValue<string>();
        const id = data.row.original.id;
        const identityNumber = data.row.original.identityNumber;

        return (
          <LinkToDetails resourceId={id} className="flex flex-col gap-2 group/name">
            <span className="font-semibold">{name}</span>
            <span>{identityNumber}</span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'mobileNumber',
      accessorKey: 'mobileNumber',
      header: 'Contact no.',
      enableSorting: true,
    },
    {
      id: 'email',
      accessorKey: 'email',
      header: 'Email',
      enableSorting: true,
    },
    {
      id: 'status',
      accessorKey: 'status',
    },
  ],
  show: defineShowPage({
    title: (data) => (
      <div className="inline-flex items-center gap-2">
        <h1 className="text-2xl">{data.fullName}</h1>
        <Tag color={colorByStatus[data.status]}>{data.status}</Tag>
      </div>
    ),
    sections: [
      defineStatsSection({
        fields: [
          'profilePicture',
          'ordersCount',
          { wallets: [{ balance: ['formattedAmount'] }] },
          'colorRating',
        ],
        imageField: 'profilePicture',
        stats: [
          {
            label: 'No. of booking',
            value: (data) => data.ordersCount,
          },
          {
            label: 'Wallet',
            value: (data) => data.wallets[0]?.balance.formattedAmount,
          },
          {
            label: 'Color Rating',
            value: (data) => (
              <div
                className={`bg-${
                  data.colorRating.toLowerCase() === 'black'
                    ? data.colorRating.toLowerCase()
                    : data.colorRating.toLowerCase() + '-500'
                } h-5 w-20`}
              ></div>
            ),
          },
        ],
        allowChangeImage: true,
      }),
      defineTabsSection({
        tabs: [
          {
            label: 'Details',
            content: [
              defineCardSection({
                title: 'General',
                fields: [
                  'fullName',
                  'status',
                  'mobileNumber',
                  'dateOfBirth',
                  'email',
                  'createdAt',
                  'updatedAt',
                ],
                displays: {
                  dateOfBirth: {
                    label: 'Date of birth',
                    type: 'date',
                    formatType: 'dateOnly',
                  },
                  createdAt: {
                    label: 'Created at',
                    type: 'date',
                    formatType: 'dateAndTime',
                  },
                  updatedAt: {
                    label: 'Updated at',
                    type: 'date',
                    formatType: 'dateAndTime',
                  },
                },
                editControls: {
                  dateOfBirth: {
                    type: 'date',
                    config: {
                      label: 'Date of birth',
                    },
                  },
                  createdAt: false,
                  updatedAt: false,
                  status: {
                    type: 'select',
                    config: {
                      label: 'Status',
                      options: Object.entries(CustomerStatus).map(([label, value]) => ({
                        label,
                        value,
                      })),
                    },
                  },
                },
              }),
            ],
          },
          {
            label: 'Bookings',
            content: [
              defineRelatedResourceSection<Order>({
                relatedResourceName: resourceNames.order,
                fields: [
                  'id',
                  'orderNumber',
                  'state',
                  'createdAt',
                  {
                    payments: [
                      'id',
                      {
                        amount: ['amount', 'currencyCode'],
                      },
                    ],
                    offering: [
                      'id',
                      'serviceId',
                      'serviceProviderId',
                      'displayName',
                      {
                        service: ['name'],
                      },
                    ],
                    sessionInfo: [
                      'date',
                      'formattedDate',
                      {
                        sessionSlots: ['startTime', 'endTime'],
                      },
                    ],
                  },
                ],
                getInitialFilter: ({ id }) => [
                  {
                    field: 'customerId',
                    operator: 'eq',
                    value: id,
                  },
                ],
                defaultSorter: [{ field: 'createdAt', order: 'desc' }],
                columns: ({ LinkToDetails }) => [
                  {
                    id: 'id',
                    accessorKey: 'id',
                    header: 'Booking ID',
                    cell: (data) => {
                      const id = data.cell.getValue<string>();

                      return (
                        <LinkToDetails resourceId={id} className="font-semibold">
                          {data.row.original.orderNumber}
                        </LinkToDetails>
                      );
                    },
                  },
                  {
                    id: 'serviceType',
                    accessorKey: 'serviceType',
                    header: 'Service type',
                    cell: (data) => {
                      const { service, displayName } = data.row.original.offering;

                      return (
                        <>
                          <span>{service.name}</span>
                          <br />
                          <span className="text-gray-500">by {displayName}</span>
                        </>
                      );
                    },
                  },
                  {
                    id: 'createdAt',
                    accessorKey: 'createdAt',
                    header: 'Date',
                    cell: (data) => {
                      const dateValue = data.cell.getValue<string>();

                      return (
                        dateValue &&
                        formatDate(dateValue, { formatType: 'dateOnly', format: 'dd MMM yyyy' })
                      );
                    },
                  },
                  {
                    id: 'time',
                    accessorKey: 'time',
                    header: 'Time',
                    cell: (data) => {
                      const slots = data.row.original.sessionInfo.sessionSlots;

                      return (
                        <>
                          {slots &&
                            slots.map((slot, index) => (
                              <span key={index}>
                                {format(parse(slot.startTime, 'H:mm:ss', new Date()), 'H:mm b')} -
                                {format(parse(slot.endTime, 'H:mm:ss', new Date()), 'H:mm b')}
                                <br />
                              </span>
                            ))}
                        </>
                      );
                    },
                  },
                  {
                    id: 'offering',
                    accessorFn: (row) => row.offering,
                    header: 'Service Provicer Name',
                    cell: (data) => {
                      const { serviceProviderId, displayName } = data.row.original.offering;

                      return displayName ? (
                        <LinkToDetails
                          resourceName={resourceNames.serviceProvider}
                          resourceId={serviceProviderId}
                        >
                          {displayName}
                        </LinkToDetails>
                      ) : (
                        '-'
                      );
                    },
                  },
                  {
                    id: 'state',
                    accessorKey: 'state',
                    cell: (data) => {
                      const state = data.cell.getValue<OrderState>();
                      return <OrderStateTag state={state} />;
                    },
                  },
                  {
                    id: 'amount',
                    accessorKey: 'amount',
                    header: 'Amount',
                    cell: (data) => {
                      const totalAmount = data.row.original.payments.reduce(
                        (acc, obj) => acc + +obj.amount.amount,
                        0
                      );
                      const currencyCode =
                        data.row.original.payments?.[0]?.amount?.currencyCode ?? 'credits';
                      return `${Math.abs(totalAmount)} ${currencyCode}`;
                    },
                  },
                ],
              }),
            ],
          },
          {
            label: 'Transaction history',
            content: [
              defineRelatedResourceSection<Transaction>({
                relatedResourceName: resourceNames.transaction,
                fields: [
                  'id',
                  'type',
                  'createdAt',
                  {
                    amount: ['formattedAmount'],
                  },
                ],
                getInitialFilter: ({ id }) => [
                  {
                    field: 'userId',
                    operator: 'eq',
                    value: id,
                  },
                ],
                defaultSorter: [{ field: 'createdAt', order: 'desc' }],
                columns: () => [
                  {
                    id: 'type',
                    accessorKey: 'type',
                    header: 'Adjustment Type',
                    cell: (data) => data.cell.getValue<string>(),
                  },
                  {
                    id: 'amount.formattedAmount',
                    accessorKey: 'amount.formattedAmount',
                    header: 'Amount',
                    cell: (data) => data.cell.getValue<string>(),
                  },
                  {
                    id: 'createdAt',
                    accessorKey: 'createdAt',
                    header: 'Created Date',
                    cell: (data) => {
                      const dateValue = data.cell.getValue<string>();

                      return formatDate(dateValue, {
                        formatType: 'dateAndTime',
                        format: 'dd MMM yyyy, hh:mm a',
                      });
                    },
                  },
                ],
              }),
            ],
          },
        ],
      }),
    ],
  }),
});

const colorByStatus = {
  Active: 'green',
  Blocked: 'red',
  Deleted: 'gray',
} as const;
