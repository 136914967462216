import { GraphQLError } from 'graphql';

type GqlException = {
  request: unknown;
  response: {
    errors: GraphQLError[];
  };
};

export const isGraphqlError = (error: unknown): error is GqlException => {
  if (typeof error === 'object' && error) {
    const errorObj = error;
    if ('response' in errorObj) {
      const response = errorObj['response'];
      if (typeof response === 'object' && response !== null) {
        const responseObj = response as Record<string, unknown>;
        return 'errors' in responseObj && Array.isArray(responseObj['errors']);
      }
    }
  }
  return false;
};
