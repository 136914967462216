import { useCustomMutation } from '@refinedev/core';
import { Button, Card } from '@scalingworks/react-admin-ui';
import { PayoutStatus } from '~/api';

export const PayoutRequestApproveForm = (props: {
  payoutId: string;
  onSuccess: () => void;
  onClose: () => void;
}) => {
  const mutation = useCustomMutation();

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();

        mutation.mutate(
          {
            method: 'post',
            url: '',
            values: {},
            metaData: {
              operation: 'updatePayout',
              fields: ['id'],
              variables: {
                data: {
                  type: 'PayoutUpdateInputV2!',
                  value: {
                    status: PayoutStatus.Paid,
                  },
                },
                where: {
                  type: 'PayoutWhereUniqueInput!',
                  value: {
                    id: props.payoutId,
                  },
                },
              },
            },
            successNotification: {
              message: 'Payout processed',
              type: 'success',
            },
          },
          {
            onSuccess: props.onSuccess,
          }
        );
      }}
    >
      <Card>
        <Card.Header bordered className="text-xl font-bold">
          Mark payout as processed?
        </Card.Header>
        <Card.Body>Continue to mark payout as processed?</Card.Body>
        <Card.Footer className="text-right">
          <Button
            className="mr-2 rounded inline-block text-sm text-red-500 border-red-500"
            type="button"
            variant="default"
            size="sm"
            onClick={props.onClose}
          >
            Close
          </Button>

          <Button
            type="submit"
            className="rounded inline-block text-sm border-orange-500"
            variant="solid"
            size="sm"
            loading={mutation.isLoading}
          >
            Yes, mark as processed
          </Button>
        </Card.Footer>
      </Card>
    </form>
  );
};
