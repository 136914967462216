import * as React from 'react';
import { Button, Card, TextField } from '@scalingworks/react-admin-ui';
import { useCustomMutation } from '@refinedev/core';

export const BusinessApplicationApproveForm = (props: { 
  applicationId: string; 
  onSuccess: () => void;
  onClose: () => void;
 }) => {
  const mutation = useCustomMutation();

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();

        mutation.mutate(
          {
            method: 'post',
            url: '',
            values: {},
            metaData: {
              operation: 'approveBusinessApplication',
              fields: ['id'],
              variables: {
                id: {
                  type: 'String',
                  required: true,
                  value: props.applicationId,
                },
              },
            },
            successNotification: {
              message: 'Application approved',
              type: 'success',
            },
          },
          {
            onSuccess: props.onSuccess,
          },
        )
      }}
    >
      <Card>
        <Card.Header bordered className="text-xl font-bold">Approve application?</Card.Header>
        <Card.Body>
          Continue to approve application?
        </Card.Body>
        <Card.Footer className="text-right">
          <Button 
            className="mr-2 rounded inline-block text-sm text-red-500 border-red-500"
            type="button" 
            variant="default" 
            size="sm"
            onClick={props.onClose}
          >
            Close
          </Button>

          <Button 
            type="submit" 
            className="rounded inline-block text-sm border-orange-500"
            variant="solid" 
            size="sm"
            loading={mutation.isLoading}
          >
            Yes, appprove
          </Button>
        </Card.Footer>
      </Card>
    </form>
  );
};
