import { Tag, type TagProps } from '@scalingworks/react-admin-ui';
import type { WithdrawalStatus } from '~/api';

const colorByStatus: Record<WithdrawalStatus, TagProps['color']> = {
  Failed: 'red',
  Completed: 'green',
  Pending: 'amber',
  Reversed: 'gray',
};

export const WithdrawalStatusTag = ({
  status,
  displayText = status,
}: {
  status: WithdrawalStatus;
  displayText?: string;
}) => <Tag color={colorByStatus[status]}>{displayText}</Tag>;
