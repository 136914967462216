import { Card, DescList, formatDate, getFileInfo } from '@scalingworks/react-admin-ui';
import {
  Content,
  createHelpers,
  createResource,
  sentenceCase,
} from '@scalingworks/refine-react-admin';
import { type Customer, type Order, OrderState, OrderStatus, OrderActor, ServiceType, OrderCancelStatus, PaymentStatus, CountryCode } from '~/api';
import { MdOutlineEventNote } from 'react-icons/md';
import { format, parse } from 'date-fns';
import { HiCheck } from 'react-icons/hi';
import { OrderStateTag, OrderStateText } from '~/components/order-state-tag';
import { resourceNames } from './resource-names';
import { GrAttachment } from 'react-icons/gr';
import { COUNTRY_CODES_TO_NAMES } from '~/utils/constant';

const { defineFields, defineShowPage } = createHelpers<Order>({
  resourceName: resourceNames.order,
});

export const orderResource = createResource({
  name: resourceNames.order,
  label: 'Bookings',
  icon: <MdOutlineEventNote />,
  fields: defineFields([
    'id',
    'orderNumber',
    'state',
    'createdAt',
    'cancelledBy',
    'fulfillmentFailedState',
    'refundStatus',
    'countryCode',
    {
      customer: ['id', 'fullName', 'mobileNumber'],
      payments: [
        'id',
        {
          amount: ['amount', 'currencyCode'],
        },
      ],
      offering: [
        'id',
        'serviceId',
        'serviceProviderId',
        'displayName',
        {
          service: ['name', 'type'],
        },
        {
          workspace: [
            {
              capacity: ['estimatedAudiences', 'estimatedPerformers'],
            },
          ],
        },
      ],
      sessionInfo: [
        'date',
        'formattedDate',
        {
          sessionSlots: ['startTime', 'endTime'],
        },
      ],
    },
    {
      totalAmount: ['formattedAmount'],
    },
    {
      totalEarningAmount: ['formattedAmount'],
    },
    'isRefund',
    {
      refundAmount: ['amount', 'formattedAmount'],
    },
    {
      notRefundedFees: [
        {
          penaltyFeeAmount: ['formattedAmount'],
          adminFeeAmount: ['formattedAmount'],
        },
      ],
    },
    {
      subTotalBreakdown: [
        {
          sessionFee: ['formattedAmount'],
          insuranceFee: ['formattedAmount'],
        },
      ],
    },
    {
      cancelMetadata: ['cancelType', 'attachments', 'cancelStatus'],
    },
  ]),
  defaultValues: {},
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  allowCreate: false,
  allowEdit: false,
  allowSearch: true,
  timeSensitive: {
    pollingInterval: 5000,
    subscribe: (cb) => {
      const evtSource = new EventSource(`${import.meta.env.VITE_API_BASE_URL}/api/orders/sse`);
      const onMessage = () => cb('New order is created');
      evtSource.addEventListener('message', onMessage);

      return function unsubscribe() {
        evtSource.removeEventListener('message', onMessage);
      };
    },
  },
  filterControls: {
    orderNumber: {
      type: 'text',
      config: {
        label: 'Booking ID',
      },
      operator: 'contains',
    },
    createdAt: {
      type: 'daterange',
      config: {
        label: 'Booking Date',
      },
    },
    '.customer.is.mobileNumber||email': {
      type: 'text',
      config: {
        label: 'Customer (mobile/email)',
      },
      operator: 'contains',
    },
    countryCode: {
      type: 'multiselect',
      config: {
        label: 'Country',
        options: Object.entries(CountryCode).map(([label, value]) => {
          const countryName = COUNTRY_CODES_TO_NAMES[value];
          return { label: countryName, value };
        }),
        placeholder: 'All countries',
      },
    },
  },
  columns: ({ LinkToDetails }) => [
    {
      id: 'id',
      accessorKey: 'id',
      header: 'Booking ID',
      cell: (data) => {
        const id = data.cell.getValue<string>();

        return (
          <LinkToDetails resourceId={id} className="font-semibold">
            {data.row.original.orderNumber}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'serviceType',
      accessorKey: 'serviceType',
      header: 'Service type',
      cell: (data) => {
        const { service, displayName } = data.row.original.offering;

        return (
          <>
            <span>{service.name}</span>
            <br />
            <span className="text-gray-500">by {displayName}</span>
          </>
        );
      },
    },
    {
      id: 'time',
      accessorKey: 'time',
      header: 'Session Date',
      cell: (data) => {
        const { date, sessionSlots } = data.row.original.sessionInfo;

        return (
          <>
            <span>{formatDate(date, { formatType: 'dateOnly', format: 'dd MMM yyyy' })}, </span>
            {sessionSlots &&
              sessionSlots.map((slot, index) => (
                <>
                  <span key={index}>
                    {`${format(parse(slot.startTime, 'H:mm:ss', new Date()), 'hh:mm b')} - ${format(
                      parse(slot.endTime, 'H:mm:ss', new Date()),
                      'hh:mm b'
                    )}`}
                  </span>
                  <br />
                </>
              ))}
          </>
        );
      },
    },
    {
      id: 'customer',
      accessorFn: (row) => row.customer,
      header: 'Customer name',
      cell: (data) => {
        const customer = data.cell.getValue<Customer>();

        return customer ? (
          <LinkToDetails resourceName={resourceNames.customer} resourceId={customer.id}>
            {customer.fullName}
            <br />
            {customer.mobileNumber}
          </LinkToDetails>
        ) : (
          '-'
        );
      },
    },
    {
      id: 'country',
      accessorFn: (row) => row.countryCode,
      header: 'Country',
      cell: (data) => {
        const countryCode = data.cell.getValue<CountryCode>();
        return COUNTRY_CODES_TO_NAMES[countryCode];
      },
    },
    {
      id: 'state',
      accessorKey: 'state',
      header: 'Status',
      cell: (data) => {
        const state = data.cell.getValue<OrderState>();
        const refundStatus = data.row.original.refundStatus;
        return <OrderStateTag state={state} refundStatus={refundStatus} />;
      },
    },
    {
      id: 'totalAmount.formattedAmount',
      accessorKey: 'totalAmount.formattedAmount',
      header: 'Amount',
      cell: (data) => {
        if (data.row.original.state === OrderState.RefundProcessed) {
          if (data.row.original.isRefund && data.row.original.refundAmount) {
            return data.row.original.refundAmount.formattedAmount;
          } else {
            const currencyCode = data.row.original.payments?.[0]?.amount?.currencyCode ?? 'credits';
            return `0.00 ${currencyCode}`;
          }
        }

        return data.cell.getValue<string>();
      },
    },
  ],
  list: {
    tabs: {
      options: [OrderStatus.Pending, OrderStatus.Scheduled, OrderStatus.Completed].map(
        (status) => ({
          label: status,
          filterValue: {
            field: 'status',
            value: status,
            operator: 'eq',
          },
        })
      ),
      allowMultiSelect: true,
    },
  },
  show: defineShowPage({
    title: (order) => `#${order.orderNumber}`,
    fields: defineFields([
      'orderNumber',
      'state',
      'paymentStatus',
      'createdAt',
      'cancelReason',
      'cancelComments',
      'cancelledAt',
      'isRefund',
      'cancelledBy',
      'fulfillmentFailedState',
      'refundStatus',
      {
        customer: ['id', 'fullName', 'mobileNumber', 'createdAt'],
        offering: [
          'id',
          'termsAndConditions',
          'outcomes',
          'description',
          {
            serviceProvider: ['id', 'fullName', 'mobileNumber', 'createdAt'],
            service: ['name', 'professionName', 'type'],
            mainFacet: ['name'],
            facets: [
              'name',
              {
                values: ['name'],
              },
            ],
            workspace: [
              {
                capacity: ['estimatedAudiences', 'estimatedPerformers'],
              },
            ],
          },
        ],
        payments: ['id', 'gatewayTransactionId', 'status'],
        sessionInfo: ['date', 'formattedDate', 'formattedSessionSlots'],
        totalAmount: ['formattedAmount'],
        location: ['address'],
        refundAmount: ['amount', 'formattedAmount'],
        notRefundedFees: [
          {
            penaltyFeeAmount: ['formattedAmount'],
            adminFeeAmount: ['formattedAmount'],
          },
        ],
        subTotalBreakdown: [
          {
            sessionFee: ['formattedAmount'],
            insuranceFee: ['formattedAmount'],
          },
        ],
        cancelMetadata: ['cancelType', 'attachments', 'cancelStatus'],
        totalEarningAmount: ['formattedAmount'],
      },
    ]),
    component: function OrderShowPage({ queryResult, title }) {
      const orderData = queryResult.data && queryResult.data.data;

      return (
        <Content
          title={
            orderData && (
              <div className="flex flex-col md:flex-row md:items-center gap-3">
                <h1 className="text-2xl">{title}</h1>
                <div className="flex gap-2">
                  <OrderStateTag state={orderData.state} refundStatus={orderData.refundStatus} />
                </div>
              </div>
            )
          }
          isLoading={queryResult.isLoading}
        >
          <div className="flex flex-col gap-3 xl:flex-row-reverse xl:items-start xl:gap-6">
            <section className="flex flex-col xl:flex-col xl:w-2/3 gap-3">
              <Card className="flex-1">
                <Card.Header bordered>Session details</Card.Header>
                <Card.Body>
                  <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                    <DescList.Item
                      label="Status"
                      value={orderData && OrderStateText(orderData.state)}
                    />
                    <DescList.Item
                      label="Book on"
                      value={
                        orderData &&
                        formatDate(orderData.createdAt, {
                          formatType: 'dateAndTime',
                          format: 'dd MMM yyyy, hh:mm a',
                        })
                      }
                    />
                    <DescList.Item
                      label="Service type"
                      value={orderData && orderData.offering.service.name}
                    />
                    <DescList.Item
                      label="Category"
                      value={orderData && orderData.offering.mainFacet.name}
                    />
                    <DescList.Item
                      label="Session date"
                      value={
                        orderData &&
                        formatDate(orderData.sessionInfo.date, {
                          formatType: 'dateOnly',
                          format: 'dd MMM yyyy',
                        })
                      }
                    />
                    <DescList.Item
                      label="Session time"
                      value={orderData && orderData.sessionInfo.formattedSessionSlots}
                    />
                  </DescList>

                  {orderData && orderData.cancelledAt && (
                    <>
                      <div className="border-dashed border-b pt-1 mb-1"></div>
                      <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                        <DescList.Item
                          label="Cancellation time"
                          value={formatDate(orderData.cancelledAt, {
                            formatType: 'dateAndTime',
                            format: 'dd MMM yyyy, hh:mm a',
                          })}
                        />
                        <DescList.Item
                          label="Cancellation reason"
                          value={
                            orderData.cancelledBy == OrderActor.Operator ? (
                              <p>
                                {orderData.fulfillmentFailedState
                                  ? sentenceCase(orderData.fulfillmentFailedState)
                                  : '-'}
                              </p>
                            ) : (
                              orderData.cancelComments && (
                                <p className="text-right">
                                  {orderData.cancelledBy == OrderActor.ServiceProvider &&
                                    `${sentenceCase(String(OrderActor.ServiceProvider))} ${(
                                      orderData.cancelMetadata?.cancelType ?? 'cancelled'
                                    ).toLowerCase()} booking`}
                                  {orderData.cancelledBy == OrderActor.ServiceProvider && <br />}
                                  {orderData.cancelComments}
                                </p>
                              )
                            )
                          }
                        />
                        <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                          {orderData.cancelMetadata?.attachments &&
                          orderData.cancelMetadata?.attachments.length > 0 ? (
                            <DescList.Item
                              label="Proof of cancellation"
                              value={orderData.cancelMetadata?.attachments.map(
                                (attachment, index) => (
                                  <span key={`attachment-index`}>
                                    <GrAttachment className="inline-block" />
                                    <a
                                      href={attachment}
                                      className="underline"
                                      target="_BLANK"
                                      rel="noreferrer"
                                    >
                                      {getFileInfo(attachment).fileName}
                                    </a>
                                    <br />
                                  </span>
                                )
                              )}
                            />
                          ) : (
                            <DescList.Item label="Proof of cancellation" value="N/A" />
                          )}
                        </DescList>
                      </DescList>
                    </>
                  )}

                  {orderData && !orderData.cancelledAt && orderData.fulfillmentFailedState && (
                    <>
                      <div className="border-dashed border-b pt-1 mb-1"></div>
                      <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                        <DescList.Item
                          label="Session Remarks"
                          value={
                            <p>
                              {orderData.fulfillmentFailedState
                                ? sentenceCase(orderData.fulfillmentFailedState)
                                : '-'}
                            </p>
                          }
                        />
                      </DescList>
                    </>
                  )}
                </Card.Body>
              </Card>
              <Card className="flex-1">
                {orderData?.offering.service.type === ServiceType.Profession && (
                  <Card.Header bordered>About this session</Card.Header>
                )}
                {orderData?.offering.service.type === ServiceType.Space && (
                  <Card.Header bordered>About the space</Card.Header>
                )}
                <Card.Body>
                  <p className="text-gray-500">{orderData && orderData.offering.description}</p>
                </Card.Body>
              </Card>
              {orderData?.offering.service.type === ServiceType.Profession && (
                <>
                  <Card className="flex-1">
                    <Card.Header bordered>This session is designed for</Card.Header>
                    <Card.Body>
                      <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                        {orderData &&
                          orderData.offering.facets?.map((facet, index) => (
                            <DescList.Item
                              key={`facets-${index}`}
                              label={facet.name}
                              value={facet.values?.length > 0 ? facet.values[0].name : ''}
                            />
                          ))}
                      </DescList>
                    </Card.Body>
                  </Card>
                  <Card className="flex-1">
                    <Card.Header bordered>What you will learn</Card.Header>
                    <Card.Body>
                      <ul>
                        {orderData &&
                          orderData.offering.outcomes?.map((outcome, index) => (
                            <li key={`outcome-${index}`}>
                              <HiCheck className="inline-block text-green-800" />
                              <span className="text-gray-500">{outcome}</span>
                            </li>
                          ))}
                      </ul>
                    </Card.Body>
                  </Card>
                </>
              )}
              {orderData?.offering.service.type === ServiceType.Space && (
                <Card className="flex-1">
                  <Card.Header bordered>Basic details</Card.Header>
                  <Card.Body>
                    <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                      <DescList.Item
                        label="Accomodate guests"
                        value={
                          Number(
                            (orderData &&
                              orderData.offering.workspace?.capacity.estimatedAudiences) ||
                              0
                          ) +
                          Number(
                            (orderData &&
                              orderData.offering.workspace?.capacity.estimatedPerformers) ||
                              0
                          )
                        }
                      />
                    </DescList>
                  </Card.Body>
                </Card>
              )}
              <Card className="flex-1">
                <Card.Header bordered>Terms & conditions</Card.Header>
                <Card.Body>
                  <ol className="list-decimal list-inside text-gray-500">
                    {orderData &&
                      orderData.offering.termsAndConditions?.map((terms, index) => (
                        <li key={`terms-${index}`}>{terms}</li>
                      ))}
                  </ol>
                </Card.Body>
              </Card>
              <Card className="flex-1">
                <Card.Header bordered>Location</Card.Header>
                <Card.Body>
                  <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                    <DescList.Item
                      label="Address"
                      value={orderData && orderData.location.address}
                    />
                  </DescList>
                </Card.Body>
              </Card>
            </section>
            <section className="flex flex-col xl:flex-1 gap-3">
              <Card className="flex-1">
                <Card.Header bordered>Service provider</Card.Header>
                <Card.Body>
                  <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                    <DescList.Item
                      label="Name"
                      value={orderData && orderData.offering.serviceProvider.fullName}
                    />
                    <DescList.Item
                      label="Member since"
                      value={
                        orderData &&
                        formatDate(orderData.offering.serviceProvider.createdAt, {
                          formatType: 'dateOnly',
                          format: 'dd MMM yyyy',
                        })
                      }
                    />
                    <DescList.Item
                      label="Contact no."
                      value={orderData && orderData.offering.serviceProvider.mobileNumber}
                    />
                  </DescList>
                </Card.Body>
              </Card>
              <Card className="flex-1">
                <Card.Header bordered>Client</Card.Header>
                <Card.Body>
                  <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                    <DescList.Item label="Name" value={orderData && orderData.customer.fullName} />
                    <DescList.Item
                      label="Member since"
                      value={
                        orderData &&
                        formatDate(orderData.customer.createdAt, {
                          formatType: 'dateOnly',
                          format: 'dd MMM yyyy',
                        })
                      }
                    />
                    <DescList.Item
                      label="Contact no."
                      value={orderData && orderData.customer.mobileNumber}
                    />
                  </DescList>
                </Card.Body>
              </Card>
              <Card className="flex-1">
                <Card.Header bordered>Payment details</Card.Header>
                <Card.Body>
                  <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                    <DescList.Item
                      label="Transaction ID"
                      value={
                        orderData &&
                        (orderData.payments[0]?.gatewayTransactionId || orderData.payments[0]?.id)
                      }
                    />
                    {orderData && orderData.paymentStatus === PaymentStatus.Authorized && (
                      <DescList.Item label="Status" value={orderData && orderData.paymentStatus} />
                    )}
                    <DescList.Item
                      label="Session fee"
                      value={orderData && orderData.subTotalBreakdown.sessionFee.formattedAmount}
                    />
                    <DescList.Item
                      label="Insurance fee"
                      value={orderData && orderData.subTotalBreakdown.insuranceFee.formattedAmount}
                    />
                    <hr />
                    <DescList.Item
                      label="Total Amount paid"
                      value={orderData && orderData.totalAmount.formattedAmount}
                    />
                  </DescList>
                </Card.Body>
              </Card>
              {orderData &&
                (orderData.state === OrderState.RefundProcessed ||
                  (orderData.state === OrderState.Rescheduled &&
                    orderData.cancelMetadata &&
                    orderData.cancelMetadata.cancelStatus === OrderCancelStatus.Rejected)) && (
                  <Card className="flex-1">
                    <Card.Header bordered>Refund & Penalty details</Card.Header>
                    <Card.Body>
                      <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                        {orderData.refundAmount && (
                          <DescList.Item
                            label="Amount refunded to customer"
                            value={
                              orderData &&
                              orderData.refundAmount &&
                              Number(orderData.refundAmount.amount) > 0
                                ? orderData.refundAmount.formattedAmount
                                : 'N/A'
                            }
                          />
                        )}
                        {orderData.notRefundedFees && orderData.notRefundedFees.adminFeeAmount && (
                          <DescList.Item
                            label="Chargeable fee"
                            value={
                              orderData.notRefundedFees &&
                              orderData.notRefundedFees.adminFeeAmount.formattedAmount
                            }
                          />
                        )}
                        {orderData.notRefundedFees &&
                          orderData.notRefundedFees.penaltyFeeAmount && (
                            <DescList.Item
                              label="Amount credited to SP (Penalty)"
                              value={
                                orderData.totalEarningAmount &&
                                orderData.totalEarningAmount.formattedAmount
                              }
                            />
                          )}
                      </DescList>
                    </Card.Body>
                  </Card>
                )}
            </section>
          </div>
        </Content>
      );
    },
  }),
});
