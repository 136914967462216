import { NumberFieldInput } from './components/number-field-input';
import { useNotification } from '@refinedev/core';
import '@scalingworks/react-admin-ui';
import { Button } from '@scalingworks/react-admin-ui';
import { useGraphQLClient } from '@scalingworks/refine-react-admin';
import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { getSdk } from '~/api';
import { toCamelCase } from '~/utils/to-camel-case';

type SystemSettingsBookingProps = { defaultValues: any };
export const AllowCheckInWithinInMinutes = 'AllowCheckInWithinInMinutes';
export const AllowCheckInBeforeInMinutes = 'AllowCheckInBeforeInMinutes';
export const MarkSesionAsLateAfterInMinutes = 'MarkSesionAsLateAfterInMinutes';
export const MinimumTimeBetweenSessionSlotInMinutes = 'MinimumTimeBetweenSessionSlotInMinutes';

export const SystemSettingsBooking = (props: SystemSettingsBookingProps) => {
  const client = useGraphQLClient();
  const notificationHelpers = useNotification();
  const form = useForm({ defaultValues: props.defaultValues });
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values: any) => {
    setLoading(true);
    const data: Record<string, number> = {};
    Object.entries(values).map(([key, value]) => {
      data[toCamelCase(key)] = Number(value as string);
    });
    getSdk(client)
      .updateBookingSettings({
        data,
      })
      .then((result) => {
        if (result.updateBookingSettings) {
          if (notificationHelpers.open) {
            notificationHelpers.open({
              key: `${new Date().getTime()}`,
              type: 'success',
              message: 'Settings updated successfully',
            });
          }
          return;
        }
        if (notificationHelpers.open) {
          notificationHelpers.open({
            key: `${new Date().getTime()}`,
            type: 'error',
            message: 'Failed to update settings',
          });
        }
      })
      .catch(() => {
        if (notificationHelpers.open) {
          notificationHelpers.open({
            key: `${new Date().getTime()}`,
            type: 'error',
            message: 'Failed to update settings',
          });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <FormProvider {...form}>
      <div className="flex flex-col gap-y-4">
        <div className="space-y-2">
          <div className="font-bold text-base">Attendance configurations</div>
          <NumberFieldInput
            suffix="mins"
            label="Allow check-in and considered no-show after X mins"
            control={form.control}
            name={AllowCheckInWithinInMinutes}
          />
          <NumberFieldInput
            suffix="mins"
            label="Allow check in before"
            control={form.control}
            name={AllowCheckInBeforeInMinutes}
          />
        </div>

        <div className="space-y-2">
          <div className="font-bold text-base">Time configurations</div>
          <NumberFieldInput
            suffix="mins"
            label="Session mark as late after"
            control={form.control}
            name={MarkSesionAsLateAfterInMinutes}
          />
          <NumberFieldInput
            suffix="mins"
            label="Minimum time between each slot for creation"
            control={form.control}
            name={MinimumTimeBetweenSessionSlotInMinutes}
          />
        </div>

        <div className="ml-auto">
          <Button variant="solid" loading={loading} onClick={form.handleSubmit(handleSubmit)}>
            Update
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};
