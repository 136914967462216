import { EmbedDashboardViewer } from "~/components/embed-dashboard-viewer";

export const DashboardPage = () => {
    let id = "2";
    if(process.env.NODE_ENV === "production") {
        id = "4";
    }
    
    return (
        <>
        <EmbedDashboardViewer id={id} />
        </>
    );
}