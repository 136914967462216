import { resourceNames } from './resource-names';
import { WithdrawalRequestActions } from './withdrawal-request-resource/withdrawal-request-actions';
import { Card, DescList, formatDate } from '@scalingworks/react-admin-ui';
import { Content, createHelpers, createResource } from '@scalingworks/refine-react-admin';
import { MdAttachMoney } from 'react-icons/md';
import { type Withdrawal, WithdrawalStatus, WithdrawalType, CountryCode } from '~/api';
import { WithdrawalStatusTag } from '~/components/withdrawal-status-tag';
import { COUNTRY_CODES_TO_NAMES } from '~/utils/constant';

const { defineFields, defineShowPage } = createHelpers<Withdrawal>({
  resourceName: resourceNames.withdrawalRequests,
});

export const withdrawalRequestsResource = createResource<Withdrawal>({
  name: resourceNames.withdrawalRequests,
  label: 'Withdrawal Requests',
  icon: <MdAttachMoney />,
  columns: ({ LinkToDetails }) => [
    {
      id: 'accountHolderName',
      accessorKey: 'bankAccount.accountHolderName',
      header: 'Account',
      cell: (data) => {
        const name = data.cell.getValue<string>();

        return (
          <LinkToDetails resourceId={data.row.original.id}>
            <span className="font-semibold">{name}</span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'fullName',
      accessorKey: 'serviceProvider.fullName',
      header: 'Service Provider',
      cell: (data) => {
        const providerId = data.row.original.serviceProviderId;
        const providerName = data.cell.getValue<string>();

        return (
          <LinkToDetails resourceId={providerId} resourceName={resourceNames.serviceProvider}>
            <span className="font-semibold">{providerName}</span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'bankName',
      accessorKey: 'bankAccount.bankName',
      header: 'Bank',
    },
    {
      id: 'createdAt',
      accessorKey: 'createdAt',
      header: 'Created date',
      cell: (data) => {
        const dateValue = data.cell.getValue<string>();

        return dateValue && formatDate(dateValue);
      },
    },
    {
      id: 'amount',
      accessorKey: 'amount.formattedAmount',
      header: 'Amount',
    },
    {
      id: 'netAmount',
      accessorKey: 'netAmount.formattedAmount',
      header: 'Net Amount',
    },
    {
      id: 'country',
      accessorFn: (row) => row.countryCode,
      header: 'Country',
      cell: (data) => {
        const countryCode = data.cell.getValue<CountryCode>();
        return COUNTRY_CODES_TO_NAMES[countryCode];
      },
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: 'Status',
      cell: (data) => <WithdrawalStatusTag status={data.cell.getValue<WithdrawalStatus>()} />,
    },
  ],
  fields: defineFields([
    'id',
    'createdAt',
    'updatedAt',
    'status',
    'serviceProviderId',
    'countryCode',
    {
      amount: ['currencyCode', 'formattedAmount'],
    },
    {
      netAmount: ['currencyCode', 'formattedAmount'],
    },
    {
      bankAccount: ['accountHolderName', 'bankName'],
    },
    {
      serviceProvider: ['fullName'],
    },
  ]),
  defaultValues: {},
  allowCreate: false,
  show: defineShowPage({
    fields: defineFields([
      'id',
      'createdAt',
      'updatedAt',
      'status',
      'countryCode',
      {
        amount: ['currencyCode', 'formattedAmount'],
      },
      {
        netAmount: ['currencyCode', 'formattedAmount'],
      },
      {
        bankAccount: ['accountHolderName', 'bankName', 'accountNumber'],
      },
      {
        serviceProvider: ['fullName'],
      },
    ]),
    component: function BusinessApplicationShowPage({ queryResult, title }) {
      const withdrawalData = queryResult.data && queryResult.data.data;

      const getStatusText = (status: WithdrawalStatus) => {
        switch (status) {
          case WithdrawalStatus.Completed:
          case WithdrawalStatus.Failed:
            return status;
          case WithdrawalStatus.Pending:
            return 'Submitted';
        }

        return '';
      };

      return (
        <Content
          title={
            withdrawalData && (
              <div className="flex flex-col md:flex-row md:items-center gap-3">
                <h1>{title}</h1>
              </div>
            )
          }
          isLoading={queryResult.isLoading}
        >
          {withdrawalData && (
            <div className="flex flex-col gap-3 xl:gap-6">
              <section className="flex flex-col lg:flex-row lg:w-2/4 xl:flex-col gap-3">
                <Card className="flex-1">
                  <Card.Header bordered className="text-xl font-bold">
                    {withdrawalData?.bankAccount.accountHolderName}
                  </Card.Header>
                  <Card.Body>
                    <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                      <dl className="grid gap-3">
                        <div className="grid grid-cols-2 justify-between items-center py-1 hover:bg-gray-50">
                          <dt className="block text-gray-500 text-sm">Status</dt>
                          <dd className="block break-words text-black">
                            <WithdrawalStatusTag status={withdrawalData.status!} />
                          </dd>
                        </div>
                      </dl>

                      <dl className="grid gap-3">
                        <div className="grid grid-cols-2 justify-between items-center py-1 hover:bg-gray-50">
                          <dt className="block text-gray-500 text-sm">Country</dt>
                          <dd className="block break-words text-black">
                            {COUNTRY_CODES_TO_NAMES[withdrawalData?.countryCode]}
                          </dd>
                        </div>
                      </dl>

                      <dl className="grid gap-3">
                        <div className="grid grid-cols-2 justify-between items-center py-1 hover:bg-gray-50">
                          <dt className="block text-gray-500 text-sm">Service Provider</dt>
                          <dd className="block break-words text-black">
                            {withdrawalData?.serviceProvider.fullName}
                          </dd>
                        </div>
                      </dl>
                      <dl className="grid gap-3">
                        <div className="grid grid-cols-2 justify-between items-center py-1 hover:bg-gray-50">
                          <dt className="block text-gray-500 text-sm">Bank</dt>
                          <dd className="block break-words text-black">
                            {withdrawalData?.bankAccount.bankName}
                          </dd>
                        </div>
                      </dl>
                      <dl className="grid gap-3">
                        <div className="grid grid-cols-2 justify-between items-center py-1 hover:bg-gray-50">
                          <dt className="block text-gray-500 text-sm">Account Number</dt>
                          <dd className="block break-words text-black">
                            {withdrawalData?.bankAccount.accountNumber}
                          </dd>
                        </div>
                      </dl>
                      <dl className="grid gap-3">
                        <div className="grid grid-cols-2 justify-between items-center py-1 hover:bg-gray-50">
                          <dt className="block text-gray-500 text-sm">Amount</dt>
                          <dd className="block break-words text-black">
                            {withdrawalData?.amount.formattedAmount}
                          </dd>
                        </div>
                      </dl>
                      <dl className="grid gap-3">
                        <div className="grid grid-cols-2 justify-between items-center py-1 hover:bg-gray-50">
                          <dt className="block text-gray-500 text-sm">Net Amount</dt>
                          <dd className="block break-words text-black">
                            {withdrawalData?.netAmount.formattedAmount}
                          </dd>
                        </div>
                      </dl>
                    </DescList>

                    <hr className="my-4" />

                    {!queryResult.isLoading && withdrawalData && withdrawalData.status && (
                      <div className="block items-center xl:flex">
                        <span className="text-sm text-gray-500">
                          {`${getStatusText(withdrawalData.status ?? '').toString()} on 
                              ${formatDate(withdrawalData?.updatedAt, {
                                format: 'dd MMM yyyy, hh:mm a',
                              })}`}
                        </span>

                        {withdrawalData.status === WithdrawalStatus.Pending && (
                          <>
                            <div className="ml-auto mt-2">
                              <WithdrawalRequestActions
                                withdrawalId={withdrawalData.id}
                                onSuccess={() => queryResult.refetch()}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </section>
            </div>
          )}
        </Content>
      );
    },
  }),
  filterControls: {
    countryCode: {
      type: 'multiselect',
      config: {
        label: 'Country',
        options: Object.entries(CountryCode).map(([label, value]) => {
          const countryName = COUNTRY_CODES_TO_NAMES[value];
          return { label: countryName, value };
        }),
        placeholder: 'All countries',
      },
    },
  },
});
