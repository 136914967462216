import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Decimal: any;
  JSON: any;
  Time: any;
};

export type Account = {
  __typename?: 'Account';
  accessToken?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  expiresAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  providerAccountId: Scalars['String'];
  providerType: Scalars['String'];
  refreshToken?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId: Scalars['String'];
};

export type AccountCreateManyUserInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  providerAccountId: Scalars['String'];
  providerType: Scalars['String'];
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type AccountCreateManyUserInputEnvelope = {
  data: Array<AccountCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AccountCreateOrConnectWithoutUserInput = {
  create: AccountUncheckedCreateWithoutUserInput;
  where: AccountWhereUniqueInput;
};

export type AccountCreateWithoutUserInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  providerAccountId: Scalars['String'];
  providerType: Scalars['String'];
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type AccountListRelationFilter = {
  every?: InputMaybe<AccountWhereInput>;
  none?: InputMaybe<AccountWhereInput>;
  some?: InputMaybe<AccountWhereInput>;
};

export type AccountOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AccountProviderTypeProviderAccountIdCompoundUniqueInput = {
  providerAccountId: Scalars['String'];
  providerType: Scalars['String'];
};

export type AccountUncheckedCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AccountCreateWithoutUserInput>>;
  createMany?: InputMaybe<AccountCreateManyUserInputEnvelope>;
};

export type AccountUncheckedCreateWithoutUserInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  providerAccountId: Scalars['String'];
  providerType: Scalars['String'];
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type AccountWhereInput = {
  AND?: InputMaybe<Array<AccountWhereInput>>;
  NOT?: InputMaybe<Array<AccountWhereInput>>;
  OR?: InputMaybe<Array<AccountWhereInput>>;
  accessToken?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IntFilter>;
  providerAccountId?: InputMaybe<StringFilter>;
  providerType?: InputMaybe<StringFilter>;
  refreshToken?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AccountWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  providerType_providerAccountId?: InputMaybe<AccountProviderTypeProviderAccountIdCompoundUniqueInput>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type AdminLoginInput = {
  otpCode?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type AdminPermission = {
  __typename?: 'AdminPermission';
  action?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  resource?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type AdminPermissionOrderByWithRelationInput = {
  action?: InputMaybe<SortOrderInput>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AdminPermissionWhereInput = {
  AND?: InputMaybe<Array<AdminPermissionWhereInput>>;
  NOT?: InputMaybe<Array<AdminPermissionWhereInput>>;
  OR?: InputMaybe<Array<AdminPermissionWhereInput>>;
  action?: InputMaybe<StringNullableFilter>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  resource?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminRejectBusinessApplicationInput = {
  rejectReason?: InputMaybe<Scalars['String']>;
};

export type AdminRole = {
  __typename?: 'AdminRole';
  _count?: Maybe<AdminRoleCount>;
  admins: Array<AdminUser>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  permissionGrants?: Maybe<Array<PermissionGrant>>;
  permissions?: Maybe<Array<Scalars['String']>>;
  updatedAt: Scalars['DateTime'];
};


export type AdminRoleAdminsArgs = {
  cursor?: InputMaybe<AdminUserWhereUniqueInput>;
  distinct?: InputMaybe<Array<AdminUserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AdminUserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AdminUserWhereInput>;
};

export type AdminRoleCount = {
  __typename?: 'AdminRoleCount';
  admins: Scalars['Int'];
};

export type AdminRoleCreateInputV2 = {
  admins?: InputMaybe<AdminUserCreateNestedManyWithoutRoleInput>;
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type AdminRoleOrderByWithRelationInput = {
  admins?: InputMaybe<AdminUserOrderByRelationAggregateInput>;
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  permissions?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AdminRoleRelationFilter = {
  is?: InputMaybe<AdminRoleWhereInput>;
  isNot?: InputMaybe<AdminRoleWhereInput>;
};

export type AdminRoleUpdateInputV2 = {
  admins?: InputMaybe<AdminUserUpdateManyWithoutRoleNestedInput>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<Scalars['String']>>;
};

export type AdminRoleWhereInput = {
  AND?: InputMaybe<Array<AdminRoleWhereInput>>;
  NOT?: InputMaybe<Array<AdminRoleWhereInput>>;
  OR?: InputMaybe<Array<AdminRoleWhereInput>>;
  admins?: InputMaybe<AdminUserListRelationFilter>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  permissions?: InputMaybe<JsonFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminRoleWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  _count?: Maybe<AdminUserCount>;
  businessApplications: Array<BusinessApplication>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  profilePicture?: Maybe<Scalars['String']>;
  role?: Maybe<AdminRole>;
  roleId?: Maybe<Scalars['ID']>;
  status: Status;
  updatedAt: Scalars['DateTime'];
  withdrawals: Array<Withdrawal>;
};


export type AdminUserBusinessApplicationsArgs = {
  cursor?: InputMaybe<BusinessApplicationWhereUniqueInput>;
  distinct?: InputMaybe<Array<BusinessApplicationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BusinessApplicationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BusinessApplicationWhereInput>;
};


export type AdminUserWithdrawalsArgs = {
  cursor?: InputMaybe<WithdrawalWhereUniqueInput>;
  distinct?: InputMaybe<Array<WithdrawalScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WithdrawalOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WithdrawalWhereInput>;
};

export type AdminUserCount = {
  __typename?: 'AdminUserCount';
  businessApplications: Scalars['Int'];
  withdrawals: Scalars['Int'];
};

export type AdminUserCreateInputV2 = {
  email: Scalars['String'];
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Status>;
  withdrawals?: InputMaybe<WithdrawalUncheckedCreateNestedManyWithoutApproverInput>;
};

export type AdminUserCreateManyRoleInput = {
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type AdminUserCreateManyRoleInputEnvelope = {
  data: Array<AdminUserCreateManyRoleInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AdminUserCreateNestedManyWithoutRoleInput = {
  connect?: InputMaybe<Array<AdminUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AdminUserCreateOrConnectWithoutRoleInput>>;
  create?: InputMaybe<Array<AdminUserCreateWithoutRoleInput>>;
  createMany?: InputMaybe<AdminUserCreateManyRoleInputEnvelope>;
};

export type AdminUserCreateNestedOneWithoutBusinessApplicationsInput = {
  connect?: InputMaybe<AdminUserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdminUserCreateOrConnectWithoutBusinessApplicationsInput>;
  create?: InputMaybe<AdminUserUncheckedCreateWithoutBusinessApplicationsInput>;
};

export type AdminUserCreateOrConnectWithoutBusinessApplicationsInput = {
  create: AdminUserUncheckedCreateWithoutBusinessApplicationsInput;
  where: AdminUserWhereUniqueInput;
};

export type AdminUserCreateOrConnectWithoutRoleInput = {
  create: AdminUserUncheckedCreateWithoutRoleInput;
  where: AdminUserWhereUniqueInput;
};

export type AdminUserCreateOrConnectWithoutUserInput = {
  create: AdminUserUncheckedCreateWithoutUserInput;
  where: AdminUserWhereUniqueInput;
};

export type AdminUserCreateWithoutRoleInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  user: UserCreateNestedOneWithoutAdminInput;
  withdrawals?: InputMaybe<WithdrawalCreateNestedManyWithoutApproverInput>;
};

export type AdminUserListRelationFilter = {
  every?: InputMaybe<AdminUserWhereInput>;
  none?: InputMaybe<AdminUserWhereInput>;
  some?: InputMaybe<AdminUserWhereInput>;
};

export type AdminUserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AdminUserOrderByWithRelationInput = {
  businessApplications?: InputMaybe<BusinessApplicationOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrderInput>;
  profilePicture?: InputMaybe<SortOrderInput>;
  role?: InputMaybe<AdminRoleOrderByWithRelationInput>;
  roleId?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  withdrawals?: InputMaybe<WithdrawalOrderByRelationAggregateInput>;
};

export type AdminUserRelationFilter = {
  is?: InputMaybe<AdminUserWhereInput>;
  isNot?: InputMaybe<AdminUserWhereInput>;
};

export enum AdminUserScalarFieldEnum {
  CreatedAt = 'createdAt',
  Email = 'email',
  Id = 'id',
  Name = 'name',
  Password = 'password',
  ProfilePicture = 'profilePicture',
  RoleId = 'roleId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type AdminUserScalarWhereInput = {
  AND?: InputMaybe<Array<AdminUserScalarWhereInput>>;
  NOT?: InputMaybe<Array<AdminUserScalarWhereInput>>;
  OR?: InputMaybe<Array<AdminUserScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringNullableFilter>;
  profilePicture?: InputMaybe<StringNullableFilter>;
  roleId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AdminUserUncheckedCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<AdminUserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AdminUserCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<AdminUserUncheckedCreateWithoutUserInput>;
};

export type AdminUserUncheckedCreateWithoutBusinessApplicationsInput = {
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  withdrawals?: InputMaybe<WithdrawalUncheckedCreateNestedManyWithoutApproverInput>;
};

export type AdminUserUncheckedCreateWithoutRoleInput = {
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  withdrawals?: InputMaybe<WithdrawalUncheckedCreateNestedManyWithoutApproverInput>;
};

export type AdminUserUncheckedCreateWithoutUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  withdrawals?: InputMaybe<WithdrawalUncheckedCreateNestedManyWithoutApproverInput>;
};

export type AdminUserUncheckedUpdateManyWithoutAdminsInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
};

export type AdminUserUncheckedUpdateWithoutRoleInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  withdrawals?: InputMaybe<WithdrawalUncheckedUpdateManyWithoutApproverNestedInput>;
};

export type AdminUserUpdateInputV2 = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  roleId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Status>;
  withdrawals?: InputMaybe<WithdrawalUncheckedUpdateManyWithoutApproverNestedInput>;
};

export type AdminUserUpdateManyWithWhereWithoutRoleInput = {
  data: AdminUserUncheckedUpdateManyWithoutAdminsInput;
  where: AdminUserScalarWhereInput;
};

export type AdminUserUpdateManyWithoutRoleNestedInput = {
  connect?: InputMaybe<Array<AdminUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AdminUserCreateOrConnectWithoutRoleInput>>;
  create?: InputMaybe<Array<AdminUserCreateWithoutRoleInput>>;
  createMany?: InputMaybe<AdminUserCreateManyRoleInputEnvelope>;
  delete?: InputMaybe<Array<AdminUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AdminUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AdminUserWhereUniqueInput>>;
  set?: InputMaybe<Array<AdminUserWhereUniqueInput>>;
  update?: InputMaybe<Array<AdminUserUpdateWithWhereUniqueWithoutRoleInput>>;
  updateMany?: InputMaybe<Array<AdminUserUpdateManyWithWhereWithoutRoleInput>>;
  upsert?: InputMaybe<Array<AdminUserUpsertWithWhereUniqueWithoutRoleInput>>;
};

export type AdminUserUpdateWithWhereUniqueWithoutRoleInput = {
  data: AdminUserUncheckedUpdateWithoutRoleInput;
  where: AdminUserWhereUniqueInput;
};

export type AdminUserUpsertWithWhereUniqueWithoutRoleInput = {
  create: AdminUserUncheckedCreateWithoutRoleInput;
  update: AdminUserUncheckedUpdateWithoutRoleInput;
  where: AdminUserWhereUniqueInput;
};

export type AdminUserWhereInput = {
  AND?: InputMaybe<Array<AdminUserWhereInput>>;
  NOT?: InputMaybe<Array<AdminUserWhereInput>>;
  OR?: InputMaybe<Array<AdminUserWhereInput>>;
  businessApplications?: InputMaybe<BusinessApplicationListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringNullableFilter>;
  profilePicture?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<AdminRoleRelationFilter>;
  roleId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  withdrawals?: InputMaybe<WithdrawalListRelationFilter>;
};

export type AdminUserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateCancellationReason = {
  __typename?: 'AggregateCancellationReason';
  _avg?: Maybe<CancellationReasonAvgAggregate>;
  _count?: Maybe<CancellationReasonCountAggregate>;
  _max?: Maybe<CancellationReasonMaxAggregate>;
  _min?: Maybe<CancellationReasonMinAggregate>;
  _sum?: Maybe<CancellationReasonSumAggregate>;
};

export type Auth = {
  __typename?: 'Auth';
  /** JWT access token */
  accessToken: Scalars['String'];
  chatToken?: Maybe<Scalars['String']>;
  expiresIn: Scalars['Float'];
  refreshExpiresIn: Scalars['Float'];
  /** JWT refresh token */
  refreshToken: Scalars['String'];
};

export type Availability = {
  __typename?: 'Availability';
  createdAt: Scalars['DateTime'];
  dayOfWeek?: Maybe<DayOfWeek>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  endTime: Scalars['String'];
  frequencyType?: Maybe<FrequencyType>;
  id: Scalars['ID'];
  offering?: Maybe<Offering>;
  offeringId?: Maybe<Scalars['String']>;
  periodDays?: Maybe<Scalars['Int']>;
  periodEndDate?: Maybe<Scalars['DateTime']>;
  periodStartDate: Scalars['DateTime'];
  periodType: PeriodType;
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['Int']>;
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars['String'];
  startTime: Scalars['String'];
  timeZone: Scalars['String'];
  type: AvailabilityType;
  updatedAt: Scalars['DateTime'];
};

export type AvailabilityListRelationFilter = {
  every?: InputMaybe<AvailabilityWhereInput>;
  none?: InputMaybe<AvailabilityWhereInput>;
  some?: InputMaybe<AvailabilityWhereInput>;
};

export type AvailabilityOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  dayOfWeek?: InputMaybe<SortOrderInput>;
  deletedAt?: InputMaybe<SortOrderInput>;
  endTime?: InputMaybe<SortOrder>;
  frequencyType?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  offeringId?: InputMaybe<SortOrderInput>;
  periodDays?: InputMaybe<SortOrderInput>;
  periodEndDate?: InputMaybe<SortOrderInput>;
  periodStartDate?: InputMaybe<SortOrder>;
  periodType?: InputMaybe<SortOrder>;
  service?: InputMaybe<ServiceOrderByWithRelationInput>;
  serviceId?: InputMaybe<SortOrderInput>;
  serviceProvider?: InputMaybe<ServiceProviderOrderByWithRelationInput>;
  serviceProviderId?: InputMaybe<SortOrder>;
  startTime?: InputMaybe<SortOrder>;
  timeZone?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum AvailabilityScalarFieldEnum {
  CreatedAt = 'createdAt',
  DayOfWeek = 'dayOfWeek',
  DeletedAt = 'deletedAt',
  EndTime = 'endTime',
  FrequencyType = 'frequencyType',
  Id = 'id',
  OfferingId = 'offeringId',
  PeriodDays = 'periodDays',
  PeriodEndDate = 'periodEndDate',
  PeriodStartDate = 'periodStartDate',
  PeriodType = 'periodType',
  ServiceId = 'serviceId',
  ServiceProviderId = 'serviceProviderId',
  StartTime = 'startTime',
  TimeZone = 'timeZone',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export enum AvailabilityType {
  Override = 'Override',
  Regular = 'Regular'
}

export type AvailabilityWhereInput = {
  AND?: InputMaybe<Array<AvailabilityWhereInput>>;
  NOT?: InputMaybe<Array<AvailabilityWhereInput>>;
  OR?: InputMaybe<Array<AvailabilityWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dayOfWeek?: InputMaybe<IntNullableFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  endTime?: InputMaybe<DateTimeFilter>;
  frequencyType?: InputMaybe<EnumFrequencyTypeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  offering?: InputMaybe<OfferingRelationFilter>;
  offeringId?: InputMaybe<StringNullableFilter>;
  periodDays?: InputMaybe<IntNullableFilter>;
  periodEndDate?: InputMaybe<DateTimeNullableFilter>;
  periodStartDate?: InputMaybe<DateTimeFilter>;
  periodType?: InputMaybe<EnumPeriodTypeFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  startTime?: InputMaybe<DateTimeFilter>;
  timeZone?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumAvailabilityTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AvailabilityWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type AvailableSessionSlot = {
  __typename?: 'AvailableSessionSlot';
  availabilityId: Scalars['String'];
  endTime: Scalars['Time'];
  formattedTime: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  startTime: Scalars['Time'];
};

export enum BalanceType {
  Cash = 'Cash',
  Wallet = 'Wallet'
}

export type Bank = {
  __typename?: 'Bank';
  code: Scalars['String'];
  countryCode: CountryCode;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status: Status;
  swiftCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountHolderName?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BankOrderByWithRelationInput = {
  code?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  imageUrl?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  swiftCode?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BankUncheckedCreateInput = {
  code: Scalars['String'];
  countryCode?: InputMaybe<CountryCode>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status?: InputMaybe<Status>;
  swiftCode?: InputMaybe<Scalars['String']>;
};

export type BankUncheckedUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryCode>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  swiftCode?: InputMaybe<Scalars['String']>;
};

export type BankWhereInput = {
  AND?: InputMaybe<Array<BankWhereInput>>;
  NOT?: InputMaybe<Array<BankWhereInput>>;
  OR?: InputMaybe<Array<BankWhereInput>>;
  code?: InputMaybe<StringFilter>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  swiftCode?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BankWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type BaseAvailableSessionSlot = {
  __typename?: 'BaseAvailableSessionSlot';
  endTime: Scalars['Time'];
  startTime: Scalars['Time'];
};

export type BlockedUser = {
  __typename?: 'BlockedUser';
  blockedUser: User;
  blockedUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type BlockedUserCreateManyBlockedUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type BlockedUserCreateManyBlockedUserInputEnvelope = {
  data: Array<BlockedUserCreateManyBlockedUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BlockedUserCreateManyUserInput = {
  blockedUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlockedUserCreateManyUserInputEnvelope = {
  data: Array<BlockedUserCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BlockedUserCreateOrConnectWithoutBlockedUserInput = {
  create: BlockedUserUncheckedCreateWithoutBlockedUserInput;
  where: BlockedUserWhereUniqueInput;
};

export type BlockedUserCreateOrConnectWithoutUserInput = {
  create: BlockedUserUncheckedCreateWithoutUserInput;
  where: BlockedUserWhereUniqueInput;
};

export type BlockedUserCreateWithoutBlockedUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutBlockedByInput;
};

export type BlockedUserCreateWithoutUserInput = {
  blockedUser: UserCreateNestedOneWithoutBlockedUsersInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlockedUserIdCompoundUniqueInput = {
  blockedUserId: Scalars['String'];
  userId: Scalars['String'];
};

export type BlockedUserListRelationFilter = {
  every?: InputMaybe<BlockedUserWhereInput>;
  none?: InputMaybe<BlockedUserWhereInput>;
  some?: InputMaybe<BlockedUserWhereInput>;
};

export type BlockedUserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BlockedUserOrderByWithRelationInput = {
  blockedUser?: InputMaybe<UserOrderByWithRelationInput>;
  blockedUserId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type BlockedUserUncheckedCreateInput = {
  blockedUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type BlockedUserUncheckedCreateNestedManyWithoutBlockedUserInput = {
  connect?: InputMaybe<Array<BlockedUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BlockedUserCreateOrConnectWithoutBlockedUserInput>>;
  create?: InputMaybe<Array<BlockedUserCreateWithoutBlockedUserInput>>;
  createMany?: InputMaybe<BlockedUserCreateManyBlockedUserInputEnvelope>;
};

export type BlockedUserUncheckedCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<BlockedUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BlockedUserCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<BlockedUserCreateWithoutUserInput>>;
  createMany?: InputMaybe<BlockedUserCreateManyUserInputEnvelope>;
};

export type BlockedUserUncheckedCreateWithoutBlockedUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type BlockedUserUncheckedCreateWithoutUserInput = {
  blockedUserId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BlockedUserUncheckedUpdateInput = {
  blockedUserId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type BlockedUserWhereInput = {
  AND?: InputMaybe<Array<BlockedUserWhereInput>>;
  NOT?: InputMaybe<Array<BlockedUserWhereInput>>;
  OR?: InputMaybe<Array<BlockedUserWhereInput>>;
  blockedUser?: InputMaybe<UserRelationFilter>;
  blockedUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type BlockedUserWhereUniqueInput = {
  id?: InputMaybe<BlockedUserIdCompoundUniqueInput>;
};

export type BookingSettingsInput = {
  /** Allow check in before in minutes */
  allowCheckInBeforeInMinutes?: InputMaybe<Scalars['Int']>;
  /** Allow check in within in minutes */
  allowCheckInWithinInMinutes?: InputMaybe<Scalars['Int']>;
  /** Mark session as late after certain period in minutes */
  markSesionAsLateAfterInMinutes?: InputMaybe<Scalars['Int']>;
  /** Minimum time between each session slot */
  minimumTimeBetweenSessionSlotInMinutes?: InputMaybe<Scalars['Int']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type Business = {
  __typename?: 'Business';
  _count?: Maybe<BusinessCount>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  service: Service;
  serviceId: Scalars['Int'];
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars['String'];
  status: BusinessStatus;
  supportingDocument?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['DateTime'];
};

export type BusinessApplication = {
  __typename?: 'BusinessApplication';
  attributeIds: Array<Scalars['Int']>;
  business?: Maybe<Business>;
  countryCode: CountryCode;
  createdAt: Scalars['DateTime'];
  facets: Array<BusinessApplicationFacet>;
  id: Scalars['ID'];
  location?: Maybe<Location>;
  mainAttributeId: Scalars['Int'];
  mainFacet: FacetValue;
  metadata?: Maybe<BusinessApplicationMetadata>;
  offering?: Maybe<Offering>;
  price?: Maybe<Money>;
  service: Service;
  serviceId: Scalars['Int'];
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars['String'];
  status: BusinessApplicationStatus;
  supportingDocument?: Maybe<SupportingDocument>;
  updatedAt: Scalars['DateTime'];
  /**   */
  userId: Scalars['String'];
  userRemarks?: Maybe<Scalars['JSON']>;
  workspace?: Maybe<Workspace>;
  workspaceBusinessProfile?: Maybe<WorkspaceBusinessProfile>;
  workspaceCapacity?: Maybe<WorkspaceCapacity>;
  workspaceOwner?: Maybe<WorkspaceOwner>;
};

export type BusinessApplicationCreateManyBusinessInput = {
  approverId?: InputMaybe<Scalars['String']>;
  approverRemarks?: InputMaybe<Scalars['JSON']>;
  attributeIds?: InputMaybe<Array<Scalars['Int']>>;
  countryCode?: InputMaybe<CountryCode>;
  id?: InputMaybe<Scalars['ID']>;
  metadata: Scalars['JSON'];
  offeringId?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['Int'];
  status?: InputMaybe<BusinessApplicationStatus>;
  userRemarks?: InputMaybe<Scalars['JSON']>;
};

export type BusinessApplicationCreateManyBusinessInputEnvelope = {
  data: Array<BusinessApplicationCreateManyBusinessInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BusinessApplicationCreateNestedManyWithoutBusinessInput = {
  connect?: InputMaybe<Array<BusinessApplicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BusinessApplicationCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<BusinessApplicationCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<BusinessApplicationCreateManyBusinessInputEnvelope>;
};

export type BusinessApplicationCreateOrConnectWithoutBusinessInput = {
  create: BusinessApplicationUncheckedCreateWithoutBusinessInput;
  where: BusinessApplicationWhereUniqueInput;
};

export type BusinessApplicationCreateWithoutBusinessInput = {
  approver?: InputMaybe<AdminUserCreateNestedOneWithoutBusinessApplicationsInput>;
  approverRemarks?: InputMaybe<Scalars['JSON']>;
  attributeIds?: InputMaybe<Array<Scalars['Int']>>;
  countryCode?: InputMaybe<CountryCode>;
  id?: InputMaybe<Scalars['ID']>;
  metadata: Scalars['JSON'];
  service: ServiceCreateNestedOneWithoutBusinessApplicationsInput;
  serviceProvider: ServiceProviderCreateNestedOneWithoutBusinessApplicationsInput;
  status?: InputMaybe<BusinessApplicationStatus>;
  userRemarks?: InputMaybe<Scalars['JSON']>;
};

export type BusinessApplicationFacet = {
  __typename?: 'BusinessApplicationFacet';
  formattedValueName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  values: Array<BusinessApplicationFacetValue>;
};

export type BusinessApplicationFacetValue = {
  __typename?: 'BusinessApplicationFacetValue';
  description?: Maybe<Scalars['String']>;
  /** Facet value ID */
  id: Scalars['Int'];
  name: Scalars['String'];
  subFacetValue?: Maybe<BusinessApplicationFacetValue>;
};

export type BusinessApplicationListRelationFilter = {
  every?: InputMaybe<BusinessApplicationWhereInput>;
  none?: InputMaybe<BusinessApplicationWhereInput>;
  some?: InputMaybe<BusinessApplicationWhereInput>;
};

export type BusinessApplicationMetadata = {
  __typename?: 'BusinessApplicationMetadata';
  /** Facet IDs */
  attributeIds: Array<Scalars['Int']>;
  /** Facet ID */
  mainAttributeId: Scalars['Int'];
  supportingDocument?: Maybe<SupportingDocument>;
  workspace?: Maybe<Workspace>;
};

export type BusinessApplicationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BusinessApplicationOrderByWithRelationInput = {
  approver?: InputMaybe<AdminUserOrderByWithRelationInput>;
  approverId?: InputMaybe<SortOrderInput>;
  approverRemarks?: InputMaybe<SortOrderInput>;
  attributeIds?: InputMaybe<SortOrder>;
  business?: InputMaybe<BusinessOrderByWithRelationInput>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mainAttributeId?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrder>;
  service?: InputMaybe<ServiceOrderByWithRelationInput>;
  serviceId?: InputMaybe<SortOrder>;
  serviceProvider?: InputMaybe<ServiceProviderOrderByWithRelationInput>;
  serviceProviderId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  userRemarks?: InputMaybe<SortOrderInput>;
};

export enum BusinessApplicationScalarFieldEnum {
  ApproverId = 'approverId',
  ApproverRemarks = 'approverRemarks',
  AttributeIds = 'attributeIds',
  BusinessId = 'businessId',
  CountryCode = 'countryCode',
  CreatedAt = 'createdAt',
  Id = 'id',
  MainAttributeId = 'mainAttributeId',
  Metadata = 'metadata',
  OfferingId = 'offeringId',
  ServiceId = 'serviceId',
  ServiceProviderId = 'serviceProviderId',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UserId = 'userId',
  UserRemarks = 'userRemarks'
}

export type BusinessApplicationScalarWhereInput = {
  AND?: InputMaybe<Array<BusinessApplicationScalarWhereInput>>;
  NOT?: InputMaybe<Array<BusinessApplicationScalarWhereInput>>;
  OR?: InputMaybe<Array<BusinessApplicationScalarWhereInput>>;
  approverId?: InputMaybe<StringNullableFilter>;
  approverRemarks?: InputMaybe<JsonNullableFilter>;
  attributeIds?: InputMaybe<IntNullableListFilter>;
  businessId?: InputMaybe<StringNullableFilter>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  mainAttributeId?: InputMaybe<IntFilter>;
  metadata?: InputMaybe<JsonFilter>;
  offeringId?: InputMaybe<StringNullableFilter>;
  serviceId?: InputMaybe<IntFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumBusinessApplicationStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  userRemarks?: InputMaybe<JsonNullableFilter>;
};

export enum BusinessApplicationStatus {
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Pending = 'Pending',
  Rejected = 'Rejected',
  RequiresAction = 'RequiresAction'
}

export type BusinessApplicationUncheckedCreateNestedManyWithoutBusinessInput = {
  connect?: InputMaybe<Array<BusinessApplicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BusinessApplicationCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<BusinessApplicationCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<BusinessApplicationCreateManyBusinessInputEnvelope>;
};

export type BusinessApplicationUncheckedCreateWithoutBusinessInput = {
  approverId?: InputMaybe<Scalars['String']>;
  approverRemarks?: InputMaybe<Scalars['JSON']>;
  attributeIds?: InputMaybe<Array<Scalars['Int']>>;
  countryCode?: InputMaybe<CountryCode>;
  id?: InputMaybe<Scalars['ID']>;
  metadata: Scalars['JSON'];
  offeringId?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['Int'];
  status?: InputMaybe<BusinessApplicationStatus>;
  userRemarks?: InputMaybe<Scalars['JSON']>;
};

export type BusinessApplicationUncheckedUpdateManyWithoutApplicationsInput = {
  approverId?: InputMaybe<Scalars['String']>;
  approverRemarks?: InputMaybe<Scalars['JSON']>;
  attributeIds?: InputMaybe<Array<Scalars['Int']>>;
  countryCode?: InputMaybe<CountryCode>;
  id?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  offeringId?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<BusinessApplicationStatus>;
  userRemarks?: InputMaybe<Scalars['JSON']>;
};

export type BusinessApplicationUncheckedUpdateManyWithoutBusinessNestedInput = {
  connect?: InputMaybe<Array<BusinessApplicationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BusinessApplicationCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<BusinessApplicationCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<BusinessApplicationCreateManyBusinessInputEnvelope>;
  delete?: InputMaybe<Array<BusinessApplicationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BusinessApplicationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BusinessApplicationWhereUniqueInput>>;
  set?: InputMaybe<Array<BusinessApplicationWhereUniqueInput>>;
  update?: InputMaybe<Array<BusinessApplicationUpdateWithWhereUniqueWithoutBusinessInput>>;
  updateMany?: InputMaybe<Array<BusinessApplicationUpdateManyWithWhereWithoutBusinessInput>>;
  upsert?: InputMaybe<Array<BusinessApplicationUpsertWithWhereUniqueWithoutBusinessInput>>;
};

export type BusinessApplicationUncheckedUpdateWithoutBusinessInput = {
  approverId?: InputMaybe<Scalars['String']>;
  approverRemarks?: InputMaybe<Scalars['JSON']>;
  attributeIds?: InputMaybe<Array<Scalars['Int']>>;
  countryCode?: InputMaybe<CountryCode>;
  id?: InputMaybe<Scalars['ID']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  offeringId?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<BusinessApplicationStatus>;
  userRemarks?: InputMaybe<Scalars['JSON']>;
};

export type BusinessApplicationUpdateManyWithWhereWithoutBusinessInput = {
  data: BusinessApplicationUncheckedUpdateManyWithoutApplicationsInput;
  where: BusinessApplicationScalarWhereInput;
};

export type BusinessApplicationUpdateWithWhereUniqueWithoutBusinessInput = {
  data: BusinessApplicationUncheckedUpdateWithoutBusinessInput;
  where: BusinessApplicationWhereUniqueInput;
};

export type BusinessApplicationUpsertWithWhereUniqueWithoutBusinessInput = {
  create: BusinessApplicationUncheckedCreateWithoutBusinessInput;
  update: BusinessApplicationUncheckedUpdateWithoutBusinessInput;
  where: BusinessApplicationWhereUniqueInput;
};

export type BusinessApplicationWhereInput = {
  AND?: InputMaybe<Array<BusinessApplicationWhereInput>>;
  NOT?: InputMaybe<Array<BusinessApplicationWhereInput>>;
  OR?: InputMaybe<Array<BusinessApplicationWhereInput>>;
  approver?: InputMaybe<AdminUserRelationFilter>;
  approverId?: InputMaybe<StringNullableFilter>;
  approverRemarks?: InputMaybe<JsonNullableFilter>;
  attributeIds?: InputMaybe<IntNullableListFilter>;
  business?: InputMaybe<BusinessRelationFilter>;
  businessId?: InputMaybe<StringNullableFilter>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  mainAttributeId?: InputMaybe<IntFilter>;
  metadata?: InputMaybe<JsonFilter>;
  offering?: InputMaybe<OfferingRelationFilter>;
  offeringId?: InputMaybe<StringNullableFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<IntFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumBusinessApplicationStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  userRemarks?: InputMaybe<JsonNullableFilter>;
};

export type BusinessApplicationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type BusinessCount = {
  __typename?: 'BusinessCount';
  applications: Scalars['Int'];
  offerings: Scalars['Int'];
};

export type BusinessCreateManyServiceInput = {
  approvedAttributeIds?: InputMaybe<Scalars['JSON']>;
  approvedMainAttributeIds?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<BusinessStatus>;
  supportingDocument?: InputMaybe<Scalars['JSON']>;
};

export type BusinessCreateManyServiceInputEnvelope = {
  data: Array<BusinessCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BusinessCreateOrConnectWithoutServiceInput = {
  create: BusinessUncheckedCreateWithoutServiceInput;
  where: BusinessWhereUniqueInput;
};

export type BusinessCreateWithoutServiceInput = {
  applications?: InputMaybe<BusinessApplicationCreateNestedManyWithoutBusinessInput>;
  approvedAttributeIds?: InputMaybe<Scalars['JSON']>;
  approvedMainAttributeIds?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  offerings?: InputMaybe<OfferingCreateNestedManyWithoutBusinessInput>;
  status?: InputMaybe<BusinessStatus>;
  supportingDocument?: InputMaybe<Scalars['JSON']>;
};

export type BusinessListRelationFilter = {
  every?: InputMaybe<BusinessWhereInput>;
  none?: InputMaybe<BusinessWhereInput>;
  some?: InputMaybe<BusinessWhereInput>;
};

export type BusinessOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BusinessOrderByWithRelationInput = {
  applications?: InputMaybe<BusinessApplicationOrderByRelationAggregateInput>;
  approvedAttributeIds?: InputMaybe<SortOrder>;
  approvedMainAttributeIds?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  offerings?: InputMaybe<OfferingOrderByRelationAggregateInput>;
  serviceId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  supportingDocument?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BusinessRelationFilter = {
  is?: InputMaybe<BusinessWhereInput>;
  isNot?: InputMaybe<BusinessWhereInput>;
};

export type BusinessScalarWhereInput = {
  AND?: InputMaybe<Array<BusinessScalarWhereInput>>;
  NOT?: InputMaybe<Array<BusinessScalarWhereInput>>;
  OR?: InputMaybe<Array<BusinessScalarWhereInput>>;
  approvedAttributeIds?: InputMaybe<JsonFilter>;
  approvedMainAttributeIds?: InputMaybe<JsonFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  serviceId?: InputMaybe<IntFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumBusinessStatusFilter>;
  supportingDocument?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BusinessServiceIdServiceProviderIdCompoundUniqueInput = {
  serviceId: Scalars['Int'];
  serviceProviderId: Scalars['String'];
};

export enum BusinessStatus {
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  PartiallyApproved = 'PartiallyApproved',
  Pending = 'Pending',
  Rejected = 'Rejected',
  RequireAction = 'RequireAction'
}

export type BusinessSubmissionAttribute = {
  __typename?: 'BusinessSubmissionAttribute';
  /** Only available in attribute with type MainAttribute */
  appliedBusinessAplication?: Maybe<BusinessApplication>;
  attributeId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  isAllowCustomAttribute?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  subAttributes?: Maybe<Array<BusinessSubmissionAttribute>>;
};

export type BusinessSubmissionAttributeOption = {
  __typename?: 'BusinessSubmissionAttributeOption';
  groupName: Scalars['String'];
  hasSubAttributes?: Maybe<Scalars['Boolean']>;
  values: Array<BusinessSubmissionAttribute>;
};

export enum BusinessSubmissionStepType {
  Attribute = 'Attribute',
  MainAttribute = 'MainAttribute',
  SupportingDocument = 'SupportingDocument',
  Workspace = 'Workspace'
}

export type BusinessSubmissionSubStep = {
  __typename?: 'BusinessSubmissionSubStep';
  attributeOptions?: Maybe<Array<BusinessSubmissionAttributeOption>>;
  dependentAttributeId?: Maybe<Scalars['Int']>;
  isMultipleInput: Scalars['Boolean'];
  stepLevel: Scalars['Int'];
  stepSequence: Scalars['Int'];
  type: BusinessSubmissionStepType;
};

export type BusinessUncheckedCreateInput = {
  applications?: InputMaybe<BusinessApplicationUncheckedCreateNestedManyWithoutBusinessInput>;
  approvedAttributeIds?: InputMaybe<Scalars['JSON']>;
  approvedMainAttributeIds?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  offerings?: InputMaybe<OfferingUncheckedCreateNestedManyWithoutBusinessInput>;
  serviceId: Scalars['Int'];
  status?: InputMaybe<BusinessStatus>;
  supportingDocument?: InputMaybe<Scalars['JSON']>;
};

export type BusinessUncheckedCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<BusinessWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BusinessCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<BusinessCreateWithoutServiceInput>>;
  createMany?: InputMaybe<BusinessCreateManyServiceInputEnvelope>;
};

export type BusinessUncheckedCreateWithoutServiceInput = {
  applications?: InputMaybe<BusinessApplicationUncheckedCreateNestedManyWithoutBusinessInput>;
  approvedAttributeIds?: InputMaybe<Scalars['JSON']>;
  approvedMainAttributeIds?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  offerings?: InputMaybe<OfferingUncheckedCreateNestedManyWithoutBusinessInput>;
  status?: InputMaybe<BusinessStatus>;
  supportingDocument?: InputMaybe<Scalars['JSON']>;
};

export type BusinessUncheckedUpdateInput = {
  applications?: InputMaybe<BusinessApplicationUncheckedUpdateManyWithoutBusinessNestedInput>;
  approvedAttributeIds?: InputMaybe<Scalars['JSON']>;
  approvedMainAttributeIds?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  offerings?: InputMaybe<OfferingUncheckedUpdateManyWithoutBusinessNestedInput>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<BusinessStatus>;
  supportingDocument?: InputMaybe<Scalars['JSON']>;
};

export type BusinessUncheckedUpdateManyWithoutBusinessesInput = {
  approvedAttributeIds?: InputMaybe<Scalars['JSON']>;
  approvedMainAttributeIds?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<BusinessStatus>;
  supportingDocument?: InputMaybe<Scalars['JSON']>;
};

export type BusinessUncheckedUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<BusinessWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BusinessCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<BusinessCreateWithoutServiceInput>>;
  createMany?: InputMaybe<BusinessCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<BusinessWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BusinessScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BusinessWhereUniqueInput>>;
  set?: InputMaybe<Array<BusinessWhereUniqueInput>>;
  update?: InputMaybe<Array<BusinessUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: InputMaybe<Array<BusinessUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: InputMaybe<Array<BusinessUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type BusinessUncheckedUpdateWithoutServiceInput = {
  applications?: InputMaybe<BusinessApplicationUncheckedUpdateManyWithoutBusinessNestedInput>;
  approvedAttributeIds?: InputMaybe<Scalars['JSON']>;
  approvedMainAttributeIds?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['ID']>;
  offerings?: InputMaybe<OfferingUncheckedUpdateManyWithoutBusinessNestedInput>;
  status?: InputMaybe<BusinessStatus>;
  supportingDocument?: InputMaybe<Scalars['JSON']>;
};

export type BusinessUpdateManyWithWhereWithoutServiceInput = {
  data: BusinessUncheckedUpdateManyWithoutBusinessesInput;
  where: BusinessScalarWhereInput;
};

export type BusinessUpdateWithWhereUniqueWithoutServiceInput = {
  data: BusinessUncheckedUpdateWithoutServiceInput;
  where: BusinessWhereUniqueInput;
};

export type BusinessUpsertWithWhereUniqueWithoutServiceInput = {
  create: BusinessUncheckedCreateWithoutServiceInput;
  update: BusinessUncheckedUpdateWithoutServiceInput;
  where: BusinessWhereUniqueInput;
};

export type BusinessWhereInput = {
  AND?: InputMaybe<Array<BusinessWhereInput>>;
  NOT?: InputMaybe<Array<BusinessWhereInput>>;
  OR?: InputMaybe<Array<BusinessWhereInput>>;
  applications?: InputMaybe<BusinessApplicationListRelationFilter>;
  approvedAttributeIds?: InputMaybe<JsonFilter>;
  approvedMainAttributeIds?: InputMaybe<JsonFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  offerings?: InputMaybe<OfferingListRelationFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<IntFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumBusinessStatusFilter>;
  supportingDocument?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BusinessWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  serviceId_serviceProviderId?: InputMaybe<BusinessServiceIdServiceProviderIdCompoundUniqueInput>;
};

export type CancellationReason = {
  __typename?: 'CancellationReason';
  _count?: Maybe<CancellationReasonCount>;
  code: Scalars['String'];
  description: Scalars['String'];
  for: OrderActor;
  id: Scalars['ID'];
  serviceId?: Maybe<Scalars['Int']>;
  status: Status;
  translation?: Maybe<CancellationReasonTranslation>;
  translations: Array<CancellationReasonTranslation>;
};


export type CancellationReasonTranslationArgs = {
  localeCode: LocaleCode;
};


export type CancellationReasonTranslationsArgs = {
  cursor?: InputMaybe<CancellationReasonTranslationWhereUniqueInput>;
  distinct?: InputMaybe<Array<CancellationReasonTranslationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CancellationReasonTranslationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CancellationReasonTranslationWhereInput>;
};

export type CancellationReasonAvgAggregate = {
  __typename?: 'CancellationReasonAvgAggregate';
  id?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Float']>;
};

export type CancellationReasonAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  serviceId?: InputMaybe<SortOrder>;
};

export type CancellationReasonCount = {
  __typename?: 'CancellationReasonCount';
  translations: Scalars['Int'];
};

export type CancellationReasonCountAggregate = {
  __typename?: 'CancellationReasonCountAggregate';
  _all: Scalars['Int'];
  code: Scalars['Int'];
  createdAt: Scalars['Int'];
  description: Scalars['Int'];
  for: Scalars['Int'];
  id: Scalars['Int'];
  serviceId: Scalars['Int'];
  status: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type CancellationReasonCountOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  for?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  serviceId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type CancellationReasonCreateManyInput = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  for: OrderActor;
  id?: InputMaybe<Scalars['ID']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
};

export type CancellationReasonGroupBy = {
  __typename?: 'CancellationReasonGroupBy';
  _avg?: Maybe<CancellationReasonAvgAggregate>;
  _count?: Maybe<CancellationReasonCountAggregate>;
  _max?: Maybe<CancellationReasonMaxAggregate>;
  _min?: Maybe<CancellationReasonMinAggregate>;
  _sum?: Maybe<CancellationReasonSumAggregate>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  for: OrderActor;
  id: Scalars['Int'];
  serviceId?: Maybe<Scalars['Int']>;
  status: Status;
  updatedAt: Scalars['DateTime'];
};

export type CancellationReasonMaxAggregate = {
  __typename?: 'CancellationReasonMaxAggregate';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  for?: Maybe<OrderActor>;
  id?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CancellationReasonMaxOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  for?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  serviceId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type CancellationReasonMinAggregate = {
  __typename?: 'CancellationReasonMinAggregate';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  for?: Maybe<OrderActor>;
  id?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
  status?: Maybe<Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CancellationReasonMinOrderByAggregateInput = {
  code?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  for?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  serviceId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
};

export type CancellationReasonOption = {
  __typename?: 'CancellationReasonOption';
  code: Scalars['String'];
  description: Scalars['String'];
  serviceId?: Maybe<Scalars['Int']>;
};

export type CancellationReasonOrderByWithAggregationInput = {
  _avg?: InputMaybe<CancellationReasonAvgOrderByAggregateInput>;
  _count?: InputMaybe<CancellationReasonCountOrderByAggregateInput>;
  _max?: InputMaybe<CancellationReasonMaxOrderByAggregateInput>;
  _min?: InputMaybe<CancellationReasonMinOrderByAggregateInput>;
  _sum?: InputMaybe<CancellationReasonSumOrderByAggregateInput>;
  code?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  for?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  serviceId?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
};

export type CancellationReasonOrderByWithRelationInput = {
  code?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  for?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  serviceId?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  translations?: InputMaybe<CancellationReasonTranslationOrderByRelationAggregateInput>;
};

export type CancellationReasonRelationFilter = {
  is?: InputMaybe<CancellationReasonWhereInput>;
  isNot?: InputMaybe<CancellationReasonWhereInput>;
};

export enum CancellationReasonScalarFieldEnum {
  Code = 'code',
  CreatedAt = 'createdAt',
  Description = 'description',
  For = 'for',
  Id = 'id',
  ServiceId = 'serviceId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type CancellationReasonScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<CancellationReasonScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<CancellationReasonScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<CancellationReasonScalarWhereWithAggregatesInput>>;
  code?: InputMaybe<StringWithAggregatesFilter>;
  description?: InputMaybe<StringNullableWithAggregatesFilter>;
  for?: InputMaybe<EnumOrderActorWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  serviceId?: InputMaybe<IntNullableWithAggregatesFilter>;
  status?: InputMaybe<EnumStatusWithAggregatesFilter>;
};

export type CancellationReasonSumAggregate = {
  __typename?: 'CancellationReasonSumAggregate';
  id?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['Int']>;
};

export type CancellationReasonSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  serviceId?: InputMaybe<SortOrder>;
};

export type CancellationReasonTranslation = {
  __typename?: 'CancellationReasonTranslation';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  localeCode: Scalars['String'];
};

export type CancellationReasonTranslationBaseIdLocaleCodeCompoundUniqueInput = {
  baseId: Scalars['Int'];
  localeCode: Scalars['String'];
};

export type CancellationReasonTranslationCreateManyCancellationReasonInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  localeCode: Scalars['String'];
};

export type CancellationReasonTranslationCreateManyCancellationReasonInputEnvelope = {
  data: Array<CancellationReasonTranslationCreateManyCancellationReasonInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CancellationReasonTranslationCreateOrConnectWithoutCancellationReasonInput = {
  create: CancellationReasonTranslationUncheckedCreateWithoutCancellationReasonInput;
  where: CancellationReasonTranslationWhereUniqueInput;
};

export type CancellationReasonTranslationCreateWithoutCancellationReasonInput = {
  description?: InputMaybe<Scalars['String']>;
  localeCode: Scalars['String'];
};

export type CancellationReasonTranslationListRelationFilter = {
  every?: InputMaybe<CancellationReasonTranslationWhereInput>;
  none?: InputMaybe<CancellationReasonTranslationWhereInput>;
  some?: InputMaybe<CancellationReasonTranslationWhereInput>;
};

export type CancellationReasonTranslationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CancellationReasonTranslationOrderByWithRelationInput = {
  baseId?: InputMaybe<SortOrder>;
  cancellationReason?: InputMaybe<CancellationReasonOrderByWithRelationInput>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  localeCode?: InputMaybe<SortOrder>;
};

export enum CancellationReasonTranslationScalarFieldEnum {
  BaseId = 'baseId',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  LocaleCode = 'localeCode',
  UpdatedAt = 'updatedAt'
}

export type CancellationReasonTranslationScalarWhereInput = {
  AND?: InputMaybe<Array<CancellationReasonTranslationScalarWhereInput>>;
  NOT?: InputMaybe<Array<CancellationReasonTranslationScalarWhereInput>>;
  OR?: InputMaybe<Array<CancellationReasonTranslationScalarWhereInput>>;
  baseId?: InputMaybe<IntFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  localeCode?: InputMaybe<StringFilter>;
};

export type CancellationReasonTranslationUncheckedCreateNestedManyWithoutCancellationReasonInput = {
  connect?: InputMaybe<Array<CancellationReasonTranslationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CancellationReasonTranslationCreateOrConnectWithoutCancellationReasonInput>>;
  create?: InputMaybe<Array<CancellationReasonTranslationCreateWithoutCancellationReasonInput>>;
  createMany?: InputMaybe<CancellationReasonTranslationCreateManyCancellationReasonInputEnvelope>;
};

export type CancellationReasonTranslationUncheckedCreateWithoutCancellationReasonInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  localeCode: Scalars['String'];
};

export type CancellationReasonTranslationUncheckedUpdateManyWithoutCancellationReasonNestedInput = {
  connect?: InputMaybe<Array<CancellationReasonTranslationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CancellationReasonTranslationCreateOrConnectWithoutCancellationReasonInput>>;
  create?: InputMaybe<Array<CancellationReasonTranslationCreateWithoutCancellationReasonInput>>;
  createMany?: InputMaybe<CancellationReasonTranslationCreateManyCancellationReasonInputEnvelope>;
  delete?: InputMaybe<Array<CancellationReasonTranslationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CancellationReasonTranslationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CancellationReasonTranslationWhereUniqueInput>>;
  set?: InputMaybe<Array<CancellationReasonTranslationWhereUniqueInput>>;
  update?: InputMaybe<Array<CancellationReasonTranslationUpdateWithWhereUniqueWithoutCancellationReasonInput>>;
  updateMany?: InputMaybe<Array<CancellationReasonTranslationUpdateManyWithWhereWithoutCancellationReasonInput>>;
  upsert?: InputMaybe<Array<CancellationReasonTranslationUpsertWithWhereUniqueWithoutCancellationReasonInput>>;
};

export type CancellationReasonTranslationUncheckedUpdateManyWithoutTranslationsInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  localeCode?: InputMaybe<Scalars['String']>;
};

export type CancellationReasonTranslationUncheckedUpdateWithoutCancellationReasonInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  localeCode?: InputMaybe<Scalars['String']>;
};

export type CancellationReasonTranslationUpdateManyWithWhereWithoutCancellationReasonInput = {
  data: CancellationReasonTranslationUncheckedUpdateManyWithoutTranslationsInput;
  where: CancellationReasonTranslationScalarWhereInput;
};

export type CancellationReasonTranslationUpdateWithWhereUniqueWithoutCancellationReasonInput = {
  data: CancellationReasonTranslationUncheckedUpdateWithoutCancellationReasonInput;
  where: CancellationReasonTranslationWhereUniqueInput;
};

export type CancellationReasonTranslationUpsertWithWhereUniqueWithoutCancellationReasonInput = {
  create: CancellationReasonTranslationUncheckedCreateWithoutCancellationReasonInput;
  update: CancellationReasonTranslationUncheckedUpdateWithoutCancellationReasonInput;
  where: CancellationReasonTranslationWhereUniqueInput;
};

export type CancellationReasonTranslationWhereInput = {
  AND?: InputMaybe<Array<CancellationReasonTranslationWhereInput>>;
  NOT?: InputMaybe<Array<CancellationReasonTranslationWhereInput>>;
  OR?: InputMaybe<Array<CancellationReasonTranslationWhereInput>>;
  baseId?: InputMaybe<IntFilter>;
  cancellationReason?: InputMaybe<CancellationReasonRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  localeCode?: InputMaybe<StringFilter>;
};

export type CancellationReasonTranslationWhereUniqueInput = {
  baseId_localeCode?: InputMaybe<CancellationReasonTranslationBaseIdLocaleCodeCompoundUniqueInput>;
  id?: InputMaybe<Scalars['ID']>;
};

export type CancellationReasonUncheckedCreateInput = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  for: OrderActor;
  id?: InputMaybe<Scalars['ID']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  translations?: InputMaybe<CancellationReasonTranslationUncheckedCreateNestedManyWithoutCancellationReasonInput>;
};

export type CancellationReasonUncheckedUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  for?: InputMaybe<OrderActor>;
  id?: InputMaybe<Scalars['ID']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
  translations?: InputMaybe<CancellationReasonTranslationUncheckedUpdateManyWithoutCancellationReasonNestedInput>;
};

export type CancellationReasonUncheckedUpdateManyInput = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  for?: InputMaybe<OrderActor>;
  id?: InputMaybe<Scalars['ID']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Status>;
};

export type CancellationReasonWhereInput = {
  AND?: InputMaybe<Array<CancellationReasonWhereInput>>;
  NOT?: InputMaybe<Array<CancellationReasonWhereInput>>;
  OR?: InputMaybe<Array<CancellationReasonWhereInput>>;
  code?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  for?: InputMaybe<EnumOrderActorFilter>;
  id?: InputMaybe<IntFilter>;
  serviceId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  translations?: InputMaybe<CancellationReasonTranslationListRelationFilter>;
};

export type CancellationReasonWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export enum ColorRating {
  Black = 'Black',
  Green = 'Green',
  Red = 'Red',
  Yellow = 'Yellow'
}

export type ColorRatingProgress = {
  __typename?: 'ColorRatingProgress';
  colorRating: ColorRating;
  currentPercentage: Scalars['Int'];
  maxPercentage: Scalars['Int'];
};

export type Coordinates = {
  __typename?: 'Coordinates';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type CoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export enum CountryCode {
  My = 'MY',
  Sg = 'SG'
}

export type Customer = {
  __typename?: 'Customer';
  _count?: Maybe<CustomerCount>;
  colorRating: ColorRating;
  colorRatingProgress: ColorRatingProgress;
  createdAt: Scalars['DateTime'];
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  hasPreferences: Scalars['Boolean'];
  id: Scalars['ID'];
  identityNumber?: Maybe<Scalars['String']>;
  mainRole?: Maybe<Role>;
  mobileNumber: Scalars['String'];
  orders: Array<Order>;
  ordersCount: Scalars['Int'];
  preferences: Array<CustomerPreference>;
  profilePicture?: Maybe<Scalars['String']>;
  reviews: Array<Review>;
  roles: Array<Role>;
  status: CustomerStatus;
  totalSpendings: Money;
  updatedAt: Scalars['DateTime'];
  wallets: Array<Wallet>;
};


export type CustomerOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type CustomerPreferencesArgs = {
  cursor?: InputMaybe<CustomerPreferenceWhereUniqueInput>;
  distinct?: InputMaybe<Array<CustomerPreferenceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CustomerPreferenceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomerPreferenceWhereInput>;
};


export type CustomerReviewsArgs = {
  cursor?: InputMaybe<ReviewWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReviewScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReviewOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReviewWhereInput>;
};


export type CustomerWalletsArgs = {
  cursor?: InputMaybe<WalletWhereUniqueInput>;
  distinct?: InputMaybe<Array<WalletScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WalletOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WalletWhereInput>;
};

export type CustomerCount = {
  __typename?: 'CustomerCount';
  fulfillmentTasks: Scalars['Int'];
  notificationSettings: Scalars['Int'];
  orders: Scalars['Int'];
  preferences: Scalars['Int'];
  reviews: Scalars['Int'];
  wallets: Scalars['Int'];
};

export type CustomerCreateNestedOneWithoutOrdersInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<CustomerUncheckedCreateWithoutOrdersInput>;
};

export type CustomerCreateNestedOneWithoutPreferencesInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutPreferencesInput>;
  create?: InputMaybe<CustomerUncheckedCreateWithoutPreferencesInput>;
};

export type CustomerCreateOrConnectWithoutOrdersInput = {
  create: CustomerUncheckedCreateWithoutOrdersInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutPreferencesInput = {
  create: CustomerUncheckedCreateWithoutPreferencesInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutUserInput = {
  create: CustomerUncheckedCreateWithoutUserInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerOrderByWithRelationInput = {
  colorRating?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dateOfBirth?: InputMaybe<SortOrderInput>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mobileNumber?: InputMaybe<SortOrder>;
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  ordersCount?: InputMaybe<SortOrder>;
  preferences?: InputMaybe<CustomerPreferenceOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  stripeCi?: InputMaybe<SortOrderInput>;
  stripeCiMY?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  wallets?: InputMaybe<WalletOrderByRelationAggregateInput>;
};

export type CustomerPreference = {
  __typename?: 'CustomerPreference';
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['String'];
  id: Scalars['ID'];
  preferredFacetIds: Scalars['JSON'];
  preferredFacetValueIds: Scalars['JSON'];
  service: Service;
  serviceId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type CustomerPreferenceCreateManyServiceInput = {
  customerId: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  preferredFacetIds?: InputMaybe<Scalars['JSON']>;
  preferredFacetValueIds?: InputMaybe<Scalars['JSON']>;
};

export type CustomerPreferenceCreateManyServiceInputEnvelope = {
  data: Array<CustomerPreferenceCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerPreferenceCreateOrConnectWithoutServiceInput = {
  create: CustomerPreferenceUncheckedCreateWithoutServiceInput;
  where: CustomerPreferenceWhereUniqueInput;
};

export type CustomerPreferenceCreateWithoutServiceInput = {
  customer: CustomerCreateNestedOneWithoutPreferencesInput;
  preferredFacetIds?: InputMaybe<Scalars['JSON']>;
  preferredFacetValueIds?: InputMaybe<Scalars['JSON']>;
};

export type CustomerPreferenceCustomerIdServiceIdCompoundUniqueInput = {
  customerId: Scalars['String'];
  serviceId: Scalars['Int'];
};

export type CustomerPreferenceFacet = {
  __typename?: 'CustomerPreferenceFacet';
  name: Scalars['String'];
  values: Array<CustomerPreferenceFacetValue>;
};

export type CustomerPreferenceFacetValue = {
  __typename?: 'CustomerPreferenceFacetValue';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type CustomerPreferenceGroup = {
  __typename?: 'CustomerPreferenceGroup';
  facets: Array<CustomerPreferenceFacet>;
  groupName: Scalars['String'];
};

export type CustomerPreferenceListRelationFilter = {
  every?: InputMaybe<CustomerPreferenceWhereInput>;
  none?: InputMaybe<CustomerPreferenceWhereInput>;
  some?: InputMaybe<CustomerPreferenceWhereInput>;
};

export type CustomerPreferenceOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CustomerPreferenceOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  preferredFacetIds?: InputMaybe<SortOrder>;
  preferredFacetValueIds?: InputMaybe<SortOrder>;
  service?: InputMaybe<ServiceOrderByWithRelationInput>;
  serviceId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum CustomerPreferenceScalarFieldEnum {
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  Id = 'id',
  PreferredFacetIds = 'preferredFacetIds',
  PreferredFacetValueIds = 'preferredFacetValueIds',
  ServiceId = 'serviceId',
  UpdatedAt = 'updatedAt'
}

export type CustomerPreferenceScalarWhereInput = {
  AND?: InputMaybe<Array<CustomerPreferenceScalarWhereInput>>;
  NOT?: InputMaybe<Array<CustomerPreferenceScalarWhereInput>>;
  OR?: InputMaybe<Array<CustomerPreferenceScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  preferredFacetIds?: InputMaybe<JsonFilter>;
  preferredFacetValueIds?: InputMaybe<JsonFilter>;
  serviceId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CustomerPreferenceService = {
  __typename?: 'CustomerPreferenceService';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type CustomerPreferenceUncheckedCreateInput = {
  customerId: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  preferredFacetIds?: InputMaybe<Scalars['JSON']>;
  preferredFacetValueIds?: InputMaybe<Scalars['JSON']>;
};

export type CustomerPreferenceUncheckedCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<CustomerPreferenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomerPreferenceCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<CustomerPreferenceCreateWithoutServiceInput>>;
  createMany?: InputMaybe<CustomerPreferenceCreateManyServiceInputEnvelope>;
};

export type CustomerPreferenceUncheckedCreateWithoutServiceInput = {
  customerId: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  preferredFacetIds?: InputMaybe<Scalars['JSON']>;
  preferredFacetValueIds?: InputMaybe<Scalars['JSON']>;
};

export type CustomerPreferenceUncheckedUpdateInput = {
  customerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  preferredFacetIds?: InputMaybe<Scalars['JSON']>;
  preferredFacetValueIds?: InputMaybe<Scalars['JSON']>;
};

export type CustomerPreferenceUncheckedUpdateManyWithoutCustomerPreferencesInput = {
  customerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  preferredFacetIds?: InputMaybe<Scalars['JSON']>;
  preferredFacetValueIds?: InputMaybe<Scalars['JSON']>;
};

export type CustomerPreferenceUncheckedUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<CustomerPreferenceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CustomerPreferenceCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<CustomerPreferenceCreateWithoutServiceInput>>;
  createMany?: InputMaybe<CustomerPreferenceCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<CustomerPreferenceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomerPreferenceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomerPreferenceWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomerPreferenceWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomerPreferenceUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: InputMaybe<Array<CustomerPreferenceUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: InputMaybe<Array<CustomerPreferenceUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type CustomerPreferenceUncheckedUpdateWithoutServiceInput = {
  customerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  preferredFacetIds?: InputMaybe<Scalars['JSON']>;
  preferredFacetValueIds?: InputMaybe<Scalars['JSON']>;
};

export type CustomerPreferenceUpdateManyWithWhereWithoutServiceInput = {
  data: CustomerPreferenceUncheckedUpdateManyWithoutCustomerPreferencesInput;
  where: CustomerPreferenceScalarWhereInput;
};

export type CustomerPreferenceUpdateWithWhereUniqueWithoutServiceInput = {
  data: CustomerPreferenceUncheckedUpdateWithoutServiceInput;
  where: CustomerPreferenceWhereUniqueInput;
};

export type CustomerPreferenceUpsertWithWhereUniqueWithoutServiceInput = {
  create: CustomerPreferenceUncheckedCreateWithoutServiceInput;
  update: CustomerPreferenceUncheckedUpdateWithoutServiceInput;
  where: CustomerPreferenceWhereUniqueInput;
};

export type CustomerPreferenceWhereInput = {
  AND?: InputMaybe<Array<CustomerPreferenceWhereInput>>;
  NOT?: InputMaybe<Array<CustomerPreferenceWhereInput>>;
  OR?: InputMaybe<Array<CustomerPreferenceWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  preferredFacetIds?: InputMaybe<JsonFilter>;
  preferredFacetValueIds?: InputMaybe<JsonFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CustomerPreferenceWhereUniqueInput = {
  customerId_serviceId?: InputMaybe<CustomerPreferenceCustomerIdServiceIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['ID']>;
};

export type CustomerRelationFilter = {
  is?: InputMaybe<CustomerWhereInput>;
  isNot?: InputMaybe<CustomerWhereInput>;
};

export enum CustomerStatus {
  Active = 'Active',
  Blocked = 'Blocked',
  Deleted = 'Deleted'
}

export type CustomerUncheckedCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<CustomerUncheckedCreateWithoutUserInput>;
};

export type CustomerUncheckedCreateWithoutOrdersInput = {
  colorRating?: InputMaybe<ColorRating>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  id: Scalars['ID'];
  identityNumber?: InputMaybe<Scalars['String']>;
  mobileNumber: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CustomerStatus>;
  stripeCi?: InputMaybe<Scalars['String']>;
  stripeCiMY?: InputMaybe<Scalars['String']>;
  wallets?: InputMaybe<WalletUncheckedCreateNestedManyWithoutCustomerInput>;
};

export type CustomerUncheckedCreateWithoutPreferencesInput = {
  colorRating?: InputMaybe<ColorRating>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  id: Scalars['ID'];
  identityNumber?: InputMaybe<Scalars['String']>;
  mobileNumber: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CustomerStatus>;
  stripeCi?: InputMaybe<Scalars['String']>;
  stripeCiMY?: InputMaybe<Scalars['String']>;
  wallets?: InputMaybe<WalletUncheckedCreateNestedManyWithoutCustomerInput>;
};

export type CustomerUncheckedCreateWithoutUserInput = {
  colorRating?: InputMaybe<ColorRating>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  identityNumber?: InputMaybe<Scalars['String']>;
  mobileNumber: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CustomerStatus>;
  stripeCi?: InputMaybe<Scalars['String']>;
  stripeCiMY?: InputMaybe<Scalars['String']>;
  wallets?: InputMaybe<WalletUncheckedCreateNestedManyWithoutCustomerInput>;
};

export type CustomerUncheckedUpdateInput = {
  colorRating?: InputMaybe<ColorRating>;
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CustomerStatus>;
  stripeCi?: InputMaybe<Scalars['String']>;
  stripeCiMY?: InputMaybe<Scalars['String']>;
  wallets?: InputMaybe<WalletUncheckedUpdateManyWithoutCustomerNestedInput>;
};

export type CustomerWhereInput = {
  AND?: InputMaybe<Array<CustomerWhereInput>>;
  NOT?: InputMaybe<Array<CustomerWhereInput>>;
  OR?: InputMaybe<Array<CustomerWhereInput>>;
  colorRating?: InputMaybe<EnumColorRatingFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateOfBirth?: InputMaybe<DateTimeNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  fullName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  identityNumber?: InputMaybe<StringNullableFilter>;
  mobileNumber?: InputMaybe<StringFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  ordersCount?: InputMaybe<IntFilter>;
  preferences?: InputMaybe<CustomerPreferenceListRelationFilter>;
  profilePicture?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumCustomerStatusFilter>;
  stripeCi?: InputMaybe<StringNullableFilter>;
  stripeCiMY?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  wallets?: InputMaybe<WalletListRelationFilter>;
};

export type CustomerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
};

export type DashboardConfiguration = {
  __typename?: 'DashboardConfiguration';
  id: Scalars['ID'];
  iframeUrl: Scalars['String'];
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export enum DayOfWeek {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type EnumAvailabilityTypeFilter = {
  equals?: InputMaybe<AvailabilityType>;
  in?: InputMaybe<Array<AvailabilityType>>;
  not?: InputMaybe<NestedEnumAvailabilityTypeFilter>;
  notIn?: InputMaybe<Array<AvailabilityType>>;
};

export type EnumBalanceTypeFilter = {
  equals?: InputMaybe<BalanceType>;
  in?: InputMaybe<Array<BalanceType>>;
  not?: InputMaybe<NestedEnumBalanceTypeFilter>;
  notIn?: InputMaybe<Array<BalanceType>>;
};

export type EnumBusinessApplicationStatusFilter = {
  equals?: InputMaybe<BusinessApplicationStatus>;
  in?: InputMaybe<Array<BusinessApplicationStatus>>;
  not?: InputMaybe<NestedEnumBusinessApplicationStatusFilter>;
  notIn?: InputMaybe<Array<BusinessApplicationStatus>>;
};

export type EnumBusinessStatusFilter = {
  equals?: InputMaybe<BusinessStatus>;
  in?: InputMaybe<Array<BusinessStatus>>;
  not?: InputMaybe<NestedEnumBusinessStatusFilter>;
  notIn?: InputMaybe<Array<BusinessStatus>>;
};

export type EnumColorRatingFilter = {
  equals?: InputMaybe<ColorRating>;
  in?: InputMaybe<Array<ColorRating>>;
  not?: InputMaybe<NestedEnumColorRatingFilter>;
  notIn?: InputMaybe<Array<ColorRating>>;
};

export type EnumCountryCodeFilter = {
  equals?: InputMaybe<CountryCode>;
  in?: InputMaybe<Array<CountryCode>>;
  not?: InputMaybe<NestedEnumCountryCodeFilter>;
  notIn?: InputMaybe<Array<CountryCode>>;
};

export type EnumCountryCodeNullableFilter = {
  equals?: InputMaybe<CountryCode>;
  in?: InputMaybe<Array<CountryCode>>;
  not?: InputMaybe<NestedEnumCountryCodeNullableFilter>;
  notIn?: InputMaybe<Array<CountryCode>>;
};

export type EnumCustomerStatusFilter = {
  equals?: InputMaybe<CustomerStatus>;
  in?: InputMaybe<Array<CustomerStatus>>;
  not?: InputMaybe<NestedEnumCustomerStatusFilter>;
  notIn?: InputMaybe<Array<CustomerStatus>>;
};

export type EnumFacetFilterGroupTypeFilter = {
  equals?: InputMaybe<FacetFilterGroupType>;
  in?: InputMaybe<Array<FacetFilterGroupType>>;
  not?: InputMaybe<NestedEnumFacetFilterGroupTypeFilter>;
  notIn?: InputMaybe<Array<FacetFilterGroupType>>;
};

export type EnumFrequencyTypeNullableFilter = {
  equals?: InputMaybe<FrequencyType>;
  in?: InputMaybe<Array<FrequencyType>>;
  not?: InputMaybe<NestedEnumFrequencyTypeNullableFilter>;
  notIn?: InputMaybe<Array<FrequencyType>>;
};

export type EnumFulfillmentFailedStateNullableFilter = {
  equals?: InputMaybe<FulfillmentFailedState>;
  in?: InputMaybe<Array<FulfillmentFailedState>>;
  not?: InputMaybe<NestedEnumFulfillmentFailedStateNullableFilter>;
  notIn?: InputMaybe<Array<FulfillmentFailedState>>;
};

export type EnumFulfillmentStatusFilter = {
  equals?: InputMaybe<FulfillmentStatus>;
  in?: InputMaybe<Array<FulfillmentStatus>>;
  not?: InputMaybe<NestedEnumFulfillmentStatusFilter>;
  notIn?: InputMaybe<Array<FulfillmentStatus>>;
};

export type EnumFulfillmentTaskFailedStateNullableFilter = {
  equals?: InputMaybe<FulfillmentTaskFailedState>;
  in?: InputMaybe<Array<FulfillmentTaskFailedState>>;
  not?: InputMaybe<NestedEnumFulfillmentTaskFailedStateNullableFilter>;
  notIn?: InputMaybe<Array<FulfillmentTaskFailedState>>;
};

export type EnumFulfillmentTaskStatusFilter = {
  equals?: InputMaybe<FulfillmentTaskStatus>;
  in?: InputMaybe<Array<FulfillmentTaskStatus>>;
  not?: InputMaybe<NestedEnumFulfillmentTaskStatusFilter>;
  notIn?: InputMaybe<Array<FulfillmentTaskStatus>>;
};

export type EnumGenderNullableFilter = {
  equals?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  not?: InputMaybe<NestedEnumGenderNullableFilter>;
  notIn?: InputMaybe<Array<Gender>>;
};

export type EnumNotificationTypeFilter = {
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type EnumOfferingStatusFilter = {
  equals?: InputMaybe<OfferingStatus>;
  in?: InputMaybe<Array<OfferingStatus>>;
  not?: InputMaybe<NestedEnumOfferingStatusFilter>;
  notIn?: InputMaybe<Array<OfferingStatus>>;
};

export type EnumOrderActorFilter = {
  equals?: InputMaybe<OrderActor>;
  in?: InputMaybe<Array<OrderActor>>;
  not?: InputMaybe<NestedEnumOrderActorFilter>;
  notIn?: InputMaybe<Array<OrderActor>>;
};

export type EnumOrderActorNullableFilter = {
  equals?: InputMaybe<OrderActor>;
  in?: InputMaybe<Array<OrderActor>>;
  not?: InputMaybe<NestedEnumOrderActorNullableFilter>;
  notIn?: InputMaybe<Array<OrderActor>>;
};

export type EnumOrderActorWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumOrderActorFilter>;
  _min?: InputMaybe<NestedEnumOrderActorFilter>;
  equals?: InputMaybe<OrderActor>;
  in?: InputMaybe<Array<OrderActor>>;
  not?: InputMaybe<NestedEnumOrderActorWithAggregatesFilter>;
  notIn?: InputMaybe<Array<OrderActor>>;
};

export type EnumOrderCancelResolutionTypeNullableFilter = {
  equals?: InputMaybe<OrderCancelResolutionType>;
  in?: InputMaybe<Array<OrderCancelResolutionType>>;
  not?: InputMaybe<NestedEnumOrderCancelResolutionTypeNullableFilter>;
  notIn?: InputMaybe<Array<OrderCancelResolutionType>>;
};

export type EnumOrderRefundReviewStatusNullableFilter = {
  equals?: InputMaybe<OrderRefundReviewStatus>;
  in?: InputMaybe<Array<OrderRefundReviewStatus>>;
  not?: InputMaybe<NestedEnumOrderRefundReviewStatusNullableFilter>;
  notIn?: InputMaybe<Array<OrderRefundReviewStatus>>;
};

export type EnumOrderRefundStatusNullableFilter = {
  equals?: InputMaybe<OrderRefundStatus>;
  in?: InputMaybe<Array<OrderRefundStatus>>;
  not?: InputMaybe<NestedEnumOrderRefundStatusNullableFilter>;
  notIn?: InputMaybe<Array<OrderRefundStatus>>;
};

export type EnumOrderStateFilter = {
  equals?: InputMaybe<OrderState>;
  in?: InputMaybe<Array<OrderState>>;
  not?: InputMaybe<NestedEnumOrderStateFilter>;
  notIn?: InputMaybe<Array<OrderState>>;
};

export type EnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type EnumPaymentMethodFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type EnumPaymentProcessorFilter = {
  equals?: InputMaybe<PaymentProcessor>;
  in?: InputMaybe<Array<PaymentProcessor>>;
  not?: InputMaybe<NestedEnumPaymentProcessorFilter>;
  notIn?: InputMaybe<Array<PaymentProcessor>>;
};

export type EnumPaymentStatusFilter = {
  equals?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  not?: InputMaybe<NestedEnumPaymentStatusFilter>;
  notIn?: InputMaybe<Array<PaymentStatus>>;
};

export type EnumPayoutStatusFilter = {
  equals?: InputMaybe<PayoutStatus>;
  in?: InputMaybe<Array<PayoutStatus>>;
  not?: InputMaybe<NestedEnumPayoutStatusFilter>;
  notIn?: InputMaybe<Array<PayoutStatus>>;
};

export type EnumPeriodTypeFilter = {
  equals?: InputMaybe<PeriodType>;
  in?: InputMaybe<Array<PeriodType>>;
  not?: InputMaybe<NestedEnumPeriodTypeFilter>;
  notIn?: InputMaybe<Array<PeriodType>>;
};

export type EnumReviewTypeFilter = {
  equals?: InputMaybe<ReviewType>;
  in?: InputMaybe<Array<ReviewType>>;
  not?: InputMaybe<NestedEnumReviewTypeFilter>;
  notIn?: InputMaybe<Array<ReviewType>>;
};

export type EnumServiceProviderStatusFilter = {
  equals?: InputMaybe<ServiceProviderStatus>;
  in?: InputMaybe<Array<ServiceProviderStatus>>;
  not?: InputMaybe<NestedEnumServiceProviderStatusFilter>;
  notIn?: InputMaybe<Array<ServiceProviderStatus>>;
};

export type EnumServiceProviderVerificationStatusFilter = {
  equals?: InputMaybe<ServiceProviderVerificationStatus>;
  in?: InputMaybe<Array<ServiceProviderVerificationStatus>>;
  not?: InputMaybe<NestedEnumServiceProviderVerificationStatusFilter>;
  notIn?: InputMaybe<Array<ServiceProviderVerificationStatus>>;
};

export type EnumServiceTypeFilter = {
  equals?: InputMaybe<ServiceType>;
  in?: InputMaybe<Array<ServiceType>>;
  not?: InputMaybe<NestedEnumServiceTypeFilter>;
  notIn?: InputMaybe<Array<ServiceType>>;
};

export type EnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type EnumStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStatusFilter>;
  _min?: InputMaybe<NestedEnumStatusFilter>;
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type EnumSystemSettingGroupFilter = {
  equals?: InputMaybe<SystemSettingGroup>;
  in?: InputMaybe<Array<SystemSettingGroup>>;
  not?: InputMaybe<NestedEnumSystemSettingGroupFilter>;
  notIn?: InputMaybe<Array<SystemSettingGroup>>;
};

export type EnumTopupMethodFilter = {
  equals?: InputMaybe<TopupMethod>;
  in?: InputMaybe<Array<TopupMethod>>;
  not?: InputMaybe<NestedEnumTopupMethodFilter>;
  notIn?: InputMaybe<Array<TopupMethod>>;
};

export type EnumTopupStatusFilter = {
  equals?: InputMaybe<TopupStatus>;
  in?: InputMaybe<Array<TopupStatus>>;
  not?: InputMaybe<NestedEnumTopupStatusFilter>;
  notIn?: InputMaybe<Array<TopupStatus>>;
};

export type EnumTransactionStatusFilter = {
  equals?: InputMaybe<TransactionStatus>;
  in?: InputMaybe<Array<TransactionStatus>>;
  not?: InputMaybe<NestedEnumTransactionStatusFilter>;
  notIn?: InputMaybe<Array<TransactionStatus>>;
};

export type EnumTransactionTypeFilter = {
  equals?: InputMaybe<TransactionType>;
  in?: InputMaybe<Array<TransactionType>>;
  not?: InputMaybe<NestedEnumTransactionTypeFilter>;
  notIn?: InputMaybe<Array<TransactionType>>;
};

export type EnumUserNotificationIconTypeFilter = {
  equals?: InputMaybe<UserNotificationIconType>;
  in?: InputMaybe<Array<UserNotificationIconType>>;
  not?: InputMaybe<NestedEnumUserNotificationIconTypeFilter>;
  notIn?: InputMaybe<Array<UserNotificationIconType>>;
};

export type EnumUserStatusFilter = {
  equals?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  not?: InputMaybe<NestedEnumUserStatusFilter>;
  notIn?: InputMaybe<Array<UserStatus>>;
};

export type EnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type EnumWithdrawalStatusFilter = {
  equals?: InputMaybe<WithdrawalStatus>;
  in?: InputMaybe<Array<WithdrawalStatus>>;
  not?: InputMaybe<NestedEnumWithdrawalStatusFilter>;
  notIn?: InputMaybe<Array<WithdrawalStatus>>;
};

export type EnumWithdrawalTypeFilter = {
  equals?: InputMaybe<WithdrawalType>;
  in?: InputMaybe<Array<WithdrawalType>>;
  not?: InputMaybe<NestedEnumWithdrawalTypeFilter>;
  notIn?: InputMaybe<Array<WithdrawalType>>;
};

export type EstimatedOrder = {
  __typename?: 'EstimatedOrder';
  amountSummary: OrderAmountSummary;
  date: Scalars['DateTime'];
  formattedDate: Scalars['String'];
  formattedSessionSlots: Array<Scalars['String']>;
  sessionSlots: Array<BaseAvailableSessionSlot>;
};

export type Facet = {
  __typename?: 'Facet';
  _count?: Maybe<FacetCount>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FacetCount = {
  __typename?: 'FacetCount';
  facetValues: Scalars['Int'];
  offeringAttributes: Scalars['Int'];
};

export type FacetCreateNestedOneWithoutFacetValuesInput = {
  connect?: InputMaybe<FacetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FacetCreateOrConnectWithoutFacetValuesInput>;
  create?: InputMaybe<FacetUncheckedCreateWithoutFacetValuesInput>;
};

export type FacetCreateNestedOneWithoutOfferingAttributesInput = {
  connect?: InputMaybe<FacetWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FacetCreateOrConnectWithoutOfferingAttributesInput>;
  create?: InputMaybe<FacetUncheckedCreateWithoutOfferingAttributesInput>;
};

export type FacetCreateOrConnectWithoutFacetValuesInput = {
  create: FacetUncheckedCreateWithoutFacetValuesInput;
  where: FacetWhereUniqueInput;
};

export type FacetCreateOrConnectWithoutOfferingAttributesInput = {
  create: FacetUncheckedCreateWithoutOfferingAttributesInput;
  where: FacetWhereUniqueInput;
};

export type FacetFilterGroupCreateManyServiceInput = {
  facetIds?: InputMaybe<Scalars['JSON']>;
  filterDepthLevel?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  sequence?: InputMaybe<Scalars['Int']>;
  slug: Scalars['String'];
  type?: InputMaybe<FacetFilterGroupType>;
};

export type FacetFilterGroupCreateManyServiceInputEnvelope = {
  data: Array<FacetFilterGroupCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FacetFilterGroupCreateOrConnectWithoutServiceInput = {
  create: FacetFilterGroupUncheckedCreateWithoutServiceInput;
  where: FacetFilterGroupWhereUniqueInput;
};

export type FacetFilterGroupCreateWithoutServiceInput = {
  facetIds?: InputMaybe<Scalars['JSON']>;
  filterDepthLevel?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  sequence?: InputMaybe<Scalars['Int']>;
  slug: Scalars['String'];
  type?: InputMaybe<FacetFilterGroupType>;
};

export type FacetFilterGroupListRelationFilter = {
  every?: InputMaybe<FacetFilterGroupWhereInput>;
  none?: InputMaybe<FacetFilterGroupWhereInput>;
  some?: InputMaybe<FacetFilterGroupWhereInput>;
};

export type FacetFilterGroupOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FacetFilterGroupScalarWhereInput = {
  AND?: InputMaybe<Array<FacetFilterGroupScalarWhereInput>>;
  NOT?: InputMaybe<Array<FacetFilterGroupScalarWhereInput>>;
  OR?: InputMaybe<Array<FacetFilterGroupScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  facetIds?: InputMaybe<JsonFilter>;
  filterDepthLevel?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  sequence?: InputMaybe<IntFilter>;
  serviceId?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumFacetFilterGroupTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FacetFilterGroupServiceIdSlugTypeCompoundUniqueInput = {
  serviceId: Scalars['Int'];
  slug: Scalars['String'];
  type: FacetFilterGroupType;
};

export enum FacetFilterGroupType {
  CustomerPreference = 'CustomerPreference',
  Default = 'Default'
}

export type FacetFilterGroupUncheckedCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<FacetFilterGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FacetFilterGroupCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<FacetFilterGroupCreateWithoutServiceInput>>;
  createMany?: InputMaybe<FacetFilterGroupCreateManyServiceInputEnvelope>;
};

export type FacetFilterGroupUncheckedCreateWithoutServiceInput = {
  facetIds?: InputMaybe<Scalars['JSON']>;
  filterDepthLevel?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  sequence?: InputMaybe<Scalars['Int']>;
  slug: Scalars['String'];
  type?: InputMaybe<FacetFilterGroupType>;
};

export type FacetFilterGroupUncheckedUpdateManyWithoutFilterGroupsInput = {
  facetIds?: InputMaybe<Scalars['JSON']>;
  filterDepthLevel?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<FacetFilterGroupType>;
};

export type FacetFilterGroupUncheckedUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<FacetFilterGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FacetFilterGroupCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<FacetFilterGroupCreateWithoutServiceInput>>;
  createMany?: InputMaybe<FacetFilterGroupCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<FacetFilterGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FacetFilterGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FacetFilterGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<FacetFilterGroupWhereUniqueInput>>;
  update?: InputMaybe<Array<FacetFilterGroupUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: InputMaybe<Array<FacetFilterGroupUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: InputMaybe<Array<FacetFilterGroupUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type FacetFilterGroupUncheckedUpdateWithoutServiceInput = {
  facetIds?: InputMaybe<Scalars['JSON']>;
  filterDepthLevel?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  sequence?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<FacetFilterGroupType>;
};

export type FacetFilterGroupUpdateManyWithWhereWithoutServiceInput = {
  data: FacetFilterGroupUncheckedUpdateManyWithoutFilterGroupsInput;
  where: FacetFilterGroupScalarWhereInput;
};

export type FacetFilterGroupUpdateWithWhereUniqueWithoutServiceInput = {
  data: FacetFilterGroupUncheckedUpdateWithoutServiceInput;
  where: FacetFilterGroupWhereUniqueInput;
};

export type FacetFilterGroupUpsertWithWhereUniqueWithoutServiceInput = {
  create: FacetFilterGroupUncheckedCreateWithoutServiceInput;
  update: FacetFilterGroupUncheckedUpdateWithoutServiceInput;
  where: FacetFilterGroupWhereUniqueInput;
};

export type FacetFilterGroupWhereInput = {
  AND?: InputMaybe<Array<FacetFilterGroupWhereInput>>;
  NOT?: InputMaybe<Array<FacetFilterGroupWhereInput>>;
  OR?: InputMaybe<Array<FacetFilterGroupWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  facetIds?: InputMaybe<JsonFilter>;
  filterDepthLevel?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  sequence?: InputMaybe<IntFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumFacetFilterGroupTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FacetFilterGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  serviceId_slug_type?: InputMaybe<FacetFilterGroupServiceIdSlugTypeCompoundUniqueInput>;
};

export type FacetRelationFilter = {
  is?: InputMaybe<FacetWhereInput>;
  isNot?: InputMaybe<FacetWhereInput>;
};

export type FacetUncheckedCreateInput = {
  code: Scalars['String'];
  facetValues?: InputMaybe<FacetValueUncheckedCreateNestedManyWithoutFacetInput>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  offeringAttributes?: InputMaybe<OfferingAttributeUncheckedCreateNestedManyWithoutFacetInput>;
  shortName: Scalars['String'];
};

export type FacetUncheckedCreateWithoutFacetValuesInput = {
  code: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  offeringAttributes?: InputMaybe<OfferingAttributeUncheckedCreateNestedManyWithoutFacetInput>;
  shortName: Scalars['String'];
};

export type FacetUncheckedCreateWithoutOfferingAttributesInput = {
  code: Scalars['String'];
  facetValues?: InputMaybe<FacetValueUncheckedCreateNestedManyWithoutFacetInput>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  shortName: Scalars['String'];
};

export type FacetUncheckedUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  facetValues?: InputMaybe<FacetValueUncheckedUpdateManyWithoutFacetNestedInput>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  offeringAttributes?: InputMaybe<OfferingAttributeUncheckedUpdateManyWithoutFacetNestedInput>;
  shortName?: InputMaybe<Scalars['String']>;
};

export type FacetValue = {
  __typename?: 'FacetValue';
  _count?: Maybe<FacetValueCount>;
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  facetId: Scalars['Int'];
  filterDescription?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FacetValueCount = {
  __typename?: 'FacetValueCount';
  groupedValues: Scalars['Int'];
  offeringAttributes: Scalars['Int'];
};

export type FacetValueCreateManyFacetInput = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  excludedIds?: InputMaybe<Scalars['JSON']>;
  filterDescription?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  isTaggable?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type FacetValueCreateManyFacetInputEnvelope = {
  data: Array<FacetValueCreateManyFacetInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FacetValueCreateManyGroupInput = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  excludedIds?: InputMaybe<Scalars['JSON']>;
  facetId: Scalars['Int'];
  filterDescription?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  isTaggable?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type FacetValueCreateManyGroupInputEnvelope = {
  data: Array<FacetValueCreateManyGroupInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FacetValueCreateNestedManyWithoutGroupInput = {
  connect?: InputMaybe<Array<FacetValueWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FacetValueCreateOrConnectWithoutGroupInput>>;
  create?: InputMaybe<Array<FacetValueCreateWithoutGroupInput>>;
  createMany?: InputMaybe<FacetValueCreateManyGroupInputEnvelope>;
};

export type FacetValueCreateNestedOneWithoutGroupedValuesInput = {
  connect?: InputMaybe<FacetValueWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FacetValueCreateOrConnectWithoutGroupedValuesInput>;
  create?: InputMaybe<FacetValueUncheckedCreateWithoutGroupedValuesInput>;
};

export type FacetValueCreateNestedOneWithoutOfferingAttributesInput = {
  connect?: InputMaybe<FacetValueWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FacetValueCreateOrConnectWithoutOfferingAttributesInput>;
  create?: InputMaybe<FacetValueUncheckedCreateWithoutOfferingAttributesInput>;
};

export type FacetValueCreateOrConnectWithoutFacetInput = {
  create: FacetValueUncheckedCreateWithoutFacetInput;
  where: FacetValueWhereUniqueInput;
};

export type FacetValueCreateOrConnectWithoutGroupInput = {
  create: FacetValueUncheckedCreateWithoutGroupInput;
  where: FacetValueWhereUniqueInput;
};

export type FacetValueCreateOrConnectWithoutGroupedValuesInput = {
  create: FacetValueUncheckedCreateWithoutGroupedValuesInput;
  where: FacetValueWhereUniqueInput;
};

export type FacetValueCreateOrConnectWithoutOfferingAttributesInput = {
  create: FacetValueUncheckedCreateWithoutOfferingAttributesInput;
  where: FacetValueWhereUniqueInput;
};

export type FacetValueCreateWithoutFacetInput = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  excludedIds?: InputMaybe<Scalars['JSON']>;
  filterDescription?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<FacetValueCreateNestedOneWithoutGroupedValuesInput>;
  groupedValues?: InputMaybe<FacetValueCreateNestedManyWithoutGroupInput>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  isTaggable?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  offeringAttributes?: InputMaybe<OfferingAttributeCreateNestedManyWithoutFacetValueInput>;
};

export type FacetValueCreateWithoutGroupInput = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  excludedIds?: InputMaybe<Scalars['JSON']>;
  facet: FacetCreateNestedOneWithoutFacetValuesInput;
  filterDescription?: InputMaybe<Scalars['String']>;
  groupedValues?: InputMaybe<FacetValueCreateNestedManyWithoutGroupInput>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  isTaggable?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  offeringAttributes?: InputMaybe<OfferingAttributeCreateNestedManyWithoutFacetValueInput>;
};

export type FacetValueFacetIdCodeGroupIdCompoundUniqueInput = {
  code: Scalars['String'];
  facetId: Scalars['Int'];
  groupId: Scalars['Int'];
};

export type FacetValueListRelationFilter = {
  every?: InputMaybe<FacetValueWhereInput>;
  none?: InputMaybe<FacetValueWhereInput>;
  some?: InputMaybe<FacetValueWhereInput>;
};

export type FacetValueRelationFilter = {
  is?: InputMaybe<FacetValueWhereInput>;
  isNot?: InputMaybe<FacetValueWhereInput>;
};

export type FacetValueScalarWhereInput = {
  AND?: InputMaybe<Array<FacetValueScalarWhereInput>>;
  NOT?: InputMaybe<Array<FacetValueScalarWhereInput>>;
  OR?: InputMaybe<Array<FacetValueScalarWhereInput>>;
  code?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  excludedIds?: InputMaybe<JsonFilter>;
  facetId?: InputMaybe<IntFilter>;
  filterDescription?: InputMaybe<StringNullableFilter>;
  groupId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isCustom?: InputMaybe<BoolFilter>;
  isTaggable?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
};

export type FacetValueUncheckedCreateNestedManyWithoutFacetInput = {
  connect?: InputMaybe<Array<FacetValueWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FacetValueCreateOrConnectWithoutFacetInput>>;
  create?: InputMaybe<Array<FacetValueCreateWithoutFacetInput>>;
  createMany?: InputMaybe<FacetValueCreateManyFacetInputEnvelope>;
};

export type FacetValueUncheckedCreateNestedManyWithoutGroupInput = {
  connect?: InputMaybe<Array<FacetValueWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FacetValueCreateOrConnectWithoutGroupInput>>;
  create?: InputMaybe<Array<FacetValueCreateWithoutGroupInput>>;
  createMany?: InputMaybe<FacetValueCreateManyGroupInputEnvelope>;
};

export type FacetValueUncheckedCreateWithoutFacetInput = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  excludedIds?: InputMaybe<Scalars['JSON']>;
  filterDescription?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['Int']>;
  groupedValues?: InputMaybe<FacetValueUncheckedCreateNestedManyWithoutGroupInput>;
  id?: InputMaybe<Scalars['ID']>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  isTaggable?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  offeringAttributes?: InputMaybe<OfferingAttributeUncheckedCreateNestedManyWithoutFacetValueInput>;
};

export type FacetValueUncheckedCreateWithoutGroupInput = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  excludedIds?: InputMaybe<Scalars['JSON']>;
  facetId: Scalars['Int'];
  filterDescription?: InputMaybe<Scalars['String']>;
  groupedValues?: InputMaybe<FacetValueUncheckedCreateNestedManyWithoutGroupInput>;
  id?: InputMaybe<Scalars['ID']>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  isTaggable?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  offeringAttributes?: InputMaybe<OfferingAttributeUncheckedCreateNestedManyWithoutFacetValueInput>;
};

export type FacetValueUncheckedCreateWithoutGroupedValuesInput = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  excludedIds?: InputMaybe<Scalars['JSON']>;
  facetId: Scalars['Int'];
  filterDescription?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  isTaggable?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  offeringAttributes?: InputMaybe<OfferingAttributeUncheckedCreateNestedManyWithoutFacetValueInput>;
};

export type FacetValueUncheckedCreateWithoutOfferingAttributesInput = {
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  excludedIds?: InputMaybe<Scalars['JSON']>;
  facetId: Scalars['Int'];
  filterDescription?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['Int']>;
  groupedValues?: InputMaybe<FacetValueUncheckedCreateNestedManyWithoutGroupInput>;
  id?: InputMaybe<Scalars['ID']>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  isTaggable?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type FacetValueUncheckedUpdateManyWithoutFacetNestedInput = {
  connect?: InputMaybe<Array<FacetValueWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FacetValueCreateOrConnectWithoutFacetInput>>;
  create?: InputMaybe<Array<FacetValueCreateWithoutFacetInput>>;
  createMany?: InputMaybe<FacetValueCreateManyFacetInputEnvelope>;
  delete?: InputMaybe<Array<FacetValueWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FacetValueScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FacetValueWhereUniqueInput>>;
  set?: InputMaybe<Array<FacetValueWhereUniqueInput>>;
  update?: InputMaybe<Array<FacetValueUpdateWithWhereUniqueWithoutFacetInput>>;
  updateMany?: InputMaybe<Array<FacetValueUpdateManyWithWhereWithoutFacetInput>>;
  upsert?: InputMaybe<Array<FacetValueUpsertWithWhereUniqueWithoutFacetInput>>;
};

export type FacetValueUncheckedUpdateManyWithoutFacetValuesInput = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  excludedIds?: InputMaybe<Scalars['JSON']>;
  filterDescription?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  isTaggable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FacetValueUncheckedUpdateManyWithoutGroupNestedInput = {
  connect?: InputMaybe<Array<FacetValueWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FacetValueCreateOrConnectWithoutGroupInput>>;
  create?: InputMaybe<Array<FacetValueCreateWithoutGroupInput>>;
  createMany?: InputMaybe<FacetValueCreateManyGroupInputEnvelope>;
  delete?: InputMaybe<Array<FacetValueWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FacetValueScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FacetValueWhereUniqueInput>>;
  set?: InputMaybe<Array<FacetValueWhereUniqueInput>>;
  update?: InputMaybe<Array<FacetValueUpdateWithWhereUniqueWithoutGroupInput>>;
  updateMany?: InputMaybe<Array<FacetValueUpdateManyWithWhereWithoutGroupInput>>;
  upsert?: InputMaybe<Array<FacetValueUpsertWithWhereUniqueWithoutGroupInput>>;
};

export type FacetValueUncheckedUpdateManyWithoutGroupedValuesInput = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  excludedIds?: InputMaybe<Scalars['JSON']>;
  facetId?: InputMaybe<Scalars['Int']>;
  filterDescription?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  isTaggable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FacetValueUncheckedUpdateWithoutFacetInput = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  excludedIds?: InputMaybe<Scalars['JSON']>;
  filterDescription?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['Int']>;
  groupedValues?: InputMaybe<FacetValueUncheckedUpdateManyWithoutGroupNestedInput>;
  id?: InputMaybe<Scalars['ID']>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  isTaggable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  offeringAttributes?: InputMaybe<OfferingAttributeUncheckedUpdateManyWithoutFacetValueNestedInput>;
};

export type FacetValueUncheckedUpdateWithoutGroupInput = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  excludedIds?: InputMaybe<Scalars['JSON']>;
  facetId?: InputMaybe<Scalars['Int']>;
  filterDescription?: InputMaybe<Scalars['String']>;
  groupedValues?: InputMaybe<FacetValueUncheckedUpdateManyWithoutGroupNestedInput>;
  id?: InputMaybe<Scalars['ID']>;
  isCustom?: InputMaybe<Scalars['Boolean']>;
  isTaggable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  offeringAttributes?: InputMaybe<OfferingAttributeUncheckedUpdateManyWithoutFacetValueNestedInput>;
};

export type FacetValueUpdateManyWithWhereWithoutFacetInput = {
  data: FacetValueUncheckedUpdateManyWithoutFacetValuesInput;
  where: FacetValueScalarWhereInput;
};

export type FacetValueUpdateManyWithWhereWithoutGroupInput = {
  data: FacetValueUncheckedUpdateManyWithoutGroupedValuesInput;
  where: FacetValueScalarWhereInput;
};

export type FacetValueUpdateWithWhereUniqueWithoutFacetInput = {
  data: FacetValueUncheckedUpdateWithoutFacetInput;
  where: FacetValueWhereUniqueInput;
};

export type FacetValueUpdateWithWhereUniqueWithoutGroupInput = {
  data: FacetValueUncheckedUpdateWithoutGroupInput;
  where: FacetValueWhereUniqueInput;
};

export type FacetValueUpsertWithWhereUniqueWithoutFacetInput = {
  create: FacetValueUncheckedCreateWithoutFacetInput;
  update: FacetValueUncheckedUpdateWithoutFacetInput;
  where: FacetValueWhereUniqueInput;
};

export type FacetValueUpsertWithWhereUniqueWithoutGroupInput = {
  create: FacetValueUncheckedCreateWithoutGroupInput;
  update: FacetValueUncheckedUpdateWithoutGroupInput;
  where: FacetValueWhereUniqueInput;
};

export type FacetValueWhereInput = {
  AND?: InputMaybe<Array<FacetValueWhereInput>>;
  NOT?: InputMaybe<Array<FacetValueWhereInput>>;
  OR?: InputMaybe<Array<FacetValueWhereInput>>;
  code?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  excludedIds?: InputMaybe<JsonFilter>;
  facet?: InputMaybe<FacetRelationFilter>;
  facetId?: InputMaybe<IntFilter>;
  filterDescription?: InputMaybe<StringNullableFilter>;
  group?: InputMaybe<FacetValueRelationFilter>;
  groupId?: InputMaybe<IntNullableFilter>;
  groupedValues?: InputMaybe<FacetValueListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  isCustom?: InputMaybe<BoolFilter>;
  isTaggable?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  offeringAttributes?: InputMaybe<OfferingAttributeListRelationFilter>;
};

export type FacetValueWhereUniqueInput = {
  facetId_code_groupId?: InputMaybe<FacetValueFacetIdCodeGroupIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['ID']>;
};

export type FacetWhereInput = {
  AND?: InputMaybe<Array<FacetWhereInput>>;
  NOT?: InputMaybe<Array<FacetWhereInput>>;
  OR?: InputMaybe<Array<FacetWhereInput>>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  facetValues?: InputMaybe<FacetValueListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  offeringAttributes?: InputMaybe<OfferingAttributeListRelationFilter>;
  shortName?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FacetWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type FeeSettingsInput = {
  /** Administration fee for manual refund request in percentage */
  administrationFeeForManualRefundRequestInPercentage?: InputMaybe<Scalars['Int']>;
  /** Service charge for early withdrawal of earnings in percentage */
  serviceChargeForEarlyWithdrawalOfEarningsInPercentage?: InputMaybe<Scalars['Int']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export enum FrequencyType {
  Biweekly = 'Biweekly',
  Daily = 'Daily',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type Fulfillment = {
  __typename?: 'Fulfillment';
  _count?: Maybe<FulfillmentCount>;
  createdAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  failedState?: Maybe<FulfillmentFailedState>;
  fulfillmentTasks: Array<FulfillmentTask>;
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  location: Scalars['JSON'];
  longitude: Scalars['Float'];
  metadata?: Maybe<Scalars['JSON']>;
  order: Order;
  orderId: Scalars['String'];
  serviceId: Scalars['Int'];
  startsAt: Scalars['DateTime'];
  status: FulfillmentStatus;
  updatedAt: Scalars['DateTime'];
};


export type FulfillmentFulfillmentTasksArgs = {
  cursor?: InputMaybe<FulfillmentTaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<FulfillmentTaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FulfillmentTaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FulfillmentTaskWhereInput>;
};

export type FulfillmentCount = {
  __typename?: 'FulfillmentCount';
  fulfillmentTasks: Scalars['Int'];
};

export enum FulfillmentFailedState {
  BothLateCheckIn = 'BothLateCheckIn',
  BothNoShow = 'BothNoShow',
  CustomerFailedToConfirm = 'CustomerFailedToConfirm',
  CustomerLateCheckIn = 'CustomerLateCheckIn',
  CustomerNoShow = 'CustomerNoShow',
  ServiceProviderLateCheckIn = 'ServiceProviderLateCheckIn',
  ServiceProviderNoShow = 'ServiceProviderNoShow'
}

export type FulfillmentListRelationFilter = {
  every?: InputMaybe<FulfillmentWhereInput>;
  none?: InputMaybe<FulfillmentWhereInput>;
  some?: InputMaybe<FulfillmentWhereInput>;
};

export type FulfillmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FulfillmentOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  endsAt?: InputMaybe<SortOrder>;
  failedState?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  location?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrderInput>;
  orderId?: InputMaybe<SortOrder>;
  serviceId?: InputMaybe<SortOrder>;
  startsAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FulfillmentRelationFilter = {
  is?: InputMaybe<FulfillmentWhereInput>;
  isNot?: InputMaybe<FulfillmentWhereInput>;
};

export enum FulfillmentScalarFieldEnum {
  CreatedAt = 'createdAt',
  EndsAt = 'endsAt',
  FailedState = 'failedState',
  Id = 'id',
  Latitude = 'latitude',
  Location = 'location',
  Longitude = 'longitude',
  Metadata = 'metadata',
  OrderId = 'orderId',
  ServiceId = 'serviceId',
  StartsAt = 'startsAt',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export enum FulfillmentStatus {
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  Pending = 'Pending',
  Succeeded = 'Succeeded',
  SucceededWithFailedTasks = 'SucceededWithFailedTasks'
}

export type FulfillmentTask = {
  __typename?: 'FulfillmentTask';
  checkedInAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  customerId?: Maybe<Scalars['String']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  failedState?: Maybe<FulfillmentTaskFailedState>;
  fulfillmentId: Scalars['String'];
  id: Scalars['ID'];
  orderId: Scalars['String'];
  serviceProviderId?: Maybe<Scalars['String']>;
  startsAt?: Maybe<Scalars['DateTime']>;
  status: FulfillmentTaskStatus;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FulfillmentTaskCreateManyFulfillmentInput = {
  checkedInAt?: InputMaybe<Scalars['DateTime']>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  failedState?: InputMaybe<FulfillmentTaskFailedState>;
  id?: InputMaybe<Scalars['ID']>;
  location: Scalars['JSON'];
  metadata?: InputMaybe<Scalars['JSON']>;
  orderId: Scalars['String'];
  startsAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<FulfillmentTaskStatus>;
  title: Scalars['String'];
};

export type FulfillmentTaskCreateManyFulfillmentInputEnvelope = {
  data: Array<FulfillmentTaskCreateManyFulfillmentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FulfillmentTaskCreateOrConnectWithoutFulfillmentInput = {
  create: FulfillmentTaskUncheckedCreateWithoutFulfillmentInput;
  where: FulfillmentTaskWhereUniqueInput;
};

export type FulfillmentTaskCreateWithoutFulfillmentInput = {
  checkedInAt?: InputMaybe<Scalars['DateTime']>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  failedState?: InputMaybe<FulfillmentTaskFailedState>;
  id?: InputMaybe<Scalars['ID']>;
  location: Scalars['JSON'];
  metadata?: InputMaybe<Scalars['JSON']>;
  order: OrderCreateNestedOneWithoutFulfillmentTasksInput;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<FulfillmentTaskStatus>;
  title: Scalars['String'];
};

export enum FulfillmentTaskFailedState {
  FailedToConfirm = 'FailedToConfirm',
  LateCheckIn = 'LateCheckIn',
  NoShow = 'NoShow'
}

export type FulfillmentTaskListRelationFilter = {
  every?: InputMaybe<FulfillmentTaskWhereInput>;
  none?: InputMaybe<FulfillmentTaskWhereInput>;
  some?: InputMaybe<FulfillmentTaskWhereInput>;
};

export type FulfillmentTaskOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FulfillmentTaskOrderByWithRelationInput = {
  checkedInAt?: InputMaybe<SortOrderInput>;
  completedAt?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  endsAt?: InputMaybe<SortOrderInput>;
  failedState?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrderInput>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  startsAt?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum FulfillmentTaskScalarFieldEnum {
  CheckedInAt = 'checkedInAt',
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  EndsAt = 'endsAt',
  FailedState = 'failedState',
  FulfillmentId = 'fulfillmentId',
  Id = 'id',
  Location = 'location',
  Metadata = 'metadata',
  OrderId = 'orderId',
  ServiceProviderId = 'serviceProviderId',
  StartsAt = 'startsAt',
  Status = 'status',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type FulfillmentTaskScalarWhereInput = {
  AND?: InputMaybe<Array<FulfillmentTaskScalarWhereInput>>;
  NOT?: InputMaybe<Array<FulfillmentTaskScalarWhereInput>>;
  OR?: InputMaybe<Array<FulfillmentTaskScalarWhereInput>>;
  checkedInAt?: InputMaybe<DateTimeNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  endsAt?: InputMaybe<DateTimeNullableFilter>;
  failedState?: InputMaybe<EnumFulfillmentTaskFailedStateNullableFilter>;
  fulfillmentId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<JsonFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  orderId?: InputMaybe<StringFilter>;
  serviceProviderId?: InputMaybe<StringNullableFilter>;
  startsAt?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<EnumFulfillmentTaskStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum FulfillmentTaskStatus {
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Pending = 'Pending'
}

export type FulfillmentTaskUncheckedCreateNestedManyWithoutFulfillmentInput = {
  connect?: InputMaybe<Array<FulfillmentTaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FulfillmentTaskCreateOrConnectWithoutFulfillmentInput>>;
  create?: InputMaybe<Array<FulfillmentTaskCreateWithoutFulfillmentInput>>;
  createMany?: InputMaybe<FulfillmentTaskCreateManyFulfillmentInputEnvelope>;
};

export type FulfillmentTaskUncheckedCreateWithoutFulfillmentInput = {
  checkedInAt?: InputMaybe<Scalars['DateTime']>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  failedState?: InputMaybe<FulfillmentTaskFailedState>;
  id?: InputMaybe<Scalars['ID']>;
  location: Scalars['JSON'];
  metadata?: InputMaybe<Scalars['JSON']>;
  orderId: Scalars['String'];
  startsAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<FulfillmentTaskStatus>;
  title: Scalars['String'];
};

export type FulfillmentTaskUncheckedUpdateManyWithoutFulfillmentNestedInput = {
  connect?: InputMaybe<Array<FulfillmentTaskWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FulfillmentTaskCreateOrConnectWithoutFulfillmentInput>>;
  create?: InputMaybe<Array<FulfillmentTaskCreateWithoutFulfillmentInput>>;
  createMany?: InputMaybe<FulfillmentTaskCreateManyFulfillmentInputEnvelope>;
  delete?: InputMaybe<Array<FulfillmentTaskWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FulfillmentTaskScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FulfillmentTaskWhereUniqueInput>>;
  set?: InputMaybe<Array<FulfillmentTaskWhereUniqueInput>>;
  update?: InputMaybe<Array<FulfillmentTaskUpdateWithWhereUniqueWithoutFulfillmentInput>>;
  updateMany?: InputMaybe<Array<FulfillmentTaskUpdateManyWithWhereWithoutFulfillmentInput>>;
  upsert?: InputMaybe<Array<FulfillmentTaskUpsertWithWhereUniqueWithoutFulfillmentInput>>;
};

export type FulfillmentTaskUncheckedUpdateManyWithoutFulfillmentTasksInput = {
  checkedInAt?: InputMaybe<Scalars['DateTime']>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  failedState?: InputMaybe<FulfillmentTaskFailedState>;
  id?: InputMaybe<Scalars['ID']>;
  location?: InputMaybe<Scalars['JSON']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  orderId?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<FulfillmentTaskStatus>;
  title?: InputMaybe<Scalars['String']>;
};

export type FulfillmentTaskUncheckedUpdateWithoutFulfillmentInput = {
  checkedInAt?: InputMaybe<Scalars['DateTime']>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  endsAt?: InputMaybe<Scalars['DateTime']>;
  failedState?: InputMaybe<FulfillmentTaskFailedState>;
  id?: InputMaybe<Scalars['ID']>;
  location?: InputMaybe<Scalars['JSON']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  orderId?: InputMaybe<Scalars['String']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<FulfillmentTaskStatus>;
  title?: InputMaybe<Scalars['String']>;
};

export type FulfillmentTaskUpdateManyWithWhereWithoutFulfillmentInput = {
  data: FulfillmentTaskUncheckedUpdateManyWithoutFulfillmentTasksInput;
  where: FulfillmentTaskScalarWhereInput;
};

export type FulfillmentTaskUpdateWithWhereUniqueWithoutFulfillmentInput = {
  data: FulfillmentTaskUncheckedUpdateWithoutFulfillmentInput;
  where: FulfillmentTaskWhereUniqueInput;
};

export type FulfillmentTaskUpsertWithWhereUniqueWithoutFulfillmentInput = {
  create: FulfillmentTaskUncheckedCreateWithoutFulfillmentInput;
  update: FulfillmentTaskUncheckedUpdateWithoutFulfillmentInput;
  where: FulfillmentTaskWhereUniqueInput;
};

export type FulfillmentTaskWhereInput = {
  AND?: InputMaybe<Array<FulfillmentTaskWhereInput>>;
  NOT?: InputMaybe<Array<FulfillmentTaskWhereInput>>;
  OR?: InputMaybe<Array<FulfillmentTaskWhereInput>>;
  checkedInAt?: InputMaybe<DateTimeNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  endsAt?: InputMaybe<DateTimeNullableFilter>;
  failedState?: InputMaybe<EnumFulfillmentTaskFailedStateNullableFilter>;
  fulfillment?: InputMaybe<FulfillmentRelationFilter>;
  fulfillmentId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<JsonFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringNullableFilter>;
  startsAt?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<EnumFulfillmentTaskStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FulfillmentTaskWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type FulfillmentUncheckedCreateInput = {
  endsAt: Scalars['DateTime'];
  failedState?: InputMaybe<FulfillmentFailedState>;
  fulfillmentTasks?: InputMaybe<FulfillmentTaskUncheckedCreateNestedManyWithoutFulfillmentInput>;
  id?: InputMaybe<Scalars['ID']>;
  latitude: Scalars['Float'];
  location: Scalars['JSON'];
  longitude: Scalars['Float'];
  metadata?: InputMaybe<Scalars['JSON']>;
  orderId: Scalars['String'];
  serviceId: Scalars['Int'];
  startsAt: Scalars['DateTime'];
  status?: InputMaybe<FulfillmentStatus>;
};

export type FulfillmentUncheckedUpdateInput = {
  endsAt?: InputMaybe<Scalars['DateTime']>;
  failedState?: InputMaybe<FulfillmentFailedState>;
  fulfillmentTasks?: InputMaybe<FulfillmentTaskUncheckedUpdateManyWithoutFulfillmentNestedInput>;
  id?: InputMaybe<Scalars['ID']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['JSON']>;
  longitude?: InputMaybe<Scalars['Float']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  orderId?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  startsAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<FulfillmentStatus>;
};

export type FulfillmentWhereInput = {
  AND?: InputMaybe<Array<FulfillmentWhereInput>>;
  NOT?: InputMaybe<Array<FulfillmentWhereInput>>;
  OR?: InputMaybe<Array<FulfillmentWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endsAt?: InputMaybe<DateTimeFilter>;
  failedState?: InputMaybe<EnumFulfillmentFailedStateNullableFilter>;
  fulfillmentTasks?: InputMaybe<FulfillmentTaskListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<FloatFilter>;
  location?: InputMaybe<JsonFilter>;
  longitude?: InputMaybe<FloatFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  serviceId?: InputMaybe<IntFilter>;
  startsAt?: InputMaybe<DateTimeFilter>;
  status?: InputMaybe<EnumFulfillmentStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FulfillmentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum Gender {
  Female = 'Female',
  Male = 'Male'
}

export type GeneratePresignedFileInput = {
  fileName: Scalars['String'];
  /** The purpose of the upload, such as: profile_picture, ... */
  purpose: Scalars['String'];
  referenceId?: InputMaybe<Scalars['String']>;
};

export type HelpCenter = {
  __typename?: 'HelpCenter';
  url: Scalars['String'];
};

export type HttpHeader = {
  __typename?: 'HttpHeader';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type HttpRequestParameter = {
  __typename?: 'HttpRequestParameter';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['Int']>>;
  has?: InputMaybe<Scalars['Int']>;
  hasEvery?: InputMaybe<Array<Scalars['Int']>>;
  hasSome?: InputMaybe<Array<Scalars['Int']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

/** Available locales */
export enum LocaleCode {
  En = 'EN',
  Ms = 'MS',
  ZhCn = 'ZH_CN',
  ZhTw = 'ZH_TW'
}

export type Location = {
  __typename?: 'Location';
  address: Scalars['String'];
  coordinates: Coordinates;
  formattedAddress: Scalars['String'];
  name: Scalars['String'];
  placeId?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type Metafield = {
  __typename?: 'Metafield';
  key: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Decimal'];
  currencyCode: Scalars['String'];
  formattedAmount: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminLogin: Auth;
  adminResetPassword: StatusResponse;
  approveBusinessApplication?: Maybe<BusinessApplication>;
  createManyCancellationReason: AffectedRowsOutput;
  createOneAdminRole: AdminRole;
  createOneAdminUser: AdminUser;
  createOneBank: Bank;
  createOneBlockedUser: BlockedUser;
  createOneBusiness: Business;
  createOneCancellationReason: CancellationReason;
  createOneCustomerPreference: CustomerPreference;
  createOneFacet: Facet;
  createOneFulfillment: Fulfillment;
  createOneOffering: Offering;
  createOneService: Service;
  createTopupPackage: TopupPackage;
  deleteManyCancellationReason: AffectedRowsOutput;
  deleteOneAdminRole: AdminRole;
  deleteOneAdminUser?: Maybe<AdminUser>;
  deleteOneBank?: Maybe<Bank>;
  deleteOneBlockedUser?: Maybe<BlockedUser>;
  deleteOneBusiness?: Maybe<Business>;
  deleteOneCancellationReason?: Maybe<CancellationReason>;
  deleteOneCustomerPreference?: Maybe<CustomerPreference>;
  deleteOneFacet?: Maybe<Facet>;
  deleteOneFulfillment?: Maybe<Fulfillment>;
  deleteOneOffering?: Maybe<Offering>;
  deleteOneService?: Maybe<Service>;
  deleteOneTopupPackage?: Maybe<TopupPackage>;
  generatePresignedFile: SignedFile;
  refreshToken: Auth;
  rejectBusinessApplication?: Maybe<BusinessApplication>;
  requestOtp: OtpChallenge;
  updateBookingSettings?: Maybe<Array<SystemSetting>>;
  updateFeeSettings?: Maybe<Array<SystemSetting>>;
  updateManyCancellationReason: AffectedRowsOutput;
  updateOneAdminRole?: Maybe<AdminRole>;
  updateOneAdminUser?: Maybe<AdminUser>;
  updateOneBank?: Maybe<Bank>;
  updateOneBlockedUser?: Maybe<BlockedUser>;
  updateOneBusiness?: Maybe<Business>;
  updateOneCancellationReason?: Maybe<CancellationReason>;
  updateOneCustomer?: Maybe<Customer>;
  updateOneCustomerPreference?: Maybe<CustomerPreference>;
  updateOneFacet?: Maybe<Facet>;
  updateOneFulfillment?: Maybe<Fulfillment>;
  updateOneOffering?: Maybe<Offering>;
  updateOneService?: Maybe<Service>;
  updateOneServiceProvider?: Maybe<ServiceProvider>;
  updateOneSystemSetting?: Maybe<SystemSetting>;
  updatePayout: Payout;
  updateTopupPackage: TopupPackage;
  updateWithdrawal: Withdrawal;
  upsertOneCancellationReason: CancellationReason;
  verifyOtp: StatusResponse;
};


export type MutationAdminLoginArgs = {
  data: AdminLoginInput;
};


export type MutationAdminResetPasswordArgs = {
  data: ResetPasswordInput;
};


export type MutationApproveBusinessApplicationArgs = {
  id: Scalars['String'];
};


export type MutationCreateManyCancellationReasonArgs = {
  data: Array<CancellationReasonCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateOneAdminRoleArgs = {
  data: AdminRoleCreateInputV2;
};


export type MutationCreateOneAdminUserArgs = {
  data: AdminUserCreateInputV2;
};


export type MutationCreateOneBankArgs = {
  data: BankUncheckedCreateInput;
};


export type MutationCreateOneBlockedUserArgs = {
  data: BlockedUserUncheckedCreateInput;
};


export type MutationCreateOneBusinessArgs = {
  data: BusinessUncheckedCreateInput;
};


export type MutationCreateOneCancellationReasonArgs = {
  data: CancellationReasonUncheckedCreateInput;
};


export type MutationCreateOneCustomerPreferenceArgs = {
  data: CustomerPreferenceUncheckedCreateInput;
};


export type MutationCreateOneFacetArgs = {
  data: FacetUncheckedCreateInput;
};


export type MutationCreateOneFulfillmentArgs = {
  data: FulfillmentUncheckedCreateInput;
};


export type MutationCreateOneOfferingArgs = {
  data: OfferingUncheckedCreateInput;
};


export type MutationCreateOneServiceArgs = {
  data: ServiceUncheckedCreateInput;
};


export type MutationCreateTopupPackageArgs = {
  data: TopupPackageCreateInputV2;
};


export type MutationDeleteManyCancellationReasonArgs = {
  where?: InputMaybe<CancellationReasonWhereInput>;
};


export type MutationDeleteOneAdminRoleArgs = {
  where: AdminRoleWhereUniqueInput;
};


export type MutationDeleteOneAdminUserArgs = {
  where: AdminUserWhereUniqueInput;
};


export type MutationDeleteOneBankArgs = {
  where: BankWhereUniqueInput;
};


export type MutationDeleteOneBlockedUserArgs = {
  where: BlockedUserWhereUniqueInput;
};


export type MutationDeleteOneBusinessArgs = {
  where: BusinessWhereUniqueInput;
};


export type MutationDeleteOneCancellationReasonArgs = {
  where: CancellationReasonWhereUniqueInput;
};


export type MutationDeleteOneCustomerPreferenceArgs = {
  where: CustomerPreferenceWhereUniqueInput;
};


export type MutationDeleteOneFacetArgs = {
  where: FacetWhereUniqueInput;
};


export type MutationDeleteOneFulfillmentArgs = {
  where: FulfillmentWhereUniqueInput;
};


export type MutationDeleteOneOfferingArgs = {
  where: OfferingWhereUniqueInput;
};


export type MutationDeleteOneServiceArgs = {
  where: ServiceWhereUniqueInput;
};


export type MutationDeleteOneTopupPackageArgs = {
  where: TopupPackageWhereUniqueInput;
};


export type MutationGeneratePresignedFileArgs = {
  data: GeneratePresignedFileInput;
};


export type MutationRefreshTokenArgs = {
  token: Scalars['String'];
};


export type MutationRejectBusinessApplicationArgs = {
  data: AdminRejectBusinessApplicationInput;
  where: BusinessApplicationWhereUniqueInput;
};


export type MutationRequestOtpArgs = {
  data: RequestOtpInput;
};


export type MutationUpdateBookingSettingsArgs = {
  data: BookingSettingsInput;
};


export type MutationUpdateFeeSettingsArgs = {
  data: FeeSettingsInput;
};


export type MutationUpdateManyCancellationReasonArgs = {
  data: CancellationReasonUncheckedUpdateManyInput;
  where?: InputMaybe<CancellationReasonWhereInput>;
};


export type MutationUpdateOneAdminRoleArgs = {
  data: AdminRoleUpdateInputV2;
  where: AdminRoleWhereUniqueInput;
};


export type MutationUpdateOneAdminUserArgs = {
  data: AdminUserUpdateInputV2;
  where: AdminUserWhereUniqueInput;
};


export type MutationUpdateOneBankArgs = {
  data: BankUncheckedUpdateInput;
  where: BankWhereUniqueInput;
};


export type MutationUpdateOneBlockedUserArgs = {
  data: BlockedUserUncheckedUpdateInput;
  where: BlockedUserWhereUniqueInput;
};


export type MutationUpdateOneBusinessArgs = {
  data: BusinessUncheckedUpdateInput;
  where: BusinessWhereUniqueInput;
};


export type MutationUpdateOneCancellationReasonArgs = {
  data: CancellationReasonUncheckedUpdateInput;
  where: CancellationReasonWhereUniqueInput;
};


export type MutationUpdateOneCustomerArgs = {
  data: CustomerUncheckedUpdateInput;
  where: CustomerWhereUniqueInput;
};


export type MutationUpdateOneCustomerPreferenceArgs = {
  data: CustomerPreferenceUncheckedUpdateInput;
  where: CustomerPreferenceWhereUniqueInput;
};


export type MutationUpdateOneFacetArgs = {
  data: FacetUncheckedUpdateInput;
  where: FacetWhereUniqueInput;
};


export type MutationUpdateOneFulfillmentArgs = {
  data: FulfillmentUncheckedUpdateInput;
  where: FulfillmentWhereUniqueInput;
};


export type MutationUpdateOneOfferingArgs = {
  data: OfferingUncheckedUpdateInput;
  where: OfferingWhereUniqueInput;
};


export type MutationUpdateOneServiceArgs = {
  data: ServiceUncheckedUpdateInput;
  where: ServiceWhereUniqueInput;
};


export type MutationUpdateOneServiceProviderArgs = {
  data: ServiceProviderUncheckedUpdateInput;
  where: ServiceProviderWhereUniqueInput;
};


export type MutationUpdateOneSystemSettingArgs = {
  data: SystemSettingUncheckedUpdateInput;
  where: SystemSettingWhereUniqueInput;
};


export type MutationUpdatePayoutArgs = {
  data: PayoutUpdateInputV2;
  where: PayoutWhereUniqueInput;
};


export type MutationUpdateTopupPackageArgs = {
  data: TopupPackageUpdateInputV2;
  where: TopupPackageWhereUniqueInput;
};


export type MutationUpdateWithdrawalArgs = {
  data: WithdrawalUpdateInputV2;
  where: WithdrawalWhereUniqueInput;
};


export type MutationUpsertOneCancellationReasonArgs = {
  create: CancellationReasonUncheckedCreateInput;
  update: CancellationReasonUncheckedUpdateInput;
  where: CancellationReasonWhereUniqueInput;
};


export type MutationVerifyOtpArgs = {
  data: VerifyOtpInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']>;
  gt?: InputMaybe<Scalars['Decimal']>;
  gte?: InputMaybe<Scalars['Decimal']>;
  in?: InputMaybe<Array<Scalars['Decimal']>>;
  lt?: InputMaybe<Scalars['Decimal']>;
  lte?: InputMaybe<Scalars['Decimal']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']>>;
};

export type NestedEnumAvailabilityTypeFilter = {
  equals?: InputMaybe<AvailabilityType>;
  in?: InputMaybe<Array<AvailabilityType>>;
  not?: InputMaybe<NestedEnumAvailabilityTypeFilter>;
  notIn?: InputMaybe<Array<AvailabilityType>>;
};

export type NestedEnumBalanceTypeFilter = {
  equals?: InputMaybe<BalanceType>;
  in?: InputMaybe<Array<BalanceType>>;
  not?: InputMaybe<NestedEnumBalanceTypeFilter>;
  notIn?: InputMaybe<Array<BalanceType>>;
};

export type NestedEnumBusinessApplicationStatusFilter = {
  equals?: InputMaybe<BusinessApplicationStatus>;
  in?: InputMaybe<Array<BusinessApplicationStatus>>;
  not?: InputMaybe<NestedEnumBusinessApplicationStatusFilter>;
  notIn?: InputMaybe<Array<BusinessApplicationStatus>>;
};

export type NestedEnumBusinessStatusFilter = {
  equals?: InputMaybe<BusinessStatus>;
  in?: InputMaybe<Array<BusinessStatus>>;
  not?: InputMaybe<NestedEnumBusinessStatusFilter>;
  notIn?: InputMaybe<Array<BusinessStatus>>;
};

export type NestedEnumColorRatingFilter = {
  equals?: InputMaybe<ColorRating>;
  in?: InputMaybe<Array<ColorRating>>;
  not?: InputMaybe<NestedEnumColorRatingFilter>;
  notIn?: InputMaybe<Array<ColorRating>>;
};

export type NestedEnumCountryCodeFilter = {
  equals?: InputMaybe<CountryCode>;
  in?: InputMaybe<Array<CountryCode>>;
  not?: InputMaybe<NestedEnumCountryCodeFilter>;
  notIn?: InputMaybe<Array<CountryCode>>;
};

export type NestedEnumCountryCodeNullableFilter = {
  equals?: InputMaybe<CountryCode>;
  in?: InputMaybe<Array<CountryCode>>;
  not?: InputMaybe<NestedEnumCountryCodeNullableFilter>;
  notIn?: InputMaybe<Array<CountryCode>>;
};

export type NestedEnumCustomerStatusFilter = {
  equals?: InputMaybe<CustomerStatus>;
  in?: InputMaybe<Array<CustomerStatus>>;
  not?: InputMaybe<NestedEnumCustomerStatusFilter>;
  notIn?: InputMaybe<Array<CustomerStatus>>;
};

export type NestedEnumFacetFilterGroupTypeFilter = {
  equals?: InputMaybe<FacetFilterGroupType>;
  in?: InputMaybe<Array<FacetFilterGroupType>>;
  not?: InputMaybe<NestedEnumFacetFilterGroupTypeFilter>;
  notIn?: InputMaybe<Array<FacetFilterGroupType>>;
};

export type NestedEnumFrequencyTypeNullableFilter = {
  equals?: InputMaybe<FrequencyType>;
  in?: InputMaybe<Array<FrequencyType>>;
  not?: InputMaybe<NestedEnumFrequencyTypeNullableFilter>;
  notIn?: InputMaybe<Array<FrequencyType>>;
};

export type NestedEnumFulfillmentFailedStateNullableFilter = {
  equals?: InputMaybe<FulfillmentFailedState>;
  in?: InputMaybe<Array<FulfillmentFailedState>>;
  not?: InputMaybe<NestedEnumFulfillmentFailedStateNullableFilter>;
  notIn?: InputMaybe<Array<FulfillmentFailedState>>;
};

export type NestedEnumFulfillmentStatusFilter = {
  equals?: InputMaybe<FulfillmentStatus>;
  in?: InputMaybe<Array<FulfillmentStatus>>;
  not?: InputMaybe<NestedEnumFulfillmentStatusFilter>;
  notIn?: InputMaybe<Array<FulfillmentStatus>>;
};

export type NestedEnumFulfillmentTaskFailedStateNullableFilter = {
  equals?: InputMaybe<FulfillmentTaskFailedState>;
  in?: InputMaybe<Array<FulfillmentTaskFailedState>>;
  not?: InputMaybe<NestedEnumFulfillmentTaskFailedStateNullableFilter>;
  notIn?: InputMaybe<Array<FulfillmentTaskFailedState>>;
};

export type NestedEnumFulfillmentTaskStatusFilter = {
  equals?: InputMaybe<FulfillmentTaskStatus>;
  in?: InputMaybe<Array<FulfillmentTaskStatus>>;
  not?: InputMaybe<NestedEnumFulfillmentTaskStatusFilter>;
  notIn?: InputMaybe<Array<FulfillmentTaskStatus>>;
};

export type NestedEnumGenderNullableFilter = {
  equals?: InputMaybe<Gender>;
  in?: InputMaybe<Array<Gender>>;
  not?: InputMaybe<NestedEnumGenderNullableFilter>;
  notIn?: InputMaybe<Array<Gender>>;
};

export type NestedEnumNotificationTypeFilter = {
  equals?: InputMaybe<NotificationType>;
  in?: InputMaybe<Array<NotificationType>>;
  not?: InputMaybe<NestedEnumNotificationTypeFilter>;
  notIn?: InputMaybe<Array<NotificationType>>;
};

export type NestedEnumOfferingStatusFilter = {
  equals?: InputMaybe<OfferingStatus>;
  in?: InputMaybe<Array<OfferingStatus>>;
  not?: InputMaybe<NestedEnumOfferingStatusFilter>;
  notIn?: InputMaybe<Array<OfferingStatus>>;
};

export type NestedEnumOrderActorFilter = {
  equals?: InputMaybe<OrderActor>;
  in?: InputMaybe<Array<OrderActor>>;
  not?: InputMaybe<NestedEnumOrderActorFilter>;
  notIn?: InputMaybe<Array<OrderActor>>;
};

export type NestedEnumOrderActorNullableFilter = {
  equals?: InputMaybe<OrderActor>;
  in?: InputMaybe<Array<OrderActor>>;
  not?: InputMaybe<NestedEnumOrderActorNullableFilter>;
  notIn?: InputMaybe<Array<OrderActor>>;
};

export type NestedEnumOrderActorWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumOrderActorFilter>;
  _min?: InputMaybe<NestedEnumOrderActorFilter>;
  equals?: InputMaybe<OrderActor>;
  in?: InputMaybe<Array<OrderActor>>;
  not?: InputMaybe<NestedEnumOrderActorWithAggregatesFilter>;
  notIn?: InputMaybe<Array<OrderActor>>;
};

export type NestedEnumOrderCancelResolutionTypeNullableFilter = {
  equals?: InputMaybe<OrderCancelResolutionType>;
  in?: InputMaybe<Array<OrderCancelResolutionType>>;
  not?: InputMaybe<NestedEnumOrderCancelResolutionTypeNullableFilter>;
  notIn?: InputMaybe<Array<OrderCancelResolutionType>>;
};

export type NestedEnumOrderRefundReviewStatusNullableFilter = {
  equals?: InputMaybe<OrderRefundReviewStatus>;
  in?: InputMaybe<Array<OrderRefundReviewStatus>>;
  not?: InputMaybe<NestedEnumOrderRefundReviewStatusNullableFilter>;
  notIn?: InputMaybe<Array<OrderRefundReviewStatus>>;
};

export type NestedEnumOrderRefundStatusNullableFilter = {
  equals?: InputMaybe<OrderRefundStatus>;
  in?: InputMaybe<Array<OrderRefundStatus>>;
  not?: InputMaybe<NestedEnumOrderRefundStatusNullableFilter>;
  notIn?: InputMaybe<Array<OrderRefundStatus>>;
};

export type NestedEnumOrderStateFilter = {
  equals?: InputMaybe<OrderState>;
  in?: InputMaybe<Array<OrderState>>;
  not?: InputMaybe<NestedEnumOrderStateFilter>;
  notIn?: InputMaybe<Array<OrderState>>;
};

export type NestedEnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type NestedEnumPaymentMethodFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type NestedEnumPaymentProcessorFilter = {
  equals?: InputMaybe<PaymentProcessor>;
  in?: InputMaybe<Array<PaymentProcessor>>;
  not?: InputMaybe<NestedEnumPaymentProcessorFilter>;
  notIn?: InputMaybe<Array<PaymentProcessor>>;
};

export type NestedEnumPaymentStatusFilter = {
  equals?: InputMaybe<PaymentStatus>;
  in?: InputMaybe<Array<PaymentStatus>>;
  not?: InputMaybe<NestedEnumPaymentStatusFilter>;
  notIn?: InputMaybe<Array<PaymentStatus>>;
};

export type NestedEnumPayoutStatusFilter = {
  equals?: InputMaybe<PayoutStatus>;
  in?: InputMaybe<Array<PayoutStatus>>;
  not?: InputMaybe<NestedEnumPayoutStatusFilter>;
  notIn?: InputMaybe<Array<PayoutStatus>>;
};

export type NestedEnumPeriodTypeFilter = {
  equals?: InputMaybe<PeriodType>;
  in?: InputMaybe<Array<PeriodType>>;
  not?: InputMaybe<NestedEnumPeriodTypeFilter>;
  notIn?: InputMaybe<Array<PeriodType>>;
};

export type NestedEnumReviewTypeFilter = {
  equals?: InputMaybe<ReviewType>;
  in?: InputMaybe<Array<ReviewType>>;
  not?: InputMaybe<NestedEnumReviewTypeFilter>;
  notIn?: InputMaybe<Array<ReviewType>>;
};

export type NestedEnumServiceProviderStatusFilter = {
  equals?: InputMaybe<ServiceProviderStatus>;
  in?: InputMaybe<Array<ServiceProviderStatus>>;
  not?: InputMaybe<NestedEnumServiceProviderStatusFilter>;
  notIn?: InputMaybe<Array<ServiceProviderStatus>>;
};

export type NestedEnumServiceProviderVerificationStatusFilter = {
  equals?: InputMaybe<ServiceProviderVerificationStatus>;
  in?: InputMaybe<Array<ServiceProviderVerificationStatus>>;
  not?: InputMaybe<NestedEnumServiceProviderVerificationStatusFilter>;
  notIn?: InputMaybe<Array<ServiceProviderVerificationStatus>>;
};

export type NestedEnumServiceTypeFilter = {
  equals?: InputMaybe<ServiceType>;
  in?: InputMaybe<Array<ServiceType>>;
  not?: InputMaybe<NestedEnumServiceTypeFilter>;
  notIn?: InputMaybe<Array<ServiceType>>;
};

export type NestedEnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type NestedEnumStatusWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumStatusFilter>;
  _min?: InputMaybe<NestedEnumStatusFilter>;
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type NestedEnumSystemSettingGroupFilter = {
  equals?: InputMaybe<SystemSettingGroup>;
  in?: InputMaybe<Array<SystemSettingGroup>>;
  not?: InputMaybe<NestedEnumSystemSettingGroupFilter>;
  notIn?: InputMaybe<Array<SystemSettingGroup>>;
};

export type NestedEnumTopupMethodFilter = {
  equals?: InputMaybe<TopupMethod>;
  in?: InputMaybe<Array<TopupMethod>>;
  not?: InputMaybe<NestedEnumTopupMethodFilter>;
  notIn?: InputMaybe<Array<TopupMethod>>;
};

export type NestedEnumTopupStatusFilter = {
  equals?: InputMaybe<TopupStatus>;
  in?: InputMaybe<Array<TopupStatus>>;
  not?: InputMaybe<NestedEnumTopupStatusFilter>;
  notIn?: InputMaybe<Array<TopupStatus>>;
};

export type NestedEnumTransactionStatusFilter = {
  equals?: InputMaybe<TransactionStatus>;
  in?: InputMaybe<Array<TransactionStatus>>;
  not?: InputMaybe<NestedEnumTransactionStatusFilter>;
  notIn?: InputMaybe<Array<TransactionStatus>>;
};

export type NestedEnumTransactionTypeFilter = {
  equals?: InputMaybe<TransactionType>;
  in?: InputMaybe<Array<TransactionType>>;
  not?: InputMaybe<NestedEnumTransactionTypeFilter>;
  notIn?: InputMaybe<Array<TransactionType>>;
};

export type NestedEnumUserNotificationIconTypeFilter = {
  equals?: InputMaybe<UserNotificationIconType>;
  in?: InputMaybe<Array<UserNotificationIconType>>;
  not?: InputMaybe<NestedEnumUserNotificationIconTypeFilter>;
  notIn?: InputMaybe<Array<UserNotificationIconType>>;
};

export type NestedEnumUserStatusFilter = {
  equals?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  not?: InputMaybe<NestedEnumUserStatusFilter>;
  notIn?: InputMaybe<Array<UserStatus>>;
};

export type NestedEnumUserTypeFilter = {
  equals?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  not?: InputMaybe<NestedEnumUserTypeFilter>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type NestedEnumWithdrawalStatusFilter = {
  equals?: InputMaybe<WithdrawalStatus>;
  in?: InputMaybe<Array<WithdrawalStatus>>;
  not?: InputMaybe<NestedEnumWithdrawalStatusFilter>;
  notIn?: InputMaybe<Array<WithdrawalStatus>>;
};

export type NestedEnumWithdrawalTypeFilter = {
  equals?: InputMaybe<WithdrawalType>;
  in?: InputMaybe<Array<WithdrawalType>>;
  not?: InputMaybe<NestedEnumWithdrawalTypeFilter>;
  notIn?: InputMaybe<Array<WithdrawalType>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export enum NotificationType {
  CheckInReminder = 'CheckInReminder',
  Message = 'Message',
  Session = 'Session',
  WalletTransaction = 'WalletTransaction'
}

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type Offering = {
  __typename?: 'Offering';
  _count?: Maybe<OfferingCount>;
  amount: Money;
  amountSummary: OfferingAmountSummary;
  availabilities: Array<Availability>;
  availabilityCount?: Maybe<Scalars['Int']>;
  countryCode: CountryCode;
  /** Display name of the offering in my services */
  coverName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  currencyCode: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  distance?: Maybe<Scalars['String']>;
  facets?: Maybe<Array<OfferingFacet>>;
  id: Scalars['ID'];
  isLocationFixed: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Float']>;
  location?: Maybe<Location>;
  locationRemark?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  mainFacet: FacetValue;
  ordersCount: Scalars['Int'];
  outcomes?: Maybe<Array<Scalars['String']>>;
  price: Money;
  publishedAt?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Decimal']>;
  ratingCount: Scalars['Int'];
  reviews: Array<Review>;
  service: Service;
  serviceId: Scalars['Int'];
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars['String'];
  serviceProviderUnavailablePeriods: Array<ServiceProviderUnavailablePeriod>;
  status: OfferingStatus;
  taggedFacets: Array<FacetValue>;
  termsAndConditions?: Maybe<Array<Scalars['String']>>;
  updatedAt: Scalars['DateTime'];
  workspace?: Maybe<OfferingWorkspace>;
};


export type OfferingAvailabilitiesArgs = {
  cursor?: InputMaybe<AvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<AvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AvailabilityWhereInput>;
};


export type OfferingDistanceArgs = {
  coordinates?: InputMaybe<CoordinatesInput>;
};


export type OfferingReviewsArgs = {
  cursor?: InputMaybe<ReviewWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReviewScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReviewOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReviewWhereInput>;
};


export type OfferingServiceProviderUnavailablePeriodsArgs = {
  cursor?: InputMaybe<ServiceProviderUnavailablePeriodWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceProviderUnavailablePeriodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceProviderUnavailablePeriodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceProviderUnavailablePeriodWhereInput>;
};

export type OfferingAmountSummary = {
  __typename?: 'OfferingAmountSummary';
  discountAmount?: Maybe<Money>;
  subTotalAmount: Money;
  subTotalBreakdown: OrderSubTotalBreakdown;
  totalAmount: Money;
};

export type OfferingAttributeCreateManyFacetInput = {
  facetValueId: Scalars['Int'];
  isMain?: InputMaybe<Scalars['Boolean']>;
  offeringId: Scalars['String'];
};

export type OfferingAttributeCreateManyFacetInputEnvelope = {
  data: Array<OfferingAttributeCreateManyFacetInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OfferingAttributeCreateManyFacetValueInput = {
  facetId: Scalars['Int'];
  isMain?: InputMaybe<Scalars['Boolean']>;
  offeringId: Scalars['String'];
};

export type OfferingAttributeCreateManyFacetValueInputEnvelope = {
  data: Array<OfferingAttributeCreateManyFacetValueInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OfferingAttributeCreateNestedManyWithoutFacetValueInput = {
  connect?: InputMaybe<Array<OfferingAttributeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OfferingAttributeCreateOrConnectWithoutFacetValueInput>>;
  create?: InputMaybe<Array<OfferingAttributeCreateWithoutFacetValueInput>>;
  createMany?: InputMaybe<OfferingAttributeCreateManyFacetValueInputEnvelope>;
};

export type OfferingAttributeCreateOrConnectWithoutFacetInput = {
  create: OfferingAttributeUncheckedCreateWithoutFacetInput;
  where: OfferingAttributeWhereUniqueInput;
};

export type OfferingAttributeCreateOrConnectWithoutFacetValueInput = {
  create: OfferingAttributeUncheckedCreateWithoutFacetValueInput;
  where: OfferingAttributeWhereUniqueInput;
};

export type OfferingAttributeCreateWithoutFacetInput = {
  facetValue: FacetValueCreateNestedOneWithoutOfferingAttributesInput;
  isMain?: InputMaybe<Scalars['Boolean']>;
  offering: OfferingCreateNestedOneWithoutAttributesInput;
};

export type OfferingAttributeCreateWithoutFacetValueInput = {
  facet: FacetCreateNestedOneWithoutOfferingAttributesInput;
  isMain?: InputMaybe<Scalars['Boolean']>;
  offering: OfferingCreateNestedOneWithoutAttributesInput;
};

export type OfferingAttributeListRelationFilter = {
  every?: InputMaybe<OfferingAttributeWhereInput>;
  none?: InputMaybe<OfferingAttributeWhereInput>;
  some?: InputMaybe<OfferingAttributeWhereInput>;
};

export type OfferingAttributeOfferingIdFacetValueIdCompoundUniqueInput = {
  facetValueId: Scalars['Int'];
  offeringId: Scalars['String'];
};

export type OfferingAttributeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OfferingAttributeScalarWhereInput = {
  AND?: InputMaybe<Array<OfferingAttributeScalarWhereInput>>;
  NOT?: InputMaybe<Array<OfferingAttributeScalarWhereInput>>;
  OR?: InputMaybe<Array<OfferingAttributeScalarWhereInput>>;
  facetId?: InputMaybe<IntFilter>;
  facetValueId?: InputMaybe<IntFilter>;
  isMain?: InputMaybe<BoolFilter>;
  offeringId?: InputMaybe<StringFilter>;
};

export type OfferingAttributeUncheckedCreateNestedManyWithoutFacetInput = {
  connect?: InputMaybe<Array<OfferingAttributeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OfferingAttributeCreateOrConnectWithoutFacetInput>>;
  create?: InputMaybe<Array<OfferingAttributeCreateWithoutFacetInput>>;
  createMany?: InputMaybe<OfferingAttributeCreateManyFacetInputEnvelope>;
};

export type OfferingAttributeUncheckedCreateNestedManyWithoutFacetValueInput = {
  connect?: InputMaybe<Array<OfferingAttributeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OfferingAttributeCreateOrConnectWithoutFacetValueInput>>;
  create?: InputMaybe<Array<OfferingAttributeCreateWithoutFacetValueInput>>;
  createMany?: InputMaybe<OfferingAttributeCreateManyFacetValueInputEnvelope>;
};

export type OfferingAttributeUncheckedCreateWithoutFacetInput = {
  facetValueId: Scalars['Int'];
  isMain?: InputMaybe<Scalars['Boolean']>;
  offeringId: Scalars['String'];
};

export type OfferingAttributeUncheckedCreateWithoutFacetValueInput = {
  facetId: Scalars['Int'];
  isMain?: InputMaybe<Scalars['Boolean']>;
  offeringId: Scalars['String'];
};

export type OfferingAttributeUncheckedUpdateManyWithoutFacetNestedInput = {
  connect?: InputMaybe<Array<OfferingAttributeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OfferingAttributeCreateOrConnectWithoutFacetInput>>;
  create?: InputMaybe<Array<OfferingAttributeCreateWithoutFacetInput>>;
  createMany?: InputMaybe<OfferingAttributeCreateManyFacetInputEnvelope>;
  delete?: InputMaybe<Array<OfferingAttributeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OfferingAttributeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OfferingAttributeWhereUniqueInput>>;
  set?: InputMaybe<Array<OfferingAttributeWhereUniqueInput>>;
  update?: InputMaybe<Array<OfferingAttributeUpdateWithWhereUniqueWithoutFacetInput>>;
  updateMany?: InputMaybe<Array<OfferingAttributeUpdateManyWithWhereWithoutFacetInput>>;
  upsert?: InputMaybe<Array<OfferingAttributeUpsertWithWhereUniqueWithoutFacetInput>>;
};

export type OfferingAttributeUncheckedUpdateManyWithoutFacetValueNestedInput = {
  connect?: InputMaybe<Array<OfferingAttributeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OfferingAttributeCreateOrConnectWithoutFacetValueInput>>;
  create?: InputMaybe<Array<OfferingAttributeCreateWithoutFacetValueInput>>;
  createMany?: InputMaybe<OfferingAttributeCreateManyFacetValueInputEnvelope>;
  delete?: InputMaybe<Array<OfferingAttributeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OfferingAttributeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OfferingAttributeWhereUniqueInput>>;
  set?: InputMaybe<Array<OfferingAttributeWhereUniqueInput>>;
  update?: InputMaybe<Array<OfferingAttributeUpdateWithWhereUniqueWithoutFacetValueInput>>;
  updateMany?: InputMaybe<Array<OfferingAttributeUpdateManyWithWhereWithoutFacetValueInput>>;
  upsert?: InputMaybe<Array<OfferingAttributeUpsertWithWhereUniqueWithoutFacetValueInput>>;
};

export type OfferingAttributeUncheckedUpdateManyWithoutOfferingAttributesInput = {
  facetValueId?: InputMaybe<Scalars['Int']>;
  isMain?: InputMaybe<Scalars['Boolean']>;
  offeringId?: InputMaybe<Scalars['String']>;
};

export type OfferingAttributeUncheckedUpdateWithoutFacetInput = {
  facetValueId?: InputMaybe<Scalars['Int']>;
  isMain?: InputMaybe<Scalars['Boolean']>;
  offeringId?: InputMaybe<Scalars['String']>;
};

export type OfferingAttributeUncheckedUpdateWithoutFacetValueInput = {
  facetId?: InputMaybe<Scalars['Int']>;
  isMain?: InputMaybe<Scalars['Boolean']>;
  offeringId?: InputMaybe<Scalars['String']>;
};

export type OfferingAttributeUpdateManyWithWhereWithoutFacetInput = {
  data: OfferingAttributeUncheckedUpdateManyWithoutOfferingAttributesInput;
  where: OfferingAttributeScalarWhereInput;
};

export type OfferingAttributeUpdateManyWithWhereWithoutFacetValueInput = {
  data: OfferingAttributeUncheckedUpdateManyWithoutOfferingAttributesInput;
  where: OfferingAttributeScalarWhereInput;
};

export type OfferingAttributeUpdateWithWhereUniqueWithoutFacetInput = {
  data: OfferingAttributeUncheckedUpdateWithoutFacetInput;
  where: OfferingAttributeWhereUniqueInput;
};

export type OfferingAttributeUpdateWithWhereUniqueWithoutFacetValueInput = {
  data: OfferingAttributeUncheckedUpdateWithoutFacetValueInput;
  where: OfferingAttributeWhereUniqueInput;
};

export type OfferingAttributeUpsertWithWhereUniqueWithoutFacetInput = {
  create: OfferingAttributeUncheckedCreateWithoutFacetInput;
  update: OfferingAttributeUncheckedUpdateWithoutFacetInput;
  where: OfferingAttributeWhereUniqueInput;
};

export type OfferingAttributeUpsertWithWhereUniqueWithoutFacetValueInput = {
  create: OfferingAttributeUncheckedCreateWithoutFacetValueInput;
  update: OfferingAttributeUncheckedUpdateWithoutFacetValueInput;
  where: OfferingAttributeWhereUniqueInput;
};

export type OfferingAttributeWhereInput = {
  AND?: InputMaybe<Array<OfferingAttributeWhereInput>>;
  NOT?: InputMaybe<Array<OfferingAttributeWhereInput>>;
  OR?: InputMaybe<Array<OfferingAttributeWhereInput>>;
  facet?: InputMaybe<FacetRelationFilter>;
  facetId?: InputMaybe<IntFilter>;
  facetValue?: InputMaybe<FacetValueRelationFilter>;
  facetValueId?: InputMaybe<IntFilter>;
  isMain?: InputMaybe<BoolFilter>;
  offering?: InputMaybe<OfferingRelationFilter>;
  offeringId?: InputMaybe<StringFilter>;
};

export type OfferingAttributeWhereUniqueInput = {
  offeringId_facetValueId?: InputMaybe<OfferingAttributeOfferingIdFacetValueIdCompoundUniqueInput>;
};

export type OfferingColorRating = {
  __typename?: 'OfferingColorRating';
  colorRating: ColorRating;
  name: Scalars['String'];
};

export type OfferingCount = {
  __typename?: 'OfferingCount';
  attributes: Scalars['Int'];
  availabilities: Scalars['Int'];
  businessApplications: Scalars['Int'];
  orders: Scalars['Int'];
  reviews: Scalars['Int'];
  serviceProviderUnavailablePeriods: Scalars['Int'];
};

export type OfferingCreateManyBusinessInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  availabilityCount?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isLocationFixed?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['JSON']>;
  locationRemark?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  ordersCount?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  serviceId: Scalars['Int'];
  status?: InputMaybe<OfferingStatus>;
  termsAndConditions?: InputMaybe<Scalars['JSON']>;
  workspace?: InputMaybe<Scalars['JSON']>;
};

export type OfferingCreateManyBusinessInputEnvelope = {
  data: Array<OfferingCreateManyBusinessInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OfferingCreateManyServiceInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  availabilityCount?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isLocationFixed?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['JSON']>;
  locationRemark?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  ordersCount?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<OfferingStatus>;
  termsAndConditions?: InputMaybe<Scalars['JSON']>;
  workspace?: InputMaybe<Scalars['JSON']>;
};

export type OfferingCreateManyServiceInputEnvelope = {
  data: Array<OfferingCreateManyServiceInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OfferingCreateNestedManyWithoutBusinessInput = {
  connect?: InputMaybe<Array<OfferingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OfferingCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<OfferingCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<OfferingCreateManyBusinessInputEnvelope>;
};

export type OfferingCreateNestedOneWithoutAttributesInput = {
  connect?: InputMaybe<OfferingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OfferingCreateOrConnectWithoutAttributesInput>;
  create?: InputMaybe<OfferingUncheckedCreateWithoutAttributesInput>;
};

export type OfferingCreateOrConnectWithoutAttributesInput = {
  create: OfferingUncheckedCreateWithoutAttributesInput;
  where: OfferingWhereUniqueInput;
};

export type OfferingCreateOrConnectWithoutBusinessInput = {
  create: OfferingUncheckedCreateWithoutBusinessInput;
  where: OfferingWhereUniqueInput;
};

export type OfferingCreateOrConnectWithoutServiceInput = {
  create: OfferingUncheckedCreateWithoutServiceInput;
  where: OfferingWhereUniqueInput;
};

export type OfferingCreateWithoutBusinessInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  availabilityCount?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isLocationFixed?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['JSON']>;
  locationRemark?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutOfferingInput>;
  ordersCount?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  reviews?: InputMaybe<ReviewCreateNestedManyWithoutOfferingInput>;
  service: ServiceCreateNestedOneWithoutOfferingsInput;
  status?: InputMaybe<OfferingStatus>;
  termsAndConditions?: InputMaybe<Scalars['JSON']>;
  workspace?: InputMaybe<Scalars['JSON']>;
};

export type OfferingCreateWithoutServiceInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  availabilityCount?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isLocationFixed?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['JSON']>;
  locationRemark?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutOfferingInput>;
  ordersCount?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  reviews?: InputMaybe<ReviewCreateNestedManyWithoutOfferingInput>;
  status?: InputMaybe<OfferingStatus>;
  termsAndConditions?: InputMaybe<Scalars['JSON']>;
  workspace?: InputMaybe<Scalars['JSON']>;
};

export type OfferingDistance = {
  __typename?: 'OfferingDistance';
  maxDistance?: Maybe<Scalars['Int']>;
  minDistance?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
};

export type OfferingDistanceFilter = {
  __typename?: 'OfferingDistanceFilter';
  name: Scalars['String'];
  values: Array<OfferingDistance>;
};

export type OfferingFacet = {
  __typename?: 'OfferingFacet';
  name: Scalars['String'];
  values: Array<OfferingFacetValue>;
};

export type OfferingFacetFilter = {
  __typename?: 'OfferingFacetFilter';
  facets: Array<OfferingFacet>;
  groupName: Scalars['String'];
};

export type OfferingFacetValue = {
  __typename?: 'OfferingFacetValue';
  description?: Maybe<Scalars['String']>;
  /** Facet value ID */
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type OfferingGeneralFilter = {
  __typename?: 'OfferingGeneralFilter';
  colorRating: Array<OfferingColorRating>;
  priceRange: OfferingPriceRange;
  timeAvailability: Array<OfferingTimeAvailability>;
};

export type OfferingListRelationFilter = {
  every?: InputMaybe<OfferingWhereInput>;
  none?: InputMaybe<OfferingWhereInput>;
  some?: InputMaybe<OfferingWhereInput>;
};

export type OfferingOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OfferingOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  attributes?: InputMaybe<OfferingAttributeOrderByRelationAggregateInput>;
  availabilityCount?: InputMaybe<SortOrderInput>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isLocationFixed?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrderInput>;
  location?: InputMaybe<SortOrderInput>;
  locationRemark?: InputMaybe<SortOrderInput>;
  longitude?: InputMaybe<SortOrderInput>;
  metadata?: InputMaybe<SortOrderInput>;
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  ordersCount?: InputMaybe<SortOrder>;
  publishedAt?: InputMaybe<SortOrderInput>;
  rating?: InputMaybe<SortOrderInput>;
  ratingCount?: InputMaybe<SortOrder>;
  reviews?: InputMaybe<ReviewOrderByRelationAggregateInput>;
  service?: InputMaybe<ServiceOrderByWithRelationInput>;
  serviceId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  termsAndConditions?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  workspace?: InputMaybe<SortOrderInput>;
};

export type OfferingPageItem = {
  __typename?: 'OfferingPageItem';
  offerings: Array<Offering>;
  title: Scalars['String'];
};

export type OfferingPriceRange = {
  __typename?: 'OfferingPriceRange';
  maxPrice: Scalars['Int'];
  minPrice: Scalars['Int'];
};

export type OfferingRelationFilter = {
  is?: InputMaybe<OfferingWhereInput>;
  isNot?: InputMaybe<OfferingWhereInput>;
};

export enum OfferingScalarFieldEnum {
  Amount = 'amount',
  AvailabilityCount = 'availabilityCount',
  BusinessId = 'businessId',
  CountryCode = 'countryCode',
  CreatedAt = 'createdAt',
  CurrencyCode = 'currencyCode',
  Description = 'description',
  Id = 'id',
  IsLocationFixed = 'isLocationFixed',
  Latitude = 'latitude',
  Location = 'location',
  LocationRemark = 'locationRemark',
  Longitude = 'longitude',
  Metadata = 'metadata',
  OrdersCount = 'ordersCount',
  PublishedAt = 'publishedAt',
  Rating = 'rating',
  RatingCount = 'ratingCount',
  ServiceId = 'serviceId',
  ServiceProviderId = 'serviceProviderId',
  Status = 'status',
  TermsAndConditions = 'termsAndConditions',
  UpdatedAt = 'updatedAt',
  Workspace = 'workspace'
}

export type OfferingScalarWhereInput = {
  AND?: InputMaybe<Array<OfferingScalarWhereInput>>;
  NOT?: InputMaybe<Array<OfferingScalarWhereInput>>;
  OR?: InputMaybe<Array<OfferingScalarWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  availabilityCount?: InputMaybe<IntNullableFilter>;
  businessId?: InputMaybe<StringFilter>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isLocationFixed?: InputMaybe<BoolFilter>;
  latitude?: InputMaybe<FloatNullableFilter>;
  location?: InputMaybe<JsonNullableFilter>;
  locationRemark?: InputMaybe<StringNullableFilter>;
  longitude?: InputMaybe<FloatNullableFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  ordersCount?: InputMaybe<IntFilter>;
  publishedAt?: InputMaybe<DateTimeNullableFilter>;
  rating?: InputMaybe<DecimalNullableFilter>;
  ratingCount?: InputMaybe<IntFilter>;
  serviceId?: InputMaybe<IntFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumOfferingStatusFilter>;
  termsAndConditions?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workspace?: InputMaybe<JsonNullableFilter>;
};

export enum OfferingStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending',
  RequiresAction = 'RequiresAction'
}

export type OfferingTimeAvailability = {
  __typename?: 'OfferingTimeAvailability';
  fromTime: Scalars['Time'];
  name: Scalars['String'];
  toTime: Scalars['Time'];
};

export type OfferingUncheckedCreateInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  availabilityCount?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isLocationFixed?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['JSON']>;
  locationRemark?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  orders?: InputMaybe<OrderUncheckedCreateNestedManyWithoutOfferingInput>;
  ordersCount?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  reviews?: InputMaybe<ReviewUncheckedCreateNestedManyWithoutOfferingInput>;
  serviceId: Scalars['Int'];
  status?: InputMaybe<OfferingStatus>;
  termsAndConditions?: InputMaybe<Scalars['JSON']>;
  workspace?: InputMaybe<Scalars['JSON']>;
};

export type OfferingUncheckedCreateNestedManyWithoutBusinessInput = {
  connect?: InputMaybe<Array<OfferingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OfferingCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<OfferingCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<OfferingCreateManyBusinessInputEnvelope>;
};

export type OfferingUncheckedCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<OfferingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OfferingCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<OfferingCreateWithoutServiceInput>>;
  createMany?: InputMaybe<OfferingCreateManyServiceInputEnvelope>;
};

export type OfferingUncheckedCreateWithoutAttributesInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  availabilityCount?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isLocationFixed?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['JSON']>;
  locationRemark?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  orders?: InputMaybe<OrderUncheckedCreateNestedManyWithoutOfferingInput>;
  ordersCount?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  reviews?: InputMaybe<ReviewUncheckedCreateNestedManyWithoutOfferingInput>;
  serviceId: Scalars['Int'];
  status?: InputMaybe<OfferingStatus>;
  termsAndConditions?: InputMaybe<Scalars['JSON']>;
  workspace?: InputMaybe<Scalars['JSON']>;
};

export type OfferingUncheckedCreateWithoutBusinessInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  availabilityCount?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isLocationFixed?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['JSON']>;
  locationRemark?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  orders?: InputMaybe<OrderUncheckedCreateNestedManyWithoutOfferingInput>;
  ordersCount?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  reviews?: InputMaybe<ReviewUncheckedCreateNestedManyWithoutOfferingInput>;
  serviceId: Scalars['Int'];
  status?: InputMaybe<OfferingStatus>;
  termsAndConditions?: InputMaybe<Scalars['JSON']>;
  workspace?: InputMaybe<Scalars['JSON']>;
};

export type OfferingUncheckedCreateWithoutServiceInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  availabilityCount?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isLocationFixed?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['JSON']>;
  locationRemark?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  orders?: InputMaybe<OrderUncheckedCreateNestedManyWithoutOfferingInput>;
  ordersCount?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  reviews?: InputMaybe<ReviewUncheckedCreateNestedManyWithoutOfferingInput>;
  status?: InputMaybe<OfferingStatus>;
  termsAndConditions?: InputMaybe<Scalars['JSON']>;
  workspace?: InputMaybe<Scalars['JSON']>;
};

export type OfferingUncheckedUpdateInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  availabilityCount?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isLocationFixed?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['JSON']>;
  locationRemark?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  orders?: InputMaybe<OrderUncheckedUpdateManyWithoutOfferingNestedInput>;
  ordersCount?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  reviews?: InputMaybe<ReviewUncheckedUpdateManyWithoutOfferingNestedInput>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<OfferingStatus>;
  termsAndConditions?: InputMaybe<Scalars['JSON']>;
  workspace?: InputMaybe<Scalars['JSON']>;
};

export type OfferingUncheckedUpdateManyWithoutBusinessNestedInput = {
  connect?: InputMaybe<Array<OfferingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OfferingCreateOrConnectWithoutBusinessInput>>;
  create?: InputMaybe<Array<OfferingCreateWithoutBusinessInput>>;
  createMany?: InputMaybe<OfferingCreateManyBusinessInputEnvelope>;
  delete?: InputMaybe<Array<OfferingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OfferingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OfferingWhereUniqueInput>>;
  set?: InputMaybe<Array<OfferingWhereUniqueInput>>;
  update?: InputMaybe<Array<OfferingUpdateWithWhereUniqueWithoutBusinessInput>>;
  updateMany?: InputMaybe<Array<OfferingUpdateManyWithWhereWithoutBusinessInput>>;
  upsert?: InputMaybe<Array<OfferingUpsertWithWhereUniqueWithoutBusinessInput>>;
};

export type OfferingUncheckedUpdateManyWithoutOfferingsInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  availabilityCount?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isLocationFixed?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['JSON']>;
  locationRemark?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  ordersCount?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<OfferingStatus>;
  termsAndConditions?: InputMaybe<Scalars['JSON']>;
  workspace?: InputMaybe<Scalars['JSON']>;
};

export type OfferingUncheckedUpdateManyWithoutServiceNestedInput = {
  connect?: InputMaybe<Array<OfferingWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OfferingCreateOrConnectWithoutServiceInput>>;
  create?: InputMaybe<Array<OfferingCreateWithoutServiceInput>>;
  createMany?: InputMaybe<OfferingCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<OfferingWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OfferingScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OfferingWhereUniqueInput>>;
  set?: InputMaybe<Array<OfferingWhereUniqueInput>>;
  update?: InputMaybe<Array<OfferingUpdateWithWhereUniqueWithoutServiceInput>>;
  updateMany?: InputMaybe<Array<OfferingUpdateManyWithWhereWithoutServiceInput>>;
  upsert?: InputMaybe<Array<OfferingUpsertWithWhereUniqueWithoutServiceInput>>;
};

export type OfferingUncheckedUpdateWithoutBusinessInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  availabilityCount?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isLocationFixed?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['JSON']>;
  locationRemark?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  orders?: InputMaybe<OrderUncheckedUpdateManyWithoutOfferingNestedInput>;
  ordersCount?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  reviews?: InputMaybe<ReviewUncheckedUpdateManyWithoutOfferingNestedInput>;
  serviceId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<OfferingStatus>;
  termsAndConditions?: InputMaybe<Scalars['JSON']>;
  workspace?: InputMaybe<Scalars['JSON']>;
};

export type OfferingUncheckedUpdateWithoutServiceInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  availabilityCount?: InputMaybe<Scalars['Int']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isLocationFixed?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['JSON']>;
  locationRemark?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  orders?: InputMaybe<OrderUncheckedUpdateManyWithoutOfferingNestedInput>;
  ordersCount?: InputMaybe<Scalars['Int']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  ratingCount?: InputMaybe<Scalars['Int']>;
  reviews?: InputMaybe<ReviewUncheckedUpdateManyWithoutOfferingNestedInput>;
  status?: InputMaybe<OfferingStatus>;
  termsAndConditions?: InputMaybe<Scalars['JSON']>;
  workspace?: InputMaybe<Scalars['JSON']>;
};

export type OfferingUpdateManyWithWhereWithoutBusinessInput = {
  data: OfferingUncheckedUpdateManyWithoutOfferingsInput;
  where: OfferingScalarWhereInput;
};

export type OfferingUpdateManyWithWhereWithoutServiceInput = {
  data: OfferingUncheckedUpdateManyWithoutOfferingsInput;
  where: OfferingScalarWhereInput;
};

export type OfferingUpdateWithWhereUniqueWithoutBusinessInput = {
  data: OfferingUncheckedUpdateWithoutBusinessInput;
  where: OfferingWhereUniqueInput;
};

export type OfferingUpdateWithWhereUniqueWithoutServiceInput = {
  data: OfferingUncheckedUpdateWithoutServiceInput;
  where: OfferingWhereUniqueInput;
};

export type OfferingUpsertWithWhereUniqueWithoutBusinessInput = {
  create: OfferingUncheckedCreateWithoutBusinessInput;
  update: OfferingUncheckedUpdateWithoutBusinessInput;
  where: OfferingWhereUniqueInput;
};

export type OfferingUpsertWithWhereUniqueWithoutServiceInput = {
  create: OfferingUncheckedCreateWithoutServiceInput;
  update: OfferingUncheckedUpdateWithoutServiceInput;
  where: OfferingWhereUniqueInput;
};

export type OfferingWhereInput = {
  AND?: InputMaybe<Array<OfferingWhereInput>>;
  NOT?: InputMaybe<Array<OfferingWhereInput>>;
  OR?: InputMaybe<Array<OfferingWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  attributes?: InputMaybe<OfferingAttributeListRelationFilter>;
  availabilities?: InputMaybe<AvailabilityListRelationFilter>;
  availabilityCount?: InputMaybe<IntNullableFilter>;
  business?: InputMaybe<BusinessRelationFilter>;
  businessApplications?: InputMaybe<BusinessApplicationListRelationFilter>;
  businessId?: InputMaybe<StringFilter>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isLocationFixed?: InputMaybe<BoolFilter>;
  latitude?: InputMaybe<FloatNullableFilter>;
  location?: InputMaybe<JsonNullableFilter>;
  locationRemark?: InputMaybe<StringNullableFilter>;
  longitude?: InputMaybe<FloatNullableFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  ordersCount?: InputMaybe<IntFilter>;
  publishedAt?: InputMaybe<DateTimeNullableFilter>;
  rating?: InputMaybe<DecimalNullableFilter>;
  ratingCount?: InputMaybe<IntFilter>;
  reviews?: InputMaybe<ReviewListRelationFilter>;
  service?: InputMaybe<ServiceRelationFilter>;
  serviceId?: InputMaybe<IntFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  serviceProviderUnavailablePeriods?: InputMaybe<ServiceProviderUnavailablePeriodListRelationFilter>;
  status?: InputMaybe<EnumOfferingStatusFilter>;
  termsAndConditions?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workspace?: InputMaybe<JsonNullableFilter>;
};

export type OfferingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type OfferingWorkspace = {
  __typename?: 'OfferingWorkspace';
  /** Workspace images urls */
  attachments: Array<Scalars['String']>;
  capacity: WorkspaceCapacity;
  /** Amenities & Facilities */
  facilities?: Maybe<Array<Scalars['String']>>;
  workspaceName: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  _count?: Maybe<OrderCount>;
  actor?: Maybe<OrderActor>;
  autoAcceptNotifiedAt?: Maybe<Scalars['DateTime']>;
  availabilities: Array<Availability>;
  cancelComments?: Maybe<Scalars['String']>;
  cancelMetadata?: Maybe<OrderCancelMetadata>;
  cancelReason?: Maybe<Scalars['String']>;
  cancelResolutionType?: Maybe<OrderCancelResolutionType>;
  cancelledAt?: Maybe<Scalars['DateTime']>;
  cancelledBy?: Maybe<OrderActor>;
  completedAt?: Maybe<Scalars['DateTime']>;
  confirmedAt?: Maybe<Scalars['DateTime']>;
  countryCode?: Maybe<CountryCode>;
  couponCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['String'];
  customerNotes?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  declinableDurationInHours?: Maybe<Scalars['Int']>;
  discountAmount?: Maybe<Money>;
  events: Array<OrderEvent>;
  extraMessage?: Maybe<Scalars['String']>;
  failureCode?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
  fulfillmentFailedState?: Maybe<FulfillmentFailedState>;
  fulfillmentStatus: FulfillmentStatus;
  fulfillmentTasks: Array<FulfillmentTask>;
  fulfillments: Array<Fulfillment>;
  hasPendingAction: Scalars['Boolean'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isCancelable: Scalars['Boolean'];
  isCheckInable: Scalars['Boolean'];
  isCheckedIn: Scalars['Boolean'];
  isCompleted: Scalars['Boolean'];
  isRateable: Scalars['Boolean'];
  isRefund: Scalars['Boolean'];
  isReschedulable: Scalars['Boolean'];
  location: Location;
  notRefundedFees?: Maybe<OrderNotRefundedFees>;
  offering: Offering;
  offeringId: Scalars['String'];
  orderNumber?: Maybe<Scalars['String']>;
  paymentMethod: PaymentMethod;
  paymentStatus: PaymentStatus;
  payments: Array<Payment>;
  refundAmount?: Maybe<Money>;
  refundEligibility: OrderRefundEligibility;
  refundStatus?: Maybe<OrderRefundStatus>;
  rescheduleMetadata?: Maybe<OrderRescheduleMetadata>;
  reviews: Array<Review>;
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars['String'];
  sessionInfo: OrderSessionInfo;
  state: OrderState;
  status: OrderStatus;
  statusDescription: Scalars['String'];
  statusDisplay: Scalars['String'];
  subTotalAmount: Money;
  subTotalBreakdown: OrderSubTotalBreakdown;
  totalAmount: Money;
  totalEarningAmount: Money;
  transitionActions?: Maybe<Array<OrderTransitionAction>>;
  updatedAt: Scalars['DateTime'];
};


export type OrderEventsArgs = {
  cursor?: InputMaybe<OrderEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderEventWhereInput>;
};


export type OrderExtraMessageArgs = {
  role: Role;
};


export type OrderFulfillmentTasksArgs = {
  cursor?: InputMaybe<FulfillmentTaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<FulfillmentTaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FulfillmentTaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FulfillmentTaskWhereInput>;
};


export type OrderFulfillmentsArgs = {
  cursor?: InputMaybe<FulfillmentWhereUniqueInput>;
  distinct?: InputMaybe<Array<FulfillmentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FulfillmentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FulfillmentWhereInput>;
};


export type OrderPaymentsArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWhereInput>;
};


export type OrderReviewsArgs = {
  cursor?: InputMaybe<ReviewWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReviewScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReviewOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReviewWhereInput>;
};

export enum OrderActor {
  Customer = 'Customer',
  Operator = 'Operator',
  ServiceProvider = 'ServiceProvider'
}

export type OrderAmountSummary = {
  __typename?: 'OrderAmountSummary';
  discountAmount?: Maybe<Money>;
  subTotalAmount: Money;
  subTotalBreakdown: OrderSubTotalBreakdown;
  totalAmount: Money;
};

export type OrderBookingRemarks = {
  __typename?: 'OrderBookingRemarks';
  message?: Maybe<Scalars['String']>;
  onBehalfOfOthers: Scalars['Boolean'];
};

export type OrderCancelMetadata = {
  __typename?: 'OrderCancelMetadata';
  /** File or image urls */
  attachments?: Maybe<Array<Scalars['String']>>;
  cancelStatus: OrderCancelStatus;
  cancelType: OrderCancelType;
  isAutoApproved?: Maybe<Scalars['Boolean']>;
  refundMode?: Maybe<OrderRefundModeType>;
};

export enum OrderCancelResolutionType {
  Refund = 'Refund',
  Reschedule = 'Reschedule'
}

export enum OrderCancelStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export enum OrderCancelType {
  Cancelled = 'Cancelled',
  Declined = 'Declined'
}

export type OrderCount = {
  __typename?: 'OrderCount';
  colorRatingHistories: Scalars['Int'];
  events: Scalars['Int'];
  fulfillmentTasks: Scalars['Int'];
  fulfillments: Scalars['Int'];
  payments: Scalars['Int'];
  reviews: Scalars['Int'];
  serviceProviderUnavailablePeriods: Scalars['Int'];
};

export type OrderCreateManyOfferingInput = {
  autoAcceptNotifiedAt?: InputMaybe<Scalars['DateTime']>;
  cancelComments?: InputMaybe<Scalars['String']>;
  cancelReason?: InputMaybe<Scalars['String']>;
  cancelResolutionType?: InputMaybe<OrderCancelResolutionType>;
  cancelledBy?: InputMaybe<OrderActor>;
  countryCode?: InputMaybe<CountryCode>;
  couponCode?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
  customerNotes?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isRefund?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  notRefundedFees?: InputMaybe<Scalars['JSON']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  refundAmount?: InputMaybe<Scalars['Decimal']>;
  refundStatus?: InputMaybe<OrderRefundStatus>;
};

export type OrderCreateManyOfferingInputEnvelope = {
  data: Array<OrderCreateManyOfferingInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OrderCreateNestedManyWithoutOfferingInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutOfferingInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutOfferingInput>>;
  createMany?: InputMaybe<OrderCreateManyOfferingInputEnvelope>;
};

export type OrderCreateNestedOneWithoutFulfillmentTasksInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutFulfillmentTasksInput>;
  create?: InputMaybe<OrderUncheckedCreateWithoutFulfillmentTasksInput>;
};

export type OrderCreateNestedOneWithoutReviewsInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutReviewsInput>;
  create?: InputMaybe<OrderUncheckedCreateWithoutReviewsInput>;
};

export type OrderCreateOrConnectWithoutFulfillmentTasksInput = {
  create: OrderUncheckedCreateWithoutFulfillmentTasksInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutOfferingInput = {
  create: OrderUncheckedCreateWithoutOfferingInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutReviewsInput = {
  create: OrderUncheckedCreateWithoutReviewsInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateWithoutOfferingInput = {
  autoAcceptNotifiedAt?: InputMaybe<Scalars['DateTime']>;
  cancelComments?: InputMaybe<Scalars['String']>;
  cancelReason?: InputMaybe<Scalars['String']>;
  cancelResolutionType?: InputMaybe<OrderCancelResolutionType>;
  cancelledBy?: InputMaybe<OrderActor>;
  countryCode?: InputMaybe<CountryCode>;
  couponCode?: InputMaybe<Scalars['String']>;
  customer: CustomerCreateNestedOneWithoutOrdersInput;
  customerNotes?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isRefund?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  notRefundedFees?: InputMaybe<Scalars['JSON']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  refundAmount?: InputMaybe<Scalars['Decimal']>;
  refundStatus?: InputMaybe<OrderRefundStatus>;
  serviceProvider: ServiceProviderCreateNestedOneWithoutOrdersInput;
};

export type OrderEvent = {
  __typename?: 'OrderEvent';
  createdAt: Scalars['DateTime'];
  eventType: Scalars['String'];
  id: Scalars['ID'];
  order: Order;
  orderId: Scalars['String'];
  payload: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OrderEventOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  eventType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrder>;
  payload?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum OrderEventScalarFieldEnum {
  CreatedAt = 'createdAt',
  EventType = 'eventType',
  Id = 'id',
  OrderId = 'orderId',
  Payload = 'payload',
  UpdatedAt = 'updatedAt'
}

export type OrderEventWhereInput = {
  AND?: InputMaybe<Array<OrderEventWhereInput>>;
  NOT?: InputMaybe<Array<OrderEventWhereInput>>;
  OR?: InputMaybe<Array<OrderEventWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventType?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  payload?: InputMaybe<JsonFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderEventWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type OrderListRelationFilter = {
  every?: InputMaybe<OrderWhereInput>;
  none?: InputMaybe<OrderWhereInput>;
  some?: InputMaybe<OrderWhereInput>;
};

export type OrderNotRefundedFees = {
  __typename?: 'OrderNotRefundedFees';
  adminFeeAmount?: Maybe<Money>;
  penaltyFeeAmount?: Maybe<Money>;
};

export type OrderOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderOrderByWithRelationInput = {
  autoAcceptNotifiedAt?: InputMaybe<SortOrderInput>;
  cancelComments?: InputMaybe<SortOrderInput>;
  cancelMetadata?: InputMaybe<SortOrderInput>;
  cancelReason?: InputMaybe<SortOrderInput>;
  cancelResolutionType?: InputMaybe<SortOrderInput>;
  cancelledAt?: InputMaybe<SortOrderInput>;
  cancelledBy?: InputMaybe<SortOrderInput>;
  completedAt?: InputMaybe<SortOrderInput>;
  confirmedAt?: InputMaybe<SortOrderInput>;
  countryCode?: InputMaybe<SortOrderInput>;
  couponCode?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  customerNotes?: InputMaybe<SortOrderInput>;
  date?: InputMaybe<SortOrderInput>;
  discountAmount?: InputMaybe<SortOrderInput>;
  earningCurrencyCode?: InputMaybe<SortOrderInput>;
  failureCode?: InputMaybe<SortOrderInput>;
  failureMessage?: InputMaybe<SortOrderInput>;
  fulfillmentFailedState?: InputMaybe<SortOrderInput>;
  fulfillmentStatus?: InputMaybe<SortOrder>;
  fulfillmentTasks?: InputMaybe<FulfillmentTaskOrderByRelationAggregateInput>;
  fulfillments?: InputMaybe<FulfillmentOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  isRefund?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrderInput>;
  notRefundedFees?: InputMaybe<SortOrderInput>;
  offeringId?: InputMaybe<SortOrder>;
  orderNumber?: InputMaybe<SortOrderInput>;
  paymentMethod?: InputMaybe<SortOrder>;
  paymentStatus?: InputMaybe<SortOrder>;
  payments?: InputMaybe<PaymentOrderByRelationAggregateInput>;
  rawPricingMetadata?: InputMaybe<SortOrderInput>;
  refundAmount?: InputMaybe<SortOrderInput>;
  refundRemarks?: InputMaybe<SortOrderInput>;
  refundReviewStatus?: InputMaybe<SortOrderInput>;
  refundStatus?: InputMaybe<SortOrderInput>;
  rescheduledFromId?: InputMaybe<SortOrderInput>;
  rescheduledMetadata?: InputMaybe<SortOrderInput>;
  serviceProvider?: InputMaybe<ServiceProviderOrderByWithRelationInput>;
  serviceProviderId?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  subTotalAmount?: InputMaybe<SortOrder>;
  subTotalBreakdown?: InputMaybe<SortOrderInput>;
  totalAmount?: InputMaybe<SortOrder>;
  totalEarningAmount?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type OrderRefundEligibility = {
  __typename?: 'OrderRefundEligibility';
  refundEligibilityType: OrderRefundEligibilityType;
  /** Refundable hours if cancel */
  refundableWithinHours?: Maybe<Scalars['Int']>;
};

export enum OrderRefundEligibilityType {
  FullRefundWithoutReason = 'FullRefundWithoutReason',
  RefundWithEvidence = 'RefundWithEvidence',
  RefundWithReason = 'RefundWithReason'
}

export enum OrderRefundModeType {
  Instant = 'Instant',
  Normal = 'Normal'
}

export enum OrderRefundReviewStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export enum OrderRefundStatus {
  FullyRefunded = 'FullyRefunded',
  NotApplicable = 'NotApplicable',
  NotEligible = 'NotEligible',
  PartiallyRefunded = 'PartiallyRefunded',
  Pending = 'Pending'
}

export type OrderRelationFilter = {
  is?: InputMaybe<OrderWhereInput>;
  isNot?: InputMaybe<OrderWhereInput>;
};

export type OrderRescheduleMetadata = {
  __typename?: 'OrderRescheduleMetadata';
  /** Availability IDs */
  availabilityIds: Array<Scalars['String']>;
  /** Date of the session */
  date: Scalars['DateTime'];
  formattedDate: Scalars['String'];
  formattedSessionSlots: Array<Scalars['String']>;
  /** New rescheduled order id */
  orderId?: Maybe<Scalars['String']>;
};

export enum OrderScalarFieldEnum {
  AutoAcceptNotifiedAt = 'autoAcceptNotifiedAt',
  CancelComments = 'cancelComments',
  CancelMetadata = 'cancelMetadata',
  CancelReason = 'cancelReason',
  CancelResolutionType = 'cancelResolutionType',
  CancelledAt = 'cancelledAt',
  CancelledBy = 'cancelledBy',
  CompletedAt = 'completedAt',
  ConfirmedAt = 'confirmedAt',
  CountryCode = 'countryCode',
  CouponCode = 'couponCode',
  CreatedAt = 'createdAt',
  CurrencyCode = 'currencyCode',
  CustomerId = 'customerId',
  CustomerNotes = 'customerNotes',
  Date = 'date',
  DiscountAmount = 'discountAmount',
  EarningCurrencyCode = 'earningCurrencyCode',
  FailureCode = 'failureCode',
  FailureMessage = 'failureMessage',
  FulfillmentFailedState = 'fulfillmentFailedState',
  FulfillmentStatus = 'fulfillmentStatus',
  Id = 'id',
  IsActive = 'isActive',
  IsRefund = 'isRefund',
  Metadata = 'metadata',
  NotRefundedFees = 'notRefundedFees',
  OfferingId = 'offeringId',
  OrderNumber = 'orderNumber',
  PaymentMethod = 'paymentMethod',
  PaymentStatus = 'paymentStatus',
  RawPricingMetadata = 'rawPricingMetadata',
  RefundAmount = 'refundAmount',
  RefundRemarks = 'refundRemarks',
  RefundReviewStatus = 'refundReviewStatus',
  RefundStatus = 'refundStatus',
  RescheduledFromId = 'rescheduledFromId',
  RescheduledMetadata = 'rescheduledMetadata',
  ServiceId = 'serviceId',
  ServiceProviderId = 'serviceProviderId',
  State = 'state',
  Status = 'status',
  SubTotalAmount = 'subTotalAmount',
  SubTotalBreakdown = 'subTotalBreakdown',
  TotalAmount = 'totalAmount',
  TotalEarningAmount = 'totalEarningAmount',
  UpdatedAt = 'updatedAt'
}

export type OrderScalarWhereInput = {
  AND?: InputMaybe<Array<OrderScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrderScalarWhereInput>>;
  OR?: InputMaybe<Array<OrderScalarWhereInput>>;
  autoAcceptNotifiedAt?: InputMaybe<DateTimeNullableFilter>;
  cancelComments?: InputMaybe<StringNullableFilter>;
  cancelMetadata?: InputMaybe<JsonNullableFilter>;
  cancelReason?: InputMaybe<StringNullableFilter>;
  cancelResolutionType?: InputMaybe<EnumOrderCancelResolutionTypeNullableFilter>;
  cancelledAt?: InputMaybe<DateTimeNullableFilter>;
  cancelledBy?: InputMaybe<EnumOrderActorNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  confirmedAt?: InputMaybe<DateTimeNullableFilter>;
  countryCode?: InputMaybe<EnumCountryCodeNullableFilter>;
  couponCode?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  customerId?: InputMaybe<StringFilter>;
  customerNotes?: InputMaybe<StringNullableFilter>;
  date?: InputMaybe<DateTimeNullableFilter>;
  discountAmount?: InputMaybe<DecimalNullableFilter>;
  earningCurrencyCode?: InputMaybe<StringNullableFilter>;
  failureCode?: InputMaybe<StringNullableFilter>;
  failureMessage?: InputMaybe<StringNullableFilter>;
  fulfillmentFailedState?: InputMaybe<EnumFulfillmentFailedStateNullableFilter>;
  fulfillmentStatus?: InputMaybe<EnumFulfillmentStatusFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  isRefund?: InputMaybe<BoolFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  notRefundedFees?: InputMaybe<JsonNullableFilter>;
  offeringId?: InputMaybe<StringFilter>;
  orderNumber?: InputMaybe<StringNullableFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFilter>;
  paymentStatus?: InputMaybe<EnumPaymentStatusFilter>;
  rawPricingMetadata?: InputMaybe<JsonNullableFilter>;
  refundAmount?: InputMaybe<DecimalNullableFilter>;
  refundRemarks?: InputMaybe<StringNullableFilter>;
  refundReviewStatus?: InputMaybe<EnumOrderRefundReviewStatusNullableFilter>;
  refundStatus?: InputMaybe<EnumOrderRefundStatusNullableFilter>;
  rescheduledFromId?: InputMaybe<StringNullableFilter>;
  rescheduledMetadata?: InputMaybe<JsonNullableFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  state?: InputMaybe<EnumOrderStateFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  subTotalAmount?: InputMaybe<DecimalFilter>;
  subTotalBreakdown?: InputMaybe<JsonNullableFilter>;
  totalAmount?: InputMaybe<DecimalFilter>;
  totalEarningAmount?: InputMaybe<DecimalNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderSessionInfo = {
  __typename?: 'OrderSessionInfo';
  date: Scalars['DateTime'];
  formattedDate: Scalars['String'];
  formattedSessionSlots: Array<Scalars['String']>;
  sessionSlots: Array<BaseAvailableSessionSlot>;
};

export enum OrderState {
  Cancelled = 'Cancelled',
  CheckedIn = 'CheckedIn',
  Completed = 'Completed',
  Confirmed = 'Confirmed',
  Created = 'Created',
  Failed = 'Failed',
  PendingConfirmation = 'PendingConfirmation',
  RefundProcessed = 'RefundProcessed',
  Rescheduled = 'Rescheduled'
}

export enum OrderStatus {
  Completed = 'Completed',
  Pending = 'Pending',
  Scheduled = 'Scheduled'
}

export type OrderSubTotalBreakdown = {
  __typename?: 'OrderSubTotalBreakdown';
  insuranceFee: Money;
  sessionFee: Money;
};

export type OrderTransitionAction = {
  __typename?: 'OrderTransitionAction';
  /** Actionable endpoint */
  action?: Maybe<Scalars['String']>;
  status: OrderTransitionStatus;
};

export enum OrderTransitionStatus {
  Cancelled = 'Cancelled',
  CheckedIn = 'CheckedIn',
  Completed = 'Completed',
  Confirmed = 'Confirmed',
  Declined = 'Declined',
  RefundProcessed = 'RefundProcessed',
  Rescheduled = 'Rescheduled'
}

export type OrderUncheckedCreateNestedManyWithoutOfferingInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutOfferingInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutOfferingInput>>;
  createMany?: InputMaybe<OrderCreateManyOfferingInputEnvelope>;
};

export type OrderUncheckedCreateWithoutFulfillmentTasksInput = {
  autoAcceptNotifiedAt?: InputMaybe<Scalars['DateTime']>;
  cancelComments?: InputMaybe<Scalars['String']>;
  cancelReason?: InputMaybe<Scalars['String']>;
  cancelResolutionType?: InputMaybe<OrderCancelResolutionType>;
  cancelledBy?: InputMaybe<OrderActor>;
  countryCode?: InputMaybe<CountryCode>;
  couponCode?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
  customerNotes?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isRefund?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  notRefundedFees?: InputMaybe<Scalars['JSON']>;
  offeringId: Scalars['String'];
  orderNumber?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  refundAmount?: InputMaybe<Scalars['Decimal']>;
  refundStatus?: InputMaybe<OrderRefundStatus>;
};

export type OrderUncheckedCreateWithoutOfferingInput = {
  autoAcceptNotifiedAt?: InputMaybe<Scalars['DateTime']>;
  cancelComments?: InputMaybe<Scalars['String']>;
  cancelReason?: InputMaybe<Scalars['String']>;
  cancelResolutionType?: InputMaybe<OrderCancelResolutionType>;
  cancelledBy?: InputMaybe<OrderActor>;
  countryCode?: InputMaybe<CountryCode>;
  couponCode?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
  customerNotes?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isRefund?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  notRefundedFees?: InputMaybe<Scalars['JSON']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  refundAmount?: InputMaybe<Scalars['Decimal']>;
  refundStatus?: InputMaybe<OrderRefundStatus>;
};

export type OrderUncheckedCreateWithoutReviewsInput = {
  autoAcceptNotifiedAt?: InputMaybe<Scalars['DateTime']>;
  cancelComments?: InputMaybe<Scalars['String']>;
  cancelReason?: InputMaybe<Scalars['String']>;
  cancelResolutionType?: InputMaybe<OrderCancelResolutionType>;
  cancelledBy?: InputMaybe<OrderActor>;
  countryCode?: InputMaybe<CountryCode>;
  couponCode?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
  customerNotes?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isRefund?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  notRefundedFees?: InputMaybe<Scalars['JSON']>;
  offeringId: Scalars['String'];
  orderNumber?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  refundAmount?: InputMaybe<Scalars['Decimal']>;
  refundStatus?: InputMaybe<OrderRefundStatus>;
};

export type OrderUncheckedUpdateManyWithoutOfferingNestedInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutOfferingInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutOfferingInput>>;
  createMany?: InputMaybe<OrderCreateManyOfferingInputEnvelope>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderUpdateWithWhereUniqueWithoutOfferingInput>>;
  updateMany?: InputMaybe<Array<OrderUpdateManyWithWhereWithoutOfferingInput>>;
  upsert?: InputMaybe<Array<OrderUpsertWithWhereUniqueWithoutOfferingInput>>;
};

export type OrderUncheckedUpdateManyWithoutOrdersInput = {
  autoAcceptNotifiedAt?: InputMaybe<Scalars['DateTime']>;
  cancelComments?: InputMaybe<Scalars['String']>;
  cancelReason?: InputMaybe<Scalars['String']>;
  cancelResolutionType?: InputMaybe<OrderCancelResolutionType>;
  cancelledBy?: InputMaybe<OrderActor>;
  countryCode?: InputMaybe<CountryCode>;
  couponCode?: InputMaybe<Scalars['String']>;
  customerNotes?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isRefund?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  notRefundedFees?: InputMaybe<Scalars['JSON']>;
  offeringId?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  refundAmount?: InputMaybe<Scalars['Decimal']>;
  refundStatus?: InputMaybe<OrderRefundStatus>;
};

export type OrderUncheckedUpdateWithoutOfferingInput = {
  autoAcceptNotifiedAt?: InputMaybe<Scalars['DateTime']>;
  cancelComments?: InputMaybe<Scalars['String']>;
  cancelReason?: InputMaybe<Scalars['String']>;
  cancelResolutionType?: InputMaybe<OrderCancelResolutionType>;
  cancelledBy?: InputMaybe<OrderActor>;
  countryCode?: InputMaybe<CountryCode>;
  couponCode?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  customerNotes?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isRefund?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  notRefundedFees?: InputMaybe<Scalars['JSON']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  refundAmount?: InputMaybe<Scalars['Decimal']>;
  refundStatus?: InputMaybe<OrderRefundStatus>;
};

export type OrderUpdateManyWithWhereWithoutOfferingInput = {
  data: OrderUncheckedUpdateManyWithoutOrdersInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateWithWhereUniqueWithoutOfferingInput = {
  data: OrderUncheckedUpdateWithoutOfferingInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutOfferingInput = {
  create: OrderUncheckedCreateWithoutOfferingInput;
  update: OrderUncheckedUpdateWithoutOfferingInput;
  where: OrderWhereUniqueInput;
};

export type OrderWhereInput = {
  AND?: InputMaybe<Array<OrderWhereInput>>;
  NOT?: InputMaybe<Array<OrderWhereInput>>;
  OR?: InputMaybe<Array<OrderWhereInput>>;
  autoAcceptNotifiedAt?: InputMaybe<DateTimeNullableFilter>;
  cancelComments?: InputMaybe<StringNullableFilter>;
  cancelMetadata?: InputMaybe<JsonNullableFilter>;
  cancelReason?: InputMaybe<StringNullableFilter>;
  cancelResolutionType?: InputMaybe<EnumOrderCancelResolutionTypeNullableFilter>;
  cancelledAt?: InputMaybe<DateTimeNullableFilter>;
  cancelledBy?: InputMaybe<EnumOrderActorNullableFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  confirmedAt?: InputMaybe<DateTimeNullableFilter>;
  countryCode?: InputMaybe<EnumCountryCodeNullableFilter>;
  couponCode?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  customerNotes?: InputMaybe<StringNullableFilter>;
  date?: InputMaybe<DateTimeNullableFilter>;
  discountAmount?: InputMaybe<DecimalNullableFilter>;
  earningCurrencyCode?: InputMaybe<StringNullableFilter>;
  failureCode?: InputMaybe<StringNullableFilter>;
  failureMessage?: InputMaybe<StringNullableFilter>;
  fulfillmentFailedState?: InputMaybe<EnumFulfillmentFailedStateNullableFilter>;
  fulfillmentStatus?: InputMaybe<EnumFulfillmentStatusFilter>;
  fulfillmentTasks?: InputMaybe<FulfillmentTaskListRelationFilter>;
  fulfillments?: InputMaybe<FulfillmentListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  isRefund?: InputMaybe<BoolFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  notRefundedFees?: InputMaybe<JsonNullableFilter>;
  offeringId?: InputMaybe<StringFilter>;
  orderNumber?: InputMaybe<StringNullableFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFilter>;
  paymentStatus?: InputMaybe<EnumPaymentStatusFilter>;
  payments?: InputMaybe<PaymentListRelationFilter>;
  rawPricingMetadata?: InputMaybe<JsonNullableFilter>;
  refundAmount?: InputMaybe<DecimalNullableFilter>;
  refundRemarks?: InputMaybe<StringNullableFilter>;
  refundReviewStatus?: InputMaybe<EnumOrderRefundReviewStatusNullableFilter>;
  refundStatus?: InputMaybe<EnumOrderRefundStatusNullableFilter>;
  rescheduledFromId?: InputMaybe<StringNullableFilter>;
  rescheduledMetadata?: InputMaybe<JsonNullableFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  state?: InputMaybe<EnumOrderStateFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  subTotalAmount?: InputMaybe<DecimalFilter>;
  subTotalBreakdown?: InputMaybe<JsonNullableFilter>;
  totalAmount?: InputMaybe<DecimalFilter>;
  totalEarningAmount?: InputMaybe<DecimalNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OrderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  orderNumber?: InputMaybe<Scalars['String']>;
};

export type OtpChallenge = {
  __typename?: 'OtpChallenge';
  consumer: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expiredOn: Scalars['DateTime'];
  id: Scalars['ID'];
  method: OtpMethod;
  purpose: OtpPurpose;
  retryWaitSeconds: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export enum OtpMethod {
  Email = 'Email',
  Sms = 'SMS'
}

export enum OtpPurpose {
  BankAccountVerification = 'BankAccountVerification',
  Login = 'Login',
  MobileNumberVerification = 'MobileNumberVerification',
  PasswordReset = 'PasswordReset',
  Registration = 'Registration'
}

export type PaginatationMetadata = {
  __typename?: 'PaginatationMetadata';
  currentPage: Scalars['Int'];
  perPage: Scalars['Int'];
  totalCount: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginatedAdminPermission = {
  __typename?: 'PaginatedAdminPermission';
  items?: Maybe<Array<AdminPermission>>;
  metadata: PaginatationMetadata;
};

export type PaginatedAdminRole = {
  __typename?: 'PaginatedAdminRole';
  items?: Maybe<Array<AdminRole>>;
  metadata: PaginatationMetadata;
};

export type PaginatedAdminUser = {
  __typename?: 'PaginatedAdminUser';
  items?: Maybe<Array<AdminUser>>;
  metadata: PaginatationMetadata;
};

export type PaginatedBank = {
  __typename?: 'PaginatedBank';
  items?: Maybe<Array<Bank>>;
  metadata: PaginatationMetadata;
};

export type PaginatedBlockedUser = {
  __typename?: 'PaginatedBlockedUser';
  items?: Maybe<Array<BlockedUser>>;
  metadata: PaginatationMetadata;
};

export type PaginatedBusiness = {
  __typename?: 'PaginatedBusiness';
  items?: Maybe<Array<Business>>;
  metadata: PaginatationMetadata;
};

export type PaginatedBusinessApplication = {
  __typename?: 'PaginatedBusinessApplication';
  items?: Maybe<Array<BusinessApplication>>;
  metadata: PaginatationMetadata;
};

export type PaginatedCustomer = {
  __typename?: 'PaginatedCustomer';
  items?: Maybe<Array<Customer>>;
  metadata: PaginatationMetadata;
};

export type PaginatedFulfillment = {
  __typename?: 'PaginatedFulfillment';
  items?: Maybe<Array<Fulfillment>>;
  metadata: PaginatationMetadata;
};

export type PaginatedOffering = {
  __typename?: 'PaginatedOffering';
  items?: Maybe<Array<Offering>>;
  metadata: PaginatationMetadata;
};

export type PaginatedOrder = {
  __typename?: 'PaginatedOrder';
  items?: Maybe<Array<Order>>;
  metadata: PaginatationMetadata;
};

export type PaginatedOrderEvent = {
  __typename?: 'PaginatedOrderEvent';
  items?: Maybe<Array<OrderEvent>>;
  metadata: PaginatationMetadata;
};

export type PaginatedPayment = {
  __typename?: 'PaginatedPayment';
  items?: Maybe<Array<Payment>>;
  metadata: PaginatationMetadata;
};

export type PaginatedPayout = {
  __typename?: 'PaginatedPayout';
  items?: Maybe<Array<Payout>>;
  metadata: PaginatationMetadata;
};

export type PaginatedReview = {
  __typename?: 'PaginatedReview';
  items?: Maybe<Array<Review>>;
  metadata: PaginatationMetadata;
};

export type PaginatedService = {
  __typename?: 'PaginatedService';
  items?: Maybe<Array<Service>>;
  metadata: PaginatationMetadata;
};

export type PaginatedServiceProvider = {
  __typename?: 'PaginatedServiceProvider';
  items?: Maybe<Array<ServiceProvider>>;
  metadata: PaginatationMetadata;
};

export type PaginatedSystemSetting = {
  __typename?: 'PaginatedSystemSetting';
  items?: Maybe<Array<SystemSetting>>;
  metadata: PaginatationMetadata;
};

export type PaginatedTopupPackage = {
  __typename?: 'PaginatedTopupPackage';
  items?: Maybe<Array<TopupPackage>>;
  metadata: PaginatationMetadata;
};

export type PaginatedTransaction = {
  __typename?: 'PaginatedTransaction';
  items?: Maybe<Array<Transaction>>;
  metadata: PaginatationMetadata;
};

export type PaginatedWallet = {
  __typename?: 'PaginatedWallet';
  items?: Maybe<Array<Wallet>>;
  metadata: PaginatationMetadata;
};

export type PaginatedWithdrawal = {
  __typename?: 'PaginatedWithdrawal';
  items?: Maybe<Array<Withdrawal>>;
  metadata: PaginatationMetadata;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Money;
  countryCode: CountryCode;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  failureCode?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
  gatewayRequest?: Maybe<Scalars['JSON']>;
  gatewayResponse?: Maybe<Scalars['JSON']>;
  gatewayTransactionId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['String']>;
  processor: PaymentProcessor;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<Scalars['String']>;
  requeryResponse?: Maybe<Scalars['JSON']>;
  status: PaymentStatus;
  topup?: Maybe<Topup>;
  topupId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PaymentExtendedInfo = {
  __typename?: 'PaymentExtendedInfo';
  clientSecret?: Maybe<Scalars['String']>;
};

export type PaymentListRelationFilter = {
  every?: InputMaybe<PaymentWhereInput>;
  none?: InputMaybe<PaymentWhereInput>;
  some?: InputMaybe<PaymentWhereInput>;
};

export enum PaymentMethod {
  Wallet = 'Wallet'
}

export type PaymentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PaymentOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  processor?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum PaymentProcessor {
  Cash = 'Cash',
  Stripe = 'Stripe',
  Wallet = 'Wallet'
}

export enum PaymentScalarFieldEnum {
  Amount = 'amount',
  CountryCode = 'countryCode',
  CreatedAt = 'createdAt',
  CurrencyCode = 'currencyCode',
  Description = 'description',
  FailureCode = 'failureCode',
  FailureMessage = 'failureMessage',
  GatewayRequest = 'gatewayRequest',
  GatewayResponse = 'gatewayResponse',
  GatewayTransactionId = 'gatewayTransactionId',
  Id = 'id',
  Metadata = 'metadata',
  OrderId = 'orderId',
  Processor = 'processor',
  ReferenceId = 'referenceId',
  ReferenceType = 'referenceType',
  RequeryResponse = 'requeryResponse',
  Status = 'status',
  TopupId = 'topupId',
  UpdatedAt = 'updatedAt'
}

export enum PaymentStatus {
  Authorized = 'Authorized',
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  Pending = 'Pending',
  Refunded = 'Refunded',
  RequiresAction = 'RequiresAction',
  Succeeded = 'Succeeded'
}

export type PaymentWhereInput = {
  AND?: InputMaybe<Array<PaymentWhereInput>>;
  NOT?: InputMaybe<Array<PaymentWhereInput>>;
  OR?: InputMaybe<Array<PaymentWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  failureCode?: InputMaybe<StringNullableFilter>;
  failureMessage?: InputMaybe<StringNullableFilter>;
  gatewayRequest?: InputMaybe<JsonNullableFilter>;
  gatewayResponse?: InputMaybe<JsonNullableFilter>;
  gatewayTransactionId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  processor?: InputMaybe<EnumPaymentProcessorFilter>;
  referenceId?: InputMaybe<StringNullableFilter>;
  referenceType?: InputMaybe<StringNullableFilter>;
  requeryResponse?: InputMaybe<JsonNullableFilter>;
  status?: InputMaybe<EnumPaymentStatusFilter>;
  topup?: InputMaybe<TopupRelationFilter>;
  topupId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PaymentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Payout = {
  __typename?: 'Payout';
  _count?: Maybe<PayoutCount>;
  countryCode: CountryCode;
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  status: PayoutStatus;
  totalAmount: Money;
  totalWithdrawalsCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  withdrawals: Array<Withdrawal>;
};


export type PayoutWithdrawalsArgs = {
  cursor?: InputMaybe<WithdrawalWhereUniqueInput>;
  distinct?: InputMaybe<Array<WithdrawalScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WithdrawalOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WithdrawalWhereInput>;
};

export type PayoutCount = {
  __typename?: 'PayoutCount';
  withdrawals: Scalars['Int'];
};

export type PayoutOrderByWithRelationInput = {
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  date?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  totalAmount?: InputMaybe<SortOrder>;
  totalWithdrawalsCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum PayoutStatus {
  Failed = 'Failed',
  Paid = 'Paid',
  Processing = 'Processing',
  Scheduled = 'Scheduled'
}

export type PayoutUpdateInputV2 = {
  status: PayoutStatus;
};

export type PayoutWhereInput = {
  AND?: InputMaybe<Array<PayoutWhereInput>>;
  NOT?: InputMaybe<Array<PayoutWhereInput>>;
  OR?: InputMaybe<Array<PayoutWhereInput>>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumPayoutStatusFilter>;
  totalAmount?: InputMaybe<DecimalFilter>;
  totalWithdrawalsCount?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PayoutWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum PeriodType {
  Once = 'Once',
  Range = 'Range',
  Rolling = 'Rolling',
  Unlimited = 'Unlimited'
}

export type PermissionGrant = {
  __typename?: 'PermissionGrant';
  action: Scalars['String'];
  code: Scalars['String'];
  resource: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  accounts?: Maybe<Array<Account>>;
  adminPermission?: Maybe<AdminPermission>;
  adminPermissions: PaginatedAdminPermission;
  adminRole?: Maybe<AdminRole>;
  adminRoles: PaginatedAdminRole;
  adminUser?: Maybe<AdminUser>;
  adminUsers: PaginatedAdminUser;
  aggregateCancellationReason: AggregateCancellationReason;
  bank?: Maybe<Bank>;
  banks: PaginatedBank;
  blockedUser?: Maybe<BlockedUser>;
  blockedUsers: PaginatedBlockedUser;
  business?: Maybe<Business>;
  businessApplication?: Maybe<BusinessApplication>;
  businessApplications: PaginatedBusinessApplication;
  businesses: PaginatedBusiness;
  cancellationReason?: Maybe<CancellationReason>;
  cancellationReasons: Array<CancellationReason>;
  customer?: Maybe<Customer>;
  customerTransactions: PaginatedTransaction;
  customers: PaginatedCustomer;
  findFirstCancellationReason?: Maybe<CancellationReason>;
  findFirstCancellationReasonOrThrow?: Maybe<CancellationReason>;
  fulfillment?: Maybe<Fulfillment>;
  fulfillments: PaginatedFulfillment;
  getCancellationReason?: Maybe<CancellationReason>;
  getDashboardEmbedUrl: DashboardConfiguration;
  groupByCancellationReason: Array<CancellationReasonGroupBy>;
  me: AdminUser;
  offering?: Maybe<Offering>;
  offerings: PaginatedOffering;
  order?: Maybe<Order>;
  orderEvent?: Maybe<OrderEvent>;
  orderEvents: PaginatedOrderEvent;
  orderTransitionActions?: Maybe<Array<OrderTransitionAction>>;
  orders: PaginatedOrder;
  payment?: Maybe<Payment>;
  payments: PaginatedPayment;
  payout?: Maybe<Payout>;
  payouts: PaginatedPayout;
  review?: Maybe<Review>;
  reviews: PaginatedReview;
  searchAdminUsers: PaginatedAdminUser;
  searchCustomers: PaginatedCustomer;
  searchServiceProviders: PaginatedServiceProvider;
  service?: Maybe<Service>;
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderEarningTransactions: PaginatedTransaction;
  serviceProviderTransactions: PaginatedTransaction;
  serviceProviders: PaginatedServiceProvider;
  services: PaginatedService;
  systemSetting?: Maybe<SystemSetting>;
  systemSettings: PaginatedSystemSetting;
  topup?: Maybe<Topup>;
  topupPackage?: Maybe<TopupPackage>;
  topupPackages: PaginatedTopupPackage;
  transaction?: Maybe<Transaction>;
  transactions: PaginatedTransaction;
  wallet?: Maybe<Wallet>;
  wallets: PaginatedWallet;
  withdrawal?: Maybe<Withdrawal>;
  withdrawals: PaginatedWithdrawal;
};


export type QueryAdminPermissionArgs = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAdminPermissionsArgs = {
  orderBy?: InputMaybe<Array<AdminPermissionOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<AdminPermissionWhereInput>;
};


export type QueryAdminRoleArgs = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAdminRolesArgs = {
  orderBy?: InputMaybe<Array<AdminRoleOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<AdminRoleWhereInput>;
};


export type QueryAdminUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAdminUsersArgs = {
  orderBy?: InputMaybe<Array<AdminUserOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<AdminUserWhereInput>;
};


export type QueryAggregateCancellationReasonArgs = {
  cursor?: InputMaybe<CancellationReasonWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CancellationReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CancellationReasonWhereInput>;
};


export type QueryBankArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBanksArgs = {
  orderBy?: InputMaybe<Array<BankOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<BankWhereInput>;
};


export type QueryBlockedUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBlockedUsersArgs = {
  orderBy?: InputMaybe<Array<BlockedUserOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<BlockedUserWhereInput>;
};


export type QueryBusinessArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessApplicationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  offeringId?: InputMaybe<Scalars['String']>;
};


export type QueryBusinessApplicationsArgs = {
  orderBy?: InputMaybe<Array<BusinessApplicationOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<BusinessApplicationWhereInput>;
};


export type QueryBusinessesArgs = {
  orderBy?: InputMaybe<Array<BusinessOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<BusinessWhereInput>;
};


export type QueryCancellationReasonArgs = {
  where: CancellationReasonWhereUniqueInput;
};


export type QueryCancellationReasonsArgs = {
  cursor?: InputMaybe<CancellationReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<CancellationReasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CancellationReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CancellationReasonWhereInput>;
};


export type QueryCustomerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomerTransactionsArgs = {
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<TransactionWhereInputV2>;
};


export type QueryCustomersArgs = {
  orderBy?: InputMaybe<Array<CustomerOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<CustomerWhereInput>;
};


export type QueryFindFirstCancellationReasonArgs = {
  cursor?: InputMaybe<CancellationReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<CancellationReasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CancellationReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CancellationReasonWhereInput>;
};


export type QueryFindFirstCancellationReasonOrThrowArgs = {
  cursor?: InputMaybe<CancellationReasonWhereUniqueInput>;
  distinct?: InputMaybe<Array<CancellationReasonScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CancellationReasonOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CancellationReasonWhereInput>;
};


export type QueryFulfillmentArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFulfillmentsArgs = {
  orderBy?: InputMaybe<Array<FulfillmentOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<FulfillmentWhereInput>;
};


export type QueryGetCancellationReasonArgs = {
  where: CancellationReasonWhereUniqueInput;
};


export type QueryGetDashboardEmbedUrlArgs = {
  id: Scalars['String'];
};


export type QueryGroupByCancellationReasonArgs = {
  by: Array<CancellationReasonScalarFieldEnum>;
  having?: InputMaybe<CancellationReasonScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<CancellationReasonOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CancellationReasonWhereInput>;
};


export type QueryOfferingArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryOfferingsArgs = {
  orderBy?: InputMaybe<Array<OfferingOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<OfferingWhereInput>;
};


export type QueryOrderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryOrderEventArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryOrderEventsArgs = {
  orderBy?: InputMaybe<Array<OrderEventOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<OrderEventWhereInput>;
};


export type QueryOrderTransitionActionsArgs = {
  id: Scalars['ID'];
};


export type QueryOrdersArgs = {
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryPaymentArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPaymentsArgs = {
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<PaymentWhereInput>;
};


export type QueryPayoutArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPayoutsArgs = {
  orderBy?: InputMaybe<Array<PayoutOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<PayoutWhereInput>;
};


export type QueryReviewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryReviewsArgs = {
  orderBy?: InputMaybe<Array<ReviewOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<ReviewWhereInputV2>;
};


export type QuerySearchAdminUsersArgs = {
  keyword: Scalars['String'];
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
};


export type QuerySearchCustomersArgs = {
  keyword: Scalars['String'];
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
};


export type QuerySearchServiceProvidersArgs = {
  keyword: Scalars['String'];
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
};


export type QueryServiceArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryServiceProviderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryServiceProviderEarningTransactionsArgs = {
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<TransactionWhereInputV2>;
};


export type QueryServiceProviderTransactionsArgs = {
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<TransactionWhereInputV2>;
};


export type QueryServiceProvidersArgs = {
  orderBy?: InputMaybe<Array<ServiceProviderOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  search?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ServiceProviderWhereInput>;
};


export type QueryServicesArgs = {
  orderBy?: InputMaybe<Array<ServiceOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<ServiceWhereInput>;
};


export type QuerySystemSettingArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySystemSettingsArgs = {
  orderBy?: InputMaybe<Array<SystemSettingOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<SystemSettingWhereInput>;
};


export type QueryTopupArgs = {
  id: Scalars['ID'];
};


export type QueryTopupPackageArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTopupPackagesArgs = {
  orderBy?: InputMaybe<Array<TopupPackageOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<TopupPackageWhereInput>;
};


export type QueryTransactionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTransactionsArgs = {
  orderBy?: InputMaybe<Array<TransactionOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<TransactionWhereInput>;
};


export type QueryWalletArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryWalletsArgs = {
  orderBy?: InputMaybe<Array<WalletOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<WalletWhereInput>;
};


export type QueryWithdrawalArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryWithdrawalsArgs = {
  orderBy?: InputMaybe<Array<WithdrawalOrderByWithRelationInput>>;
  page?: Scalars['Int'];
  perPage?: Scalars['Int'];
  where?: InputMaybe<WithdrawalWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RedirectRequest = {
  __typename?: 'RedirectRequest';
  body?: Maybe<Scalars['JSON']>;
  contentType?: Maybe<Scalars['String']>;
  headers?: Maybe<Array<HttpHeader>>;
  params?: Maybe<Array<HttpRequestParameter>>;
  /** GET or POST */
  redirectMethod: Scalars['String'];
  redirectUrl: Scalars['String'];
  returnUrl: Scalars['String'];
};

export type RequestOtpInput = {
  email?: InputMaybe<Scalars['String']>;
  isResend?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  purpose: OtpPurpose;
};

export type ResetPasswordInput = {
  password: Scalars['String'];
};

export type Review = {
  __typename?: 'Review';
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  offering: Offering;
  offeringId: Scalars['String'];
  orderId: Scalars['String'];
  rating: Scalars['Int'];
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars['String'];
  type: ReviewType;
  updatedAt: Scalars['DateTime'];
};

export type ReviewCreateManyOfferingInput = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  orderId: Scalars['String'];
  rating: Scalars['Int'];
  type?: InputMaybe<ReviewType>;
};

export type ReviewCreateManyOfferingInputEnvelope = {
  data: Array<ReviewCreateManyOfferingInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ReviewCreateNestedManyWithoutOfferingInput = {
  connect?: InputMaybe<Array<ReviewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReviewCreateOrConnectWithoutOfferingInput>>;
  create?: InputMaybe<Array<ReviewCreateWithoutOfferingInput>>;
  createMany?: InputMaybe<ReviewCreateManyOfferingInputEnvelope>;
};

export type ReviewCreateOrConnectWithoutOfferingInput = {
  create: ReviewUncheckedCreateWithoutOfferingInput;
  where: ReviewWhereUniqueInput;
};

export type ReviewCreateWithoutOfferingInput = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  order: OrderCreateNestedOneWithoutReviewsInput;
  rating: Scalars['Int'];
  type?: InputMaybe<ReviewType>;
};

export type ReviewListRelationFilter = {
  every?: InputMaybe<ReviewWhereInput>;
  none?: InputMaybe<ReviewWhereInput>;
  some?: InputMaybe<ReviewWhereInput>;
};

export type ReviewOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReviewOrderByWithRelationInput = {
  comment?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  offeringId?: InputMaybe<SortOrder>;
  orderId?: InputMaybe<SortOrder>;
  rating?: InputMaybe<SortOrder>;
  serviceProvider?: InputMaybe<ServiceProviderOrderByWithRelationInput>;
  serviceProviderId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ReviewScalarFieldEnum {
  Comment = 'comment',
  CreatedAt = 'createdAt',
  CustomerId = 'customerId',
  DeletedAt = 'deletedAt',
  Id = 'id',
  OfferingId = 'offeringId',
  OrderId = 'orderId',
  Rating = 'rating',
  ServiceProviderId = 'serviceProviderId',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type ReviewScalarWhereInput = {
  AND?: InputMaybe<Array<ReviewScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReviewScalarWhereInput>>;
  OR?: InputMaybe<Array<ReviewScalarWhereInput>>;
  comment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  offeringId?: InputMaybe<StringFilter>;
  orderId?: InputMaybe<StringFilter>;
  rating?: InputMaybe<IntFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumReviewTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum ReviewType {
  CustomerToServiceProvider = 'CustomerToServiceProvider',
  ServiceProviderToCustomer = 'ServiceProviderToCustomer'
}

export type ReviewTypeOrderIdCompoundUniqueInput = {
  orderId: Scalars['String'];
  type: ReviewType;
};

export type ReviewUncheckedCreateNestedManyWithoutOfferingInput = {
  connect?: InputMaybe<Array<ReviewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReviewCreateOrConnectWithoutOfferingInput>>;
  create?: InputMaybe<Array<ReviewCreateWithoutOfferingInput>>;
  createMany?: InputMaybe<ReviewCreateManyOfferingInputEnvelope>;
};

export type ReviewUncheckedCreateWithoutOfferingInput = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  orderId: Scalars['String'];
  rating: Scalars['Int'];
  type?: InputMaybe<ReviewType>;
};

export type ReviewUncheckedUpdateManyWithoutOfferingNestedInput = {
  connect?: InputMaybe<Array<ReviewWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReviewCreateOrConnectWithoutOfferingInput>>;
  create?: InputMaybe<Array<ReviewCreateWithoutOfferingInput>>;
  createMany?: InputMaybe<ReviewCreateManyOfferingInputEnvelope>;
  delete?: InputMaybe<Array<ReviewWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReviewScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReviewWhereUniqueInput>>;
  set?: InputMaybe<Array<ReviewWhereUniqueInput>>;
  update?: InputMaybe<Array<ReviewUpdateWithWhereUniqueWithoutOfferingInput>>;
  updateMany?: InputMaybe<Array<ReviewUpdateManyWithWhereWithoutOfferingInput>>;
  upsert?: InputMaybe<Array<ReviewUpsertWithWhereUniqueWithoutOfferingInput>>;
};

export type ReviewUncheckedUpdateManyWithoutReviewsInput = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  offeringId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ReviewType>;
};

export type ReviewUncheckedUpdateWithoutOfferingInput = {
  comment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  orderId?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ReviewType>;
};

export type ReviewUpdateManyWithWhereWithoutOfferingInput = {
  data: ReviewUncheckedUpdateManyWithoutReviewsInput;
  where: ReviewScalarWhereInput;
};

export type ReviewUpdateWithWhereUniqueWithoutOfferingInput = {
  data: ReviewUncheckedUpdateWithoutOfferingInput;
  where: ReviewWhereUniqueInput;
};

export type ReviewUpsertWithWhereUniqueWithoutOfferingInput = {
  create: ReviewUncheckedCreateWithoutOfferingInput;
  update: ReviewUncheckedUpdateWithoutOfferingInput;
  where: ReviewWhereUniqueInput;
};

export type ReviewWhereInput = {
  AND?: InputMaybe<Array<ReviewWhereInput>>;
  NOT?: InputMaybe<Array<ReviewWhereInput>>;
  OR?: InputMaybe<Array<ReviewWhereInput>>;
  comment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  offering?: InputMaybe<OfferingRelationFilter>;
  offeringId?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  rating?: InputMaybe<IntFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumReviewTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ReviewWhereInputV2 = {
  AND?: InputMaybe<Array<ReviewWhereInput>>;
  NOT?: InputMaybe<Array<ReviewWhereInput>>;
  OR?: InputMaybe<Array<ReviewWhereInput>>;
  approvedMainFacets?: InputMaybe<IntFilter>;
  comment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  customerId?: InputMaybe<StringFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  offering?: InputMaybe<OfferingRelationFilter>;
  offeringId?: InputMaybe<StringFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringFilter>;
  rating?: InputMaybe<IntFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumReviewTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ReviewWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  type_orderId?: InputMaybe<ReviewTypeOrderIdCompoundUniqueInput>;
};

export enum Role {
  Admin = 'Admin',
  Customer = 'Customer',
  ServiceProvider = 'ServiceProvider'
}

export type Service = {
  __typename?: 'Service';
  _count?: Maybe<ServiceCount>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  hasPendingBusinessApplications: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  offerings: Array<Offering>;
  professionName?: Maybe<Scalars['String']>;
  status: Status;
  type: ServiceType;
  updatedAt: Scalars['DateTime'];
};


export type ServiceOfferingsArgs = {
  cursor?: InputMaybe<OfferingWhereUniqueInput>;
  distinct?: InputMaybe<Array<OfferingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OfferingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OfferingWhereInput>;
};

export type ServiceCount = {
  __typename?: 'ServiceCount';
  availabilities: Scalars['Int'];
  businessApplications: Scalars['Int'];
  businesses: Scalars['Int'];
  customerPreferences: Scalars['Int'];
  filterGroups: Scalars['Int'];
  offerings: Scalars['Int'];
};

export type ServiceCreateNestedOneWithoutBusinessApplicationsInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutBusinessApplicationsInput>;
  create?: InputMaybe<ServiceUncheckedCreateWithoutBusinessApplicationsInput>;
};

export type ServiceCreateNestedOneWithoutOfferingsInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutOfferingsInput>;
  create?: InputMaybe<ServiceUncheckedCreateWithoutOfferingsInput>;
};

export type ServiceCreateOrConnectWithoutBusinessApplicationsInput = {
  create: ServiceUncheckedCreateWithoutBusinessApplicationsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutOfferingsInput = {
  create: ServiceUncheckedCreateWithoutOfferingsInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceOrderByWithRelationInput = {
  businesses?: InputMaybe<BusinessOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  customerPreferences?: InputMaybe<CustomerPreferenceOrderByRelationAggregateInput>;
  filterGroups?: InputMaybe<FacetFilterGroupOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  offerings?: InputMaybe<OfferingOrderByRelationAggregateInput>;
  professionName?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ServiceProvider = {
  __typename?: 'ServiceProvider';
  _count?: Maybe<ServiceProviderCount>;
  address?: Maybe<Address>;
  approvedMainFacets: Array<FacetValue>;
  availabilities: Array<Availability>;
  availableOfferings: Array<Offering>;
  averageRating?: Maybe<Scalars['Decimal']>;
  bankAccount?: Maybe<BankAccount>;
  businessStates: Array<ServiceProviderBusinessState>;
  colorRating: ColorRating;
  colorRatingProgress: ColorRatingProgress;
  countryCode: CountryCode;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  fulfillmentTasks: Array<FulfillmentTask>;
  fullName: Scalars['String'];
  gender?: Maybe<Gender>;
  id: Scalars['ID'];
  identityDocument?: Maybe<Scalars['JSON']>;
  identityNumber?: Maybe<Scalars['String']>;
  mobileNumber: Scalars['String'];
  offerings: Array<Offering>;
  orders: Array<Order>;
  ordersCount: Scalars['Int'];
  profilePicture?: Maybe<Scalars['String']>;
  reviews: Array<Review>;
  status: ServiceProviderStatus;
  totalEarnings: Money;
  totalRatingCount: Scalars['Int'];
  unavailablePeriods: Array<ServiceProviderUnavailablePeriod>;
  updatedAt: Scalars['DateTime'];
  verificationStatus: ServiceProviderVerificationStatus;
  wallet?: Maybe<Wallet>;
  withdrawals: Array<Withdrawal>;
};


export type ServiceProviderAvailabilitiesArgs = {
  cursor?: InputMaybe<AvailabilityWhereUniqueInput>;
  distinct?: InputMaybe<Array<AvailabilityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AvailabilityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AvailabilityWhereInput>;
};


export type ServiceProviderFulfillmentTasksArgs = {
  cursor?: InputMaybe<FulfillmentTaskWhereUniqueInput>;
  distinct?: InputMaybe<Array<FulfillmentTaskScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<FulfillmentTaskOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FulfillmentTaskWhereInput>;
};


export type ServiceProviderOfferingsArgs = {
  cursor?: InputMaybe<OfferingWhereUniqueInput>;
  distinct?: InputMaybe<Array<OfferingScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OfferingOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OfferingWhereInput>;
};


export type ServiceProviderOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type ServiceProviderReviewsArgs = {
  cursor?: InputMaybe<ReviewWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReviewScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReviewOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ReviewWhereInput>;
};


export type ServiceProviderUnavailablePeriodsArgs = {
  cursor?: InputMaybe<ServiceProviderUnavailablePeriodWhereUniqueInput>;
  distinct?: InputMaybe<Array<ServiceProviderUnavailablePeriodScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ServiceProviderUnavailablePeriodOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ServiceProviderUnavailablePeriodWhereInput>;
};


export type ServiceProviderWithdrawalsArgs = {
  cursor?: InputMaybe<WithdrawalWhereUniqueInput>;
  distinct?: InputMaybe<Array<WithdrawalScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WithdrawalOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WithdrawalWhereInput>;
};

export type ServiceProviderBusinessState = {
  __typename?: 'ServiceProviderBusinessState';
  isVerified: Scalars['Boolean'];
  name: Scalars['String'];
};

export type ServiceProviderCount = {
  __typename?: 'ServiceProviderCount';
  availabilities: Scalars['Int'];
  businessApplications: Scalars['Int'];
  businesses: Scalars['Int'];
  fulfillmentTasks: Scalars['Int'];
  offerings: Scalars['Int'];
  orders: Scalars['Int'];
  reviews: Scalars['Int'];
  unavailablePeriods: Scalars['Int'];
  wallets: Scalars['Int'];
  withdrawals: Scalars['Int'];
};

export type ServiceProviderCreateNestedOneWithoutBusinessApplicationsInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutBusinessApplicationsInput>;
  create?: InputMaybe<ServiceProviderUncheckedCreateWithoutBusinessApplicationsInput>;
};

export type ServiceProviderCreateNestedOneWithoutOrdersInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<ServiceProviderUncheckedCreateWithoutOrdersInput>;
};

export type ServiceProviderCreateNestedOneWithoutWithdrawalsInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutWithdrawalsInput>;
  create?: InputMaybe<ServiceProviderUncheckedCreateWithoutWithdrawalsInput>;
};

export type ServiceProviderCreateOrConnectWithoutBusinessApplicationsInput = {
  create: ServiceProviderUncheckedCreateWithoutBusinessApplicationsInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateOrConnectWithoutOrdersInput = {
  create: ServiceProviderUncheckedCreateWithoutOrdersInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateOrConnectWithoutUserInput = {
  create: ServiceProviderUncheckedCreateWithoutUserInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderCreateOrConnectWithoutWithdrawalsInput = {
  create: ServiceProviderUncheckedCreateWithoutWithdrawalsInput;
  where: ServiceProviderWhereUniqueInput;
};

export type ServiceProviderOrderByWithRelationInput = {
  averageRating?: InputMaybe<SortOrderInput>;
  businessApplications?: InputMaybe<BusinessApplicationOrderByRelationAggregateInput>;
  businesses?: InputMaybe<BusinessOrderByRelationAggregateInput>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  gender?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  identityDocument?: InputMaybe<SortOrderInput>;
  identityNumber?: InputMaybe<SortOrderInput>;
  mobileNumber?: InputMaybe<SortOrder>;
  ordersCount?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  totalRatingCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  verificationStatus?: InputMaybe<SortOrder>;
};

export type ServiceProviderRelationFilter = {
  is?: InputMaybe<ServiceProviderWhereInput>;
  isNot?: InputMaybe<ServiceProviderWhereInput>;
};

export enum ServiceProviderStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending'
}

export type ServiceProviderUnavailablePeriod = {
  __typename?: 'ServiceProviderUnavailablePeriod';
  createdAt: Scalars['DateTime'];
  endsAt: Scalars['DateTime'];
  id: Scalars['ID'];
  offeringId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  serviceProviderId: Scalars['String'];
  startsAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ServiceProviderUnavailablePeriodListRelationFilter = {
  every?: InputMaybe<ServiceProviderUnavailablePeriodWhereInput>;
  none?: InputMaybe<ServiceProviderUnavailablePeriodWhereInput>;
  some?: InputMaybe<ServiceProviderUnavailablePeriodWhereInput>;
};

export type ServiceProviderUnavailablePeriodOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  endsAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  offering?: InputMaybe<OfferingOrderByWithRelationInput>;
  offeringId?: InputMaybe<SortOrderInput>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrderInput>;
  serviceProvider?: InputMaybe<ServiceProviderOrderByWithRelationInput>;
  serviceProviderId?: InputMaybe<SortOrder>;
  startsAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ServiceProviderUnavailablePeriodScalarFieldEnum {
  CreatedAt = 'createdAt',
  EndsAt = 'endsAt',
  Id = 'id',
  OfferingId = 'offeringId',
  OrderId = 'orderId',
  ServiceProviderId = 'serviceProviderId',
  StartsAt = 'startsAt',
  UpdatedAt = 'updatedAt'
}

export type ServiceProviderUnavailablePeriodWhereInput = {
  AND?: InputMaybe<Array<ServiceProviderUnavailablePeriodWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderUnavailablePeriodWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderUnavailablePeriodWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  endsAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  offering?: InputMaybe<OfferingRelationFilter>;
  offeringId?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<StringNullableFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  startsAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceProviderUnavailablePeriodWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ServiceProviderUncheckedCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<ServiceProviderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceProviderCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<ServiceProviderUncheckedCreateWithoutUserInput>;
};

export type ServiceProviderUncheckedCreateWithoutBusinessApplicationsInput = {
  address?: InputMaybe<Scalars['JSON']>;
  bankAccount?: InputMaybe<Scalars['JSON']>;
  colorRating?: InputMaybe<ColorRating>;
  countryCode?: InputMaybe<CountryCode>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: InputMaybe<Gender>;
  id: Scalars['ID'];
  identityDocument?: InputMaybe<Scalars['JSON']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  isBankAccountVerified?: InputMaybe<Scalars['Boolean']>;
  mobileNumber: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ServiceProviderStatus>;
  verificationStatus?: InputMaybe<ServiceProviderVerificationStatus>;
};

export type ServiceProviderUncheckedCreateWithoutOrdersInput = {
  address?: InputMaybe<Scalars['JSON']>;
  bankAccount?: InputMaybe<Scalars['JSON']>;
  colorRating?: InputMaybe<ColorRating>;
  countryCode?: InputMaybe<CountryCode>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: InputMaybe<Gender>;
  id: Scalars['ID'];
  identityDocument?: InputMaybe<Scalars['JSON']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  isBankAccountVerified?: InputMaybe<Scalars['Boolean']>;
  mobileNumber: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ServiceProviderStatus>;
  verificationStatus?: InputMaybe<ServiceProviderVerificationStatus>;
};

export type ServiceProviderUncheckedCreateWithoutUserInput = {
  address?: InputMaybe<Scalars['JSON']>;
  bankAccount?: InputMaybe<Scalars['JSON']>;
  colorRating?: InputMaybe<ColorRating>;
  countryCode?: InputMaybe<CountryCode>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: InputMaybe<Gender>;
  identityDocument?: InputMaybe<Scalars['JSON']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  isBankAccountVerified?: InputMaybe<Scalars['Boolean']>;
  mobileNumber: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ServiceProviderStatus>;
  verificationStatus?: InputMaybe<ServiceProviderVerificationStatus>;
};

export type ServiceProviderUncheckedCreateWithoutWithdrawalsInput = {
  address?: InputMaybe<Scalars['JSON']>;
  bankAccount?: InputMaybe<Scalars['JSON']>;
  colorRating?: InputMaybe<ColorRating>;
  countryCode?: InputMaybe<CountryCode>;
  email?: InputMaybe<Scalars['String']>;
  fullName: Scalars['String'];
  gender?: InputMaybe<Gender>;
  id: Scalars['ID'];
  identityDocument?: InputMaybe<Scalars['JSON']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  isBankAccountVerified?: InputMaybe<Scalars['Boolean']>;
  mobileNumber: Scalars['String'];
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ServiceProviderStatus>;
  verificationStatus?: InputMaybe<ServiceProviderVerificationStatus>;
};

export type ServiceProviderUncheckedUpdateInput = {
  address?: InputMaybe<Scalars['JSON']>;
  bankAccount?: InputMaybe<Scalars['JSON']>;
  countryCode?: InputMaybe<CountryCode>;
  email?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  identityDocument?: InputMaybe<Scalars['JSON']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  isBankAccountVerified?: InputMaybe<Scalars['Boolean']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ServiceProviderStatus>;
  verificationStatus?: InputMaybe<ServiceProviderVerificationStatus>;
};

export enum ServiceProviderVerificationStatus {
  Pending = 'Pending',
  Rejected = 'Rejected',
  Submitted = 'Submitted',
  Verified = 'Verified'
}

export type ServiceProviderWhereInput = {
  AND?: InputMaybe<Array<ServiceProviderWhereInput>>;
  NOT?: InputMaybe<Array<ServiceProviderWhereInput>>;
  OR?: InputMaybe<Array<ServiceProviderWhereInput>>;
  address?: InputMaybe<JsonNullableFilter>;
  averageRating?: InputMaybe<DecimalNullableFilter>;
  bankAccount?: InputMaybe<JsonNullableFilter>;
  businessApplications?: InputMaybe<BusinessApplicationListRelationFilter>;
  businesses?: InputMaybe<BusinessListRelationFilter>;
  colorRating?: InputMaybe<EnumColorRatingFilter>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  fullName?: InputMaybe<StringFilter>;
  gender?: InputMaybe<EnumGenderNullableFilter>;
  id?: InputMaybe<StringFilter>;
  identityDocument?: InputMaybe<JsonNullableFilter>;
  identityNumber?: InputMaybe<StringNullableFilter>;
  isBankAccountVerified?: InputMaybe<BoolFilter>;
  mobileNumber?: InputMaybe<StringFilter>;
  ordersCount?: InputMaybe<IntFilter>;
  profilePicture?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumServiceProviderStatusFilter>;
  totalRatingCount?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  verificationStatus?: InputMaybe<EnumServiceProviderVerificationStatusFilter>;
};

export type ServiceProviderWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
};

export type ServiceRelationFilter = {
  is?: InputMaybe<ServiceWhereInput>;
  isNot?: InputMaybe<ServiceWhereInput>;
};

export enum ServiceType {
  Profession = 'Profession',
  Space = 'Space'
}

export type ServiceUncheckedCreateInput = {
  businesses?: InputMaybe<BusinessUncheckedCreateNestedManyWithoutServiceInput>;
  customerPreferences?: InputMaybe<CustomerPreferenceUncheckedCreateNestedManyWithoutServiceInput>;
  description?: InputMaybe<Scalars['String']>;
  filterGroups?: InputMaybe<FacetFilterGroupUncheckedCreateNestedManyWithoutServiceInput>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  offerings?: InputMaybe<OfferingUncheckedCreateNestedManyWithoutServiceInput>;
  professionName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<ServiceType>;
};

export type ServiceUncheckedCreateWithoutBusinessApplicationsInput = {
  businesses?: InputMaybe<BusinessUncheckedCreateNestedManyWithoutServiceInput>;
  customerPreferences?: InputMaybe<CustomerPreferenceUncheckedCreateNestedManyWithoutServiceInput>;
  description?: InputMaybe<Scalars['String']>;
  filterGroups?: InputMaybe<FacetFilterGroupUncheckedCreateNestedManyWithoutServiceInput>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  offerings?: InputMaybe<OfferingUncheckedCreateNestedManyWithoutServiceInput>;
  professionName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<ServiceType>;
};

export type ServiceUncheckedCreateWithoutOfferingsInput = {
  businesses?: InputMaybe<BusinessUncheckedCreateNestedManyWithoutServiceInput>;
  customerPreferences?: InputMaybe<CustomerPreferenceUncheckedCreateNestedManyWithoutServiceInput>;
  description?: InputMaybe<Scalars['String']>;
  filterGroups?: InputMaybe<FacetFilterGroupUncheckedCreateNestedManyWithoutServiceInput>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  professionName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<ServiceType>;
};

export type ServiceUncheckedUpdateInput = {
  businesses?: InputMaybe<BusinessUncheckedUpdateManyWithoutServiceNestedInput>;
  customerPreferences?: InputMaybe<CustomerPreferenceUncheckedUpdateManyWithoutServiceNestedInput>;
  description?: InputMaybe<Scalars['String']>;
  filterGroups?: InputMaybe<FacetFilterGroupUncheckedUpdateManyWithoutServiceNestedInput>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  offerings?: InputMaybe<OfferingUncheckedUpdateManyWithoutServiceNestedInput>;
  professionName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Status>;
  type?: InputMaybe<ServiceType>;
};

export type ServiceWhereInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  businesses?: InputMaybe<BusinessListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerPreferences?: InputMaybe<CustomerPreferenceListRelationFilter>;
  description?: InputMaybe<StringNullableFilter>;
  filterGroups?: InputMaybe<FacetFilterGroupListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  offerings?: InputMaybe<OfferingListRelationFilter>;
  professionName?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  type?: InputMaybe<EnumServiceTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SessionRateSetting = {
  __typename?: 'SessionRateSetting';
  conversionRate: Scalars['Decimal'];
  fromCurrency: Scalars['String'];
  minimumSessionRate: Money;
  toCurrency: Scalars['String'];
};

export type SessionScheduleSetting = {
  __typename?: 'SessionScheduleSetting';
  minimumSessionSlotDurationInMinutes: Scalars['Int'];
  minimumTimeBetweenSessionSlotInMinutes: Scalars['Int'];
};

export type SignedFile = {
  __typename?: 'SignedFile';
  name: Scalars['String'];
  path: Scalars['String'];
  signedUrl: Scalars['String'];
  url: Scalars['String'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export enum Status {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type StatusResponse = {
  __typename?: 'StatusResponse';
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  orderPlaced?: Maybe<Order>;
};

export type SupportingDocument = {
  __typename?: 'SupportingDocument';
  /** File or image urls */
  attachments?: Maybe<Array<Scalars['String']>>;
  urls?: Maybe<Array<Scalars['String']>>;
};

export type SupportingDocumentInput = {
  /** File or image urls */
  attachments?: InputMaybe<Array<Scalars['String']>>;
  urls?: InputMaybe<Array<Scalars['String']>>;
};

export type SystemSetting = {
  __typename?: 'SystemSetting';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  group: SystemSettingGroup;
  id: Scalars['ID'];
  key: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['JSON'];
};

export enum SystemSettingGroup {
  AppSettings = 'AppSettings',
  BookingSettings = 'BookingSettings',
  CurrencySettings = 'CurrencySettings',
  FeeSettings = 'FeeSettings'
}

export type SystemSettingOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  group?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export type SystemSettingUncheckedUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  group?: InputMaybe<SystemSettingGroup>;
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['JSON']>;
};

export type SystemSettingWhereInput = {
  AND?: InputMaybe<Array<SystemSettingWhereInput>>;
  NOT?: InputMaybe<Array<SystemSettingWhereInput>>;
  OR?: InputMaybe<Array<SystemSettingWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  group?: InputMaybe<EnumSystemSettingGroupFilter>;
  id?: InputMaybe<IntFilter>;
  key?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  value?: InputMaybe<JsonFilter>;
};

export type SystemSettingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
};

export type Topup = {
  __typename?: 'Topup';
  _count?: Maybe<TopupCount>;
  amount: Money;
  countryCode: CountryCode;
  createdAt: Scalars['DateTime'];
  currencyCode: Scalars['String'];
  events: Array<TopupEvent>;
  failureCode?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  package?: Maybe<TopupPackage>;
  packageId?: Maybe<Scalars['String']>;
  payments: Array<Payment>;
  status: TopupStatus;
  topupMethod: TopupMethod;
  updatedAt: Scalars['DateTime'];
  wallet: Wallet;
  walletId: Scalars['String'];
};


export type TopupEventsArgs = {
  cursor?: InputMaybe<TopupEventWhereUniqueInput>;
  distinct?: InputMaybe<Array<TopupEventScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TopupEventOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TopupEventWhereInput>;
};


export type TopupPaymentsArgs = {
  cursor?: InputMaybe<PaymentWhereUniqueInput>;
  distinct?: InputMaybe<Array<PaymentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PaymentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PaymentWhereInput>;
};

export type TopupAmountSummary = {
  __typename?: 'TopupAmountSummary';
  discountAmount?: Maybe<Money>;
  subTotalAmount: Money;
  subTotalBreakdown: TopupSubTotalBreakdown;
  totalAmount: Money;
};

export type TopupCount = {
  __typename?: 'TopupCount';
  events: Scalars['Int'];
  payments: Scalars['Int'];
};

export type TopupEvent = {
  __typename?: 'TopupEvent';
  createdAt: Scalars['DateTime'];
  eventType: Scalars['String'];
  id: Scalars['ID'];
  payload: Scalars['JSON'];
  topupId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TopupEventOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  eventType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  payload?: InputMaybe<SortOrder>;
  topup?: InputMaybe<TopupOrderByWithRelationInput>;
  topupId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum TopupEventScalarFieldEnum {
  CreatedAt = 'createdAt',
  EventType = 'eventType',
  Id = 'id',
  Payload = 'payload',
  TopupId = 'topupId',
  UpdatedAt = 'updatedAt'
}

export type TopupEventWhereInput = {
  AND?: InputMaybe<Array<TopupEventWhereInput>>;
  NOT?: InputMaybe<Array<TopupEventWhereInput>>;
  OR?: InputMaybe<Array<TopupEventWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventType?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  payload?: InputMaybe<JsonFilter>;
  topup?: InputMaybe<TopupRelationFilter>;
  topupId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TopupEventWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type TopupListRelationFilter = {
  every?: InputMaybe<TopupWhereInput>;
  none?: InputMaybe<TopupWhereInput>;
  some?: InputMaybe<TopupWhereInput>;
};

export enum TopupMethod {
  PaymentGateway = 'PaymentGateway'
}

export type TopupOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TopupOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  failureCode?: InputMaybe<SortOrderInput>;
  failureMessage?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrderInput>;
  package?: InputMaybe<TopupPackageOrderByWithRelationInput>;
  packageId?: InputMaybe<SortOrderInput>;
  payments?: InputMaybe<PaymentOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  topupMethod?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  wallet?: InputMaybe<WalletOrderByWithRelationInput>;
  walletId?: InputMaybe<SortOrder>;
};

export type TopupPackage = {
  __typename?: 'TopupPackage';
  _count?: Maybe<TopupPackageCount>;
  amount?: Maybe<Money>;
  countryCode: CountryCode;
  createdAt: Scalars['DateTime'];
  currencyCode: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  inAppAmount?: Maybe<Money>;
  inAppCurrencyCode: Scalars['String'];
  isRecommended: Scalars['Boolean'];
  originalAmount?: Maybe<Money>;
  status: Status;
  updatedAt: Scalars['DateTime'];
};

export type TopupPackageCount = {
  __typename?: 'TopupPackageCount';
  topups: Scalars['Int'];
};

export type TopupPackageCreateInputV2 = {
  amount?: InputMaybe<Scalars['Decimal']>;
  inAppAmount?: InputMaybe<Scalars['Decimal']>;
  isRecommended?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Status>;
};

export type TopupPackageOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  discountAmount?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  inAppAmount?: InputMaybe<SortOrder>;
  inAppCurrencyCode?: InputMaybe<SortOrder>;
  isRecommended?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TopupPackageRelationFilter = {
  is?: InputMaybe<TopupPackageWhereInput>;
  isNot?: InputMaybe<TopupPackageWhereInput>;
};

export type TopupPackageUpdateInputV2 = {
  amount?: InputMaybe<Scalars['Decimal']>;
  inAppAmount?: InputMaybe<Scalars['Decimal']>;
  isRecommended?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Status>;
};

export type TopupPackageWhereInput = {
  AND?: InputMaybe<Array<TopupPackageWhereInput>>;
  NOT?: InputMaybe<Array<TopupPackageWhereInput>>;
  OR?: InputMaybe<Array<TopupPackageWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  discountAmount?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inAppAmount?: InputMaybe<DecimalFilter>;
  inAppCurrencyCode?: InputMaybe<StringFilter>;
  isRecommended?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TopupPackageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type TopupRelationFilter = {
  is?: InputMaybe<TopupWhereInput>;
  isNot?: InputMaybe<TopupWhereInput>;
};

export enum TopupScalarFieldEnum {
  Amount = 'amount',
  CountryCode = 'countryCode',
  CreatedAt = 'createdAt',
  CurrencyCode = 'currencyCode',
  FailureCode = 'failureCode',
  FailureMessage = 'failureMessage',
  Id = 'id',
  Metadata = 'metadata',
  PackageId = 'packageId',
  Status = 'status',
  TopupMethod = 'topupMethod',
  UpdatedAt = 'updatedAt',
  WalletId = 'walletId'
}

export type TopupSetting = {
  __typename?: 'TopupSetting';
  enabled: Scalars['Boolean'];
  processingFeeInPercentage: Scalars['Decimal'];
};

export enum TopupStatus {
  Expired = 'Expired',
  Failed = 'Failed',
  Pending = 'Pending',
  Reversed = 'Reversed',
  Succeeded = 'Succeeded'
}

export type TopupSubTotalBreakdown = {
  __typename?: 'TopupSubTotalBreakdown';
  packageAmount: Money;
  processingFee: Money;
};

export type TopupWhereInput = {
  AND?: InputMaybe<Array<TopupWhereInput>>;
  NOT?: InputMaybe<Array<TopupWhereInput>>;
  OR?: InputMaybe<Array<TopupWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  failureCode?: InputMaybe<StringNullableFilter>;
  failureMessage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  package?: InputMaybe<TopupPackageRelationFilter>;
  packageId?: InputMaybe<StringNullableFilter>;
  payments?: InputMaybe<PaymentListRelationFilter>;
  status?: InputMaybe<EnumTopupStatusFilter>;
  topupMethod?: InputMaybe<EnumTopupMethodFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wallet?: InputMaybe<WalletRelationFilter>;
  walletId?: InputMaybe<StringFilter>;
};

export type TopupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  amount: Money;
  balanceType: BalanceType;
  countryCode: CountryCode;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  descriptionDetail?: Maybe<Scalars['String']>;
  endingBalance?: Maybe<Scalars['Decimal']>;
  id: Scalars['ID'];
  metadata?: Maybe<Scalars['JSON']>;
  metafields?: Maybe<Array<Metafield>>;
  notes?: Maybe<Scalars['JSON']>;
  referenceId?: Maybe<Scalars['String']>;
  referenceType?: Maybe<Scalars['String']>;
  status: TransactionStatus;
  type: TransactionType;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  walletId?: Maybe<Scalars['String']>;
};

export type TransactionOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  balanceType?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  endingBalance?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrderInput>;
  notes?: InputMaybe<SortOrderInput>;
  referenceId?: InputMaybe<SortOrderInput>;
  referenceType?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  walletId?: InputMaybe<SortOrderInput>;
};

export enum TransactionStatus {
  Failed = 'Failed',
  Pending = 'Pending',
  Succeeded = 'Succeeded'
}

export enum TransactionType {
  Adjustment = 'Adjustment',
  AdminFee = 'AdminFee',
  Earning = 'Earning',
  Payment = 'Payment',
  Penalty = 'Penalty',
  Refund = 'Refund',
  Topup = 'Topup',
  Withdrawal = 'Withdrawal'
}

export type TransactionWhereInput = {
  AND?: InputMaybe<Array<TransactionWhereInput>>;
  NOT?: InputMaybe<Array<TransactionWhereInput>>;
  OR?: InputMaybe<Array<TransactionWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  balanceType?: InputMaybe<EnumBalanceTypeFilter>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  endingBalance?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  notes?: InputMaybe<JsonNullableFilter>;
  referenceId?: InputMaybe<StringNullableFilter>;
  referenceType?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumTransactionStatusFilter>;
  type?: InputMaybe<EnumTransactionTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  walletId?: InputMaybe<StringNullableFilter>;
};

export type TransactionWhereInputV2 = {
  AND?: InputMaybe<Array<TransactionWhereInput>>;
  NOT?: InputMaybe<Array<TransactionWhereInput>>;
  OR?: InputMaybe<Array<TransactionWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  balanceType?: InputMaybe<EnumBalanceTypeFilter>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  customerId?: InputMaybe<StringFilter>;
  endingBalance?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  notes?: InputMaybe<JsonNullableFilter>;
  referenceId?: InputMaybe<StringNullableFilter>;
  referenceType?: InputMaybe<StringNullableFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumTransactionStatusFilter>;
  type?: InputMaybe<EnumTransactionTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  walletId?: InputMaybe<StringNullableFilter>;
};

export type Urls = {
  __typename?: 'Urls';
  aboutUs: Scalars['String'];
  appInvitation: Scalars['String'];
  commitmentLevelLearnMore: Scalars['String'];
  faq: Scalars['String'];
  howToUse: Scalars['String'];
  privacyPolicy: Scalars['String'];
  termsOfUse: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  mainRole?: Maybe<Scalars['String']>;
  secondaryUsername?: Maybe<Scalars['String']>;
  status: UserStatus;
  type: UserType;
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};

export type UserCount = {
  __typename?: 'UserCount';
  accounts: Scalars['Int'];
  blockedBy: Scalars['Int'];
  blockedUsers: Scalars['Int'];
  notifications: Scalars['Int'];
};

export type UserCreateNestedOneWithoutAdminInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAdminInput>;
  create?: InputMaybe<UserUncheckedCreateWithoutAdminInput>;
};

export type UserCreateNestedOneWithoutBlockedByInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBlockedByInput>;
  create?: InputMaybe<UserUncheckedCreateWithoutBlockedByInput>;
};

export type UserCreateNestedOneWithoutBlockedUsersInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBlockedUsersInput>;
  create?: InputMaybe<UserUncheckedCreateWithoutBlockedUsersInput>;
};

export type UserCreateOrConnectWithoutAdminInput = {
  create: UserUncheckedCreateWithoutAdminInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutBlockedByInput = {
  create: UserUncheckedCreateWithoutBlockedByInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutBlockedUsersInput = {
  create: UserUncheckedCreateWithoutBlockedUsersInput;
  where: UserWhereUniqueInput;
};

export type UserNotificationCreateManyUserInput = {
  actionText?: InputMaybe<Scalars['String']>;
  actionUrl?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  customAttributes?: InputMaybe<Scalars['JSON']>;
  iconType: UserNotificationIconType;
  id?: InputMaybe<Scalars['ID']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  overrides?: InputMaybe<Scalars['JSON']>;
  recipient?: InputMaybe<Scalars['JSON']>;
  seenAt?: InputMaybe<Scalars['DateTime']>;
  sentAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  type: NotificationType;
};

export type UserNotificationCreateManyUserInputEnvelope = {
  data: Array<UserNotificationCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserNotificationCreateOrConnectWithoutUserInput = {
  create: UserNotificationUncheckedCreateWithoutUserInput;
  where: UserNotificationWhereUniqueInput;
};

export type UserNotificationCreateWithoutUserInput = {
  actionText?: InputMaybe<Scalars['String']>;
  actionUrl?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  customAttributes?: InputMaybe<Scalars['JSON']>;
  iconType: UserNotificationIconType;
  id?: InputMaybe<Scalars['ID']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  overrides?: InputMaybe<Scalars['JSON']>;
  recipient?: InputMaybe<Scalars['JSON']>;
  seenAt?: InputMaybe<Scalars['DateTime']>;
  sentAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  type: NotificationType;
};

export enum UserNotificationIconType {
  CollaborativeMusician = 'CollaborativeMusician',
  Default = 'Default',
  MusicEducation = 'MusicEducation',
  SpaceBooking = 'SpaceBooking'
}

export type UserNotificationListRelationFilter = {
  every?: InputMaybe<UserNotificationWhereInput>;
  none?: InputMaybe<UserNotificationWhereInput>;
  some?: InputMaybe<UserNotificationWhereInput>;
};

export type UserNotificationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserNotificationUncheckedCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserNotificationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserNotificationCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserNotificationCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserNotificationCreateManyUserInputEnvelope>;
};

export type UserNotificationUncheckedCreateWithoutUserInput = {
  actionText?: InputMaybe<Scalars['String']>;
  actionUrl?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  customAttributes?: InputMaybe<Scalars['JSON']>;
  iconType: UserNotificationIconType;
  id?: InputMaybe<Scalars['ID']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  overrides?: InputMaybe<Scalars['JSON']>;
  recipient?: InputMaybe<Scalars['JSON']>;
  seenAt?: InputMaybe<Scalars['DateTime']>;
  sentAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  type: NotificationType;
};

export type UserNotificationWhereInput = {
  AND?: InputMaybe<Array<UserNotificationWhereInput>>;
  NOT?: InputMaybe<Array<UserNotificationWhereInput>>;
  OR?: InputMaybe<Array<UserNotificationWhereInput>>;
  actionText?: InputMaybe<StringNullableFilter>;
  actionUrl?: InputMaybe<StringNullableFilter>;
  content?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customAttributes?: InputMaybe<JsonNullableFilter>;
  iconType?: InputMaybe<EnumUserNotificationIconTypeFilter>;
  id?: InputMaybe<StringFilter>;
  imageUrl?: InputMaybe<StringNullableFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  overrides?: InputMaybe<JsonNullableFilter>;
  recipient?: InputMaybe<JsonNullableFilter>;
  seenAt?: InputMaybe<DateTimeNullableFilter>;
  sentAt?: InputMaybe<DateTimeNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumNotificationTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type UserNotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type UserOrderByWithRelationInput = {
  accounts?: InputMaybe<AccountOrderByRelationAggregateInput>;
  admin?: InputMaybe<AdminUserOrderByWithRelationInput>;
  blockedBy?: InputMaybe<BlockedUserOrderByRelationAggregateInput>;
  blockedUsers?: InputMaybe<BlockedUserOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<CustomerOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  mainRole?: InputMaybe<SortOrderInput>;
  notifications?: InputMaybe<UserNotificationOrderByRelationAggregateInput>;
  password?: InputMaybe<SortOrderInput>;
  secondaryUsername?: InputMaybe<SortOrderInput>;
  serviceProvider?: InputMaybe<ServiceProviderOrderByWithRelationInput>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum UserType {
  Admin = 'Admin',
  Customer = 'Customer'
}

export type UserTypeUsernameSecondaryUsernameCompoundUniqueInput = {
  secondaryUsername: Scalars['String'];
  type: UserType;
  username: Scalars['String'];
};

export type UserUncheckedCreateWithoutAdminInput = {
  accounts?: InputMaybe<AccountUncheckedCreateNestedManyWithoutUserInput>;
  blockedBy?: InputMaybe<BlockedUserUncheckedCreateNestedManyWithoutUserInput>;
  blockedUsers?: InputMaybe<BlockedUserUncheckedCreateNestedManyWithoutBlockedUserInput>;
  customer?: InputMaybe<CustomerUncheckedCreateNestedOneWithoutUserInput>;
  id?: InputMaybe<Scalars['ID']>;
  mainRole?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<UserNotificationUncheckedCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  secondaryUsername?: InputMaybe<Scalars['String']>;
  serviceProvider?: InputMaybe<ServiceProviderUncheckedCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<UserStatus>;
  type: UserType;
  username: Scalars['String'];
};

export type UserUncheckedCreateWithoutBlockedByInput = {
  accounts?: InputMaybe<AccountUncheckedCreateNestedManyWithoutUserInput>;
  admin?: InputMaybe<AdminUserUncheckedCreateNestedOneWithoutUserInput>;
  blockedUsers?: InputMaybe<BlockedUserUncheckedCreateNestedManyWithoutBlockedUserInput>;
  customer?: InputMaybe<CustomerUncheckedCreateNestedOneWithoutUserInput>;
  id?: InputMaybe<Scalars['ID']>;
  mainRole?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<UserNotificationUncheckedCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  secondaryUsername?: InputMaybe<Scalars['String']>;
  serviceProvider?: InputMaybe<ServiceProviderUncheckedCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<UserStatus>;
  type: UserType;
  username: Scalars['String'];
};

export type UserUncheckedCreateWithoutBlockedUsersInput = {
  accounts?: InputMaybe<AccountUncheckedCreateNestedManyWithoutUserInput>;
  admin?: InputMaybe<AdminUserUncheckedCreateNestedOneWithoutUserInput>;
  blockedBy?: InputMaybe<BlockedUserUncheckedCreateNestedManyWithoutUserInput>;
  customer?: InputMaybe<CustomerUncheckedCreateNestedOneWithoutUserInput>;
  id?: InputMaybe<Scalars['ID']>;
  mainRole?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<UserNotificationUncheckedCreateNestedManyWithoutUserInput>;
  password?: InputMaybe<Scalars['String']>;
  secondaryUsername?: InputMaybe<Scalars['String']>;
  serviceProvider?: InputMaybe<ServiceProviderUncheckedCreateNestedOneWithoutUserInput>;
  status?: InputMaybe<UserStatus>;
  type: UserType;
  username: Scalars['String'];
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  accounts?: InputMaybe<AccountListRelationFilter>;
  admin?: InputMaybe<AdminUserRelationFilter>;
  blockedBy?: InputMaybe<BlockedUserListRelationFilter>;
  blockedUsers?: InputMaybe<BlockedUserListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerRelationFilter>;
  id?: InputMaybe<StringFilter>;
  mainRole?: InputMaybe<StringNullableFilter>;
  notifications?: InputMaybe<UserNotificationListRelationFilter>;
  password?: InputMaybe<StringNullableFilter>;
  secondaryUsername?: InputMaybe<StringNullableFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  status?: InputMaybe<EnumUserStatusFilter>;
  type?: InputMaybe<EnumUserTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringFilter>;
};

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  type_username_secondaryUsername?: InputMaybe<UserTypeUsernameSecondaryUsernameCompoundUniqueInput>;
};

export type VerifyOtpInput = {
  challengeId: Scalars['String'];
  code: Scalars['String'];
};

export type Wallet = {
  __typename?: 'Wallet';
  _count?: Maybe<WalletCount>;
  availableBalance: Money;
  balance: Money;
  countryCode: CountryCode;
  createdAt: Scalars['DateTime'];
  currencyCode: Scalars['String'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderId?: Maybe<Scalars['String']>;
  topups: Array<Topup>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
  withdrawals: Array<Withdrawal>;
};


export type WalletTopupsArgs = {
  cursor?: InputMaybe<TopupWhereUniqueInput>;
  distinct?: InputMaybe<Array<TopupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TopupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TopupWhereInput>;
};


export type WalletWithdrawalsArgs = {
  cursor?: InputMaybe<WithdrawalWhereUniqueInput>;
  distinct?: InputMaybe<Array<WithdrawalScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WithdrawalOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WithdrawalWhereInput>;
};

export type WalletCount = {
  __typename?: 'WalletCount';
  topups: Scalars['Int'];
  withdrawals: Scalars['Int'];
};

export type WalletCreateManyCustomerInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode: Scalars['String'];
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  userId: Scalars['String'];
};

export type WalletCreateManyCustomerInputEnvelope = {
  data: Array<WalletCreateManyCustomerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type WalletCreateNestedOneWithoutWithdrawalsInput = {
  connect?: InputMaybe<WalletWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WalletCreateOrConnectWithoutWithdrawalsInput>;
  create?: InputMaybe<WalletUncheckedCreateWithoutWithdrawalsInput>;
};

export type WalletCreateOrConnectWithoutCustomerInput = {
  create: WalletUncheckedCreateWithoutCustomerInput;
  where: WalletWhereUniqueInput;
};

export type WalletCreateOrConnectWithoutWithdrawalsInput = {
  create: WalletUncheckedCreateWithoutWithdrawalsInput;
  where: WalletWhereUniqueInput;
};

export type WalletCreateWithoutCustomerInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode: Scalars['String'];
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  userId: Scalars['String'];
};

export type WalletListRelationFilter = {
  every?: InputMaybe<WalletWhereInput>;
  none?: InputMaybe<WalletWhereInput>;
  some?: InputMaybe<WalletWhereInput>;
};

export type WalletOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type WalletOrderByWithRelationInput = {
  availableBalance?: InputMaybe<SortOrder>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currencyCode?: InputMaybe<SortOrder>;
  holdBalance?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  topups?: InputMaybe<TopupOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
  withdrawals?: InputMaybe<WithdrawalOrderByRelationAggregateInput>;
};

export type WalletRelationFilter = {
  is?: InputMaybe<WalletWhereInput>;
  isNot?: InputMaybe<WalletWhereInput>;
};

export enum WalletScalarFieldEnum {
  AvailableBalance = 'availableBalance',
  CountryCode = 'countryCode',
  CreatedAt = 'createdAt',
  CurrencyCode = 'currencyCode',
  CustomerId = 'customerId',
  HoldBalance = 'holdBalance',
  Id = 'id',
  ServiceProviderId = 'serviceProviderId',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type WalletScalarWhereInput = {
  AND?: InputMaybe<Array<WalletScalarWhereInput>>;
  NOT?: InputMaybe<Array<WalletScalarWhereInput>>;
  OR?: InputMaybe<Array<WalletScalarWhereInput>>;
  availableBalance?: InputMaybe<DecimalFilter>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  holdBalance?: InputMaybe<DecimalFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type WalletUncheckedCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<WalletWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WalletCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<WalletCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<WalletCreateManyCustomerInputEnvelope>;
};

export type WalletUncheckedCreateWithoutCustomerInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode: Scalars['String'];
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  userId: Scalars['String'];
};

export type WalletUncheckedCreateWithoutWithdrawalsInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode: Scalars['String'];
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  userId: Scalars['String'];
};

export type WalletUncheckedUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<WalletWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WalletCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<WalletCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<WalletCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<WalletWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WalletScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WalletWhereUniqueInput>>;
  set?: InputMaybe<Array<WalletWhereUniqueInput>>;
  update?: InputMaybe<Array<WalletUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<Array<WalletUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: InputMaybe<Array<WalletUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type WalletUncheckedUpdateManyWithoutWalletsInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode?: InputMaybe<Scalars['String']>;
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type WalletUncheckedUpdateWithoutCustomerInput = {
  availableBalance?: InputMaybe<Scalars['Decimal']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode?: InputMaybe<Scalars['String']>;
  holdBalance?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type WalletUpdateManyWithWhereWithoutCustomerInput = {
  data: WalletUncheckedUpdateManyWithoutWalletsInput;
  where: WalletScalarWhereInput;
};

export type WalletUpdateWithWhereUniqueWithoutCustomerInput = {
  data: WalletUncheckedUpdateWithoutCustomerInput;
  where: WalletWhereUniqueInput;
};

export type WalletUpsertWithWhereUniqueWithoutCustomerInput = {
  create: WalletUncheckedCreateWithoutCustomerInput;
  update: WalletUncheckedUpdateWithoutCustomerInput;
  where: WalletWhereUniqueInput;
};

export type WalletUserIdCurrencyCodeCustomerIdCountryCodeCompoundUniqueInput = {
  countryCode: CountryCode;
  currencyCode: Scalars['String'];
  customerId: Scalars['String'];
  userId: Scalars['String'];
};

export type WalletUserIdCurrencyCodeServiceProviderIdCountryCodeCompoundUniqueInput = {
  countryCode: CountryCode;
  currencyCode: Scalars['String'];
  serviceProviderId: Scalars['String'];
  userId: Scalars['String'];
};

export type WalletWhereInput = {
  AND?: InputMaybe<Array<WalletWhereInput>>;
  NOT?: InputMaybe<Array<WalletWhereInput>>;
  OR?: InputMaybe<Array<WalletWhereInput>>;
  availableBalance?: InputMaybe<DecimalFilter>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  holdBalance?: InputMaybe<DecimalFilter>;
  id?: InputMaybe<StringFilter>;
  topups?: InputMaybe<TopupListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
  withdrawals?: InputMaybe<WithdrawalListRelationFilter>;
};

export type WalletWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  userId_currencyCode_customerId_countryCode?: InputMaybe<WalletUserIdCurrencyCodeCustomerIdCountryCodeCompoundUniqueInput>;
  userId_currencyCode_serviceProviderId_countryCode?: InputMaybe<WalletUserIdCurrencyCodeServiceProviderIdCountryCodeCompoundUniqueInput>;
};

export type Withdrawal = {
  __typename?: 'Withdrawal';
  amount: Money;
  bankAccount: BankAccount;
  countryCode: CountryCode;
  createdAt: Scalars['DateTime'];
  currencyCode: Scalars['String'];
  handlingAmount: Money;
  id: Scalars['ID'];
  netAmount: Money;
  payoutId?: Maybe<Scalars['String']>;
  serviceProvider: ServiceProvider;
  serviceProviderId: Scalars['String'];
  status: WithdrawalStatus;
  type: WithdrawalType;
  updatedAt: Scalars['DateTime'];
  wallet: Wallet;
  walletId: Scalars['String'];
};

export type WithdrawalCreateManyApproverInput = {
  amount: Scalars['Decimal'];
  approverRemarks?: InputMaybe<Scalars['JSON']>;
  bankAccount: Scalars['JSON'];
  countryCode?: InputMaybe<CountryCode>;
  currencyCode: Scalars['String'];
  handlingAmount?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  netAmount: Scalars['Decimal'];
  payoutId?: InputMaybe<Scalars['String']>;
  serviceProviderId: Scalars['String'];
  status?: InputMaybe<WithdrawalStatus>;
  type?: InputMaybe<WithdrawalType>;
  walletId: Scalars['String'];
};

export type WithdrawalCreateManyApproverInputEnvelope = {
  data: Array<WithdrawalCreateManyApproverInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type WithdrawalCreateNestedManyWithoutApproverInput = {
  connect?: InputMaybe<Array<WithdrawalWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WithdrawalCreateOrConnectWithoutApproverInput>>;
  create?: InputMaybe<Array<WithdrawalCreateWithoutApproverInput>>;
  createMany?: InputMaybe<WithdrawalCreateManyApproverInputEnvelope>;
};

export type WithdrawalCreateOrConnectWithoutApproverInput = {
  create: WithdrawalUncheckedCreateWithoutApproverInput;
  where: WithdrawalWhereUniqueInput;
};

export type WithdrawalCreateWithoutApproverInput = {
  amount: Scalars['Decimal'];
  approverRemarks?: InputMaybe<Scalars['JSON']>;
  bankAccount: Scalars['JSON'];
  countryCode?: InputMaybe<CountryCode>;
  currencyCode: Scalars['String'];
  handlingAmount?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  netAmount: Scalars['Decimal'];
  serviceProvider: ServiceProviderCreateNestedOneWithoutWithdrawalsInput;
  status?: InputMaybe<WithdrawalStatus>;
  type?: InputMaybe<WithdrawalType>;
  wallet: WalletCreateNestedOneWithoutWithdrawalsInput;
};

export type WithdrawalListRelationFilter = {
  every?: InputMaybe<WithdrawalWhereInput>;
  none?: InputMaybe<WithdrawalWhereInput>;
  some?: InputMaybe<WithdrawalWhereInput>;
};

export type WithdrawalOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type WithdrawalOrderByWithRelationInput = {
  amount?: InputMaybe<SortOrder>;
  approver?: InputMaybe<AdminUserOrderByWithRelationInput>;
  approverId?: InputMaybe<SortOrderInput>;
  approverRemarks?: InputMaybe<SortOrderInput>;
  countryCode?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  handlingAmount?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  netAmount?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  wallet?: InputMaybe<WalletOrderByWithRelationInput>;
  walletId?: InputMaybe<SortOrder>;
};

export enum WithdrawalScalarFieldEnum {
  Amount = 'amount',
  ApproverId = 'approverId',
  ApproverRemarks = 'approverRemarks',
  BankAccount = 'bankAccount',
  CountryCode = 'countryCode',
  CreatedAt = 'createdAt',
  CurrencyCode = 'currencyCode',
  HandlingAmount = 'handlingAmount',
  Id = 'id',
  NetAmount = 'netAmount',
  PayoutId = 'payoutId',
  ServiceProviderId = 'serviceProviderId',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  WalletId = 'walletId'
}

export type WithdrawalScalarWhereInput = {
  AND?: InputMaybe<Array<WithdrawalScalarWhereInput>>;
  NOT?: InputMaybe<Array<WithdrawalScalarWhereInput>>;
  OR?: InputMaybe<Array<WithdrawalScalarWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  approverId?: InputMaybe<StringNullableFilter>;
  approverRemarks?: InputMaybe<JsonNullableFilter>;
  bankAccount?: InputMaybe<JsonFilter>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  handlingAmount?: InputMaybe<DecimalFilter>;
  id?: InputMaybe<StringFilter>;
  netAmount?: InputMaybe<DecimalFilter>;
  payoutId?: InputMaybe<StringNullableFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumWithdrawalStatusFilter>;
  type?: InputMaybe<EnumWithdrawalTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  walletId?: InputMaybe<StringFilter>;
};

export enum WithdrawalStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending',
  Reversed = 'Reversed'
}

export enum WithdrawalType {
  Manual = 'Manual',
  Payout = 'Payout'
}

export type WithdrawalUncheckedCreateNestedManyWithoutApproverInput = {
  connect?: InputMaybe<Array<WithdrawalWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WithdrawalCreateOrConnectWithoutApproverInput>>;
  create?: InputMaybe<Array<WithdrawalCreateWithoutApproverInput>>;
  createMany?: InputMaybe<WithdrawalCreateManyApproverInputEnvelope>;
};

export type WithdrawalUncheckedCreateWithoutApproverInput = {
  amount: Scalars['Decimal'];
  approverRemarks?: InputMaybe<Scalars['JSON']>;
  bankAccount: Scalars['JSON'];
  countryCode?: InputMaybe<CountryCode>;
  currencyCode: Scalars['String'];
  handlingAmount?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  netAmount: Scalars['Decimal'];
  payoutId?: InputMaybe<Scalars['String']>;
  serviceProviderId: Scalars['String'];
  status?: InputMaybe<WithdrawalStatus>;
  type?: InputMaybe<WithdrawalType>;
  walletId: Scalars['String'];
};

export type WithdrawalUncheckedUpdateManyWithoutApproverNestedInput = {
  connect?: InputMaybe<Array<WithdrawalWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WithdrawalCreateOrConnectWithoutApproverInput>>;
  create?: InputMaybe<Array<WithdrawalCreateWithoutApproverInput>>;
  createMany?: InputMaybe<WithdrawalCreateManyApproverInputEnvelope>;
  delete?: InputMaybe<Array<WithdrawalWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WithdrawalScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WithdrawalWhereUniqueInput>>;
  set?: InputMaybe<Array<WithdrawalWhereUniqueInput>>;
  update?: InputMaybe<Array<WithdrawalUpdateWithWhereUniqueWithoutApproverInput>>;
  updateMany?: InputMaybe<Array<WithdrawalUpdateManyWithWhereWithoutApproverInput>>;
  upsert?: InputMaybe<Array<WithdrawalUpsertWithWhereUniqueWithoutApproverInput>>;
};

export type WithdrawalUncheckedUpdateManyWithoutWithdrawalsInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  approverRemarks?: InputMaybe<Scalars['JSON']>;
  bankAccount?: InputMaybe<Scalars['JSON']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode?: InputMaybe<Scalars['String']>;
  handlingAmount?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  netAmount?: InputMaybe<Scalars['Decimal']>;
  payoutId?: InputMaybe<Scalars['String']>;
  serviceProviderId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<WithdrawalStatus>;
  type?: InputMaybe<WithdrawalType>;
  walletId?: InputMaybe<Scalars['String']>;
};

export type WithdrawalUncheckedUpdateWithoutApproverInput = {
  amount?: InputMaybe<Scalars['Decimal']>;
  approverRemarks?: InputMaybe<Scalars['JSON']>;
  bankAccount?: InputMaybe<Scalars['JSON']>;
  countryCode?: InputMaybe<CountryCode>;
  currencyCode?: InputMaybe<Scalars['String']>;
  handlingAmount?: InputMaybe<Scalars['Decimal']>;
  id?: InputMaybe<Scalars['ID']>;
  netAmount?: InputMaybe<Scalars['Decimal']>;
  payoutId?: InputMaybe<Scalars['String']>;
  serviceProviderId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<WithdrawalStatus>;
  type?: InputMaybe<WithdrawalType>;
  walletId?: InputMaybe<Scalars['String']>;
};

export type WithdrawalUpdateInputV2 = {
  rejectReason?: InputMaybe<Scalars['String']>;
  status: WithdrawalStatus;
};

export type WithdrawalUpdateManyWithWhereWithoutApproverInput = {
  data: WithdrawalUncheckedUpdateManyWithoutWithdrawalsInput;
  where: WithdrawalScalarWhereInput;
};

export type WithdrawalUpdateWithWhereUniqueWithoutApproverInput = {
  data: WithdrawalUncheckedUpdateWithoutApproverInput;
  where: WithdrawalWhereUniqueInput;
};

export type WithdrawalUpsertWithWhereUniqueWithoutApproverInput = {
  create: WithdrawalUncheckedCreateWithoutApproverInput;
  update: WithdrawalUncheckedUpdateWithoutApproverInput;
  where: WithdrawalWhereUniqueInput;
};

export type WithdrawalWhereInput = {
  AND?: InputMaybe<Array<WithdrawalWhereInput>>;
  NOT?: InputMaybe<Array<WithdrawalWhereInput>>;
  OR?: InputMaybe<Array<WithdrawalWhereInput>>;
  amount?: InputMaybe<DecimalFilter>;
  approver?: InputMaybe<AdminUserRelationFilter>;
  approverId?: InputMaybe<StringNullableFilter>;
  approverRemarks?: InputMaybe<JsonNullableFilter>;
  bankAccount?: InputMaybe<JsonFilter>;
  countryCode?: InputMaybe<EnumCountryCodeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currencyCode?: InputMaybe<StringFilter>;
  handlingAmount?: InputMaybe<DecimalFilter>;
  id?: InputMaybe<StringFilter>;
  netAmount?: InputMaybe<DecimalFilter>;
  payoutId?: InputMaybe<StringNullableFilter>;
  serviceProvider?: InputMaybe<ServiceProviderRelationFilter>;
  serviceProviderId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumWithdrawalStatusFilter>;
  type?: InputMaybe<EnumWithdrawalTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  wallet?: InputMaybe<WalletRelationFilter>;
  walletId?: InputMaybe<StringFilter>;
};

export type WithdrawalWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Workspace = {
  __typename?: 'Workspace';
  /** Workspace images urls */
  attachments: Array<Scalars['String']>;
  businessProfile: WorkspaceBusinessProfile;
  capacity: WorkspaceCapacity;
  /** Amenities & Facilities */
  facilities?: Maybe<Array<Scalars['String']>>;
  owner: WorkspaceOwner;
};

export type WorkspaceBusinessProfile = {
  __typename?: 'WorkspaceBusinessProfile';
  address?: Maybe<Scalars['String']>;
  entityName: Scalars['String'];
  entityNumber?: Maybe<Scalars['String']>;
  workspaceName: Scalars['String'];
};

export type WorkspaceBusinessProfileInput = {
  address?: InputMaybe<Scalars['String']>;
  entityName: Scalars['String'];
  entityNumber?: InputMaybe<Scalars['String']>;
  workspaceName: Scalars['String'];
};

export type WorkspaceCapacity = {
  __typename?: 'WorkspaceCapacity';
  estimatedAudiences: Scalars['Int'];
  estimatedPerformers: Scalars['Int'];
};

export type WorkspaceCapacityInput = {
  estimatedAudiences?: Scalars['Int'];
  estimatedPerformers?: Scalars['Int'];
};

export type WorkspaceInput = {
  /** Workspace images urls */
  attachments: Array<Scalars['String']>;
  businessProfile: WorkspaceBusinessProfileInput;
  capacity: WorkspaceCapacityInput;
  /** Amenities & Facilities */
  facilities?: InputMaybe<Array<Scalars['String']>>;
  owner: WorkspaceOwnerInput;
};

export type WorkspaceOwner = {
  __typename?: 'WorkspaceOwner';
  email?: Maybe<Scalars['String']>;
  identityNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type WorkspaceOwnerInput = {
  email?: InputMaybe<Scalars['String']>;
  identityNumber?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type AdminLoginMutationVariables = Exact<{
  data: AdminLoginInput;
}>;


export type AdminLoginMutation = { __typename?: 'Mutation', adminLogin: { __typename: 'Auth', accessToken: string, refreshToken: string, refreshExpiresIn: number, chatToken?: string | null } };

export type CreateAdminUserMutationVariables = Exact<{
  data: AdminUserCreateInputV2;
}>;


export type CreateAdminUserMutation = { __typename?: 'Mutation', createOneAdminUser: { __typename?: 'AdminUser', id: string } };

export type CreateTopupPackageMutationVariables = Exact<{
  data: TopupPackageCreateInputV2;
}>;


export type CreateTopupPackageMutation = { __typename?: 'Mutation', createTopupPackage: { __typename?: 'TopupPackage', id: string } };

export type GetDashboardEmbedUrlQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetDashboardEmbedUrlQuery = { __typename?: 'Query', getDashboardEmbedUrl: { __typename?: 'DashboardConfiguration', id: string, iframeUrl: string } };

export type RequestOtpMutationVariables = Exact<{
  data: RequestOtpInput;
}>;


export type RequestOtpMutation = { __typename?: 'Mutation', requestOtp: { __typename?: 'OtpChallenge', id: string, retryWaitSeconds: number, consumer: string } };

export type UpdateAdminUserMutationVariables = Exact<{
  data: AdminUserUpdateInputV2;
  where: AdminUserWhereUniqueInput;
}>;


export type UpdateAdminUserMutation = { __typename?: 'Mutation', updateOneAdminUser?: { __typename?: 'AdminUser', id: string } | null };

export type UpdateBookingSettingsMutationVariables = Exact<{
  data: BookingSettingsInput;
}>;


export type UpdateBookingSettingsMutation = { __typename?: 'Mutation', updateBookingSettings?: Array<{ __typename?: 'SystemSetting', id: string, group: SystemSettingGroup, key: string, value: any, description?: string | null, createdAt: any, updatedAt: any }> | null };

export type UpdateFeeSettingsMutationVariables = Exact<{
  data: FeeSettingsInput;
}>;


export type UpdateFeeSettingsMutation = { __typename?: 'Mutation', updateFeeSettings?: Array<{ __typename?: 'SystemSetting', id: string, group: SystemSettingGroup, key: string, value: any, description?: string | null, createdAt: any, updatedAt: any }> | null };

export type UpdateTopupPackageMutationVariables = Exact<{
  data: TopupPackageUpdateInputV2;
  where: TopupPackageWhereUniqueInput;
}>;


export type UpdateTopupPackageMutation = { __typename?: 'Mutation', updateTopupPackage: { __typename?: 'TopupPackage', id: string } };


export const AdminLoginDocument = gql`
    mutation AdminLogin($data: AdminLoginInput!) {
  adminLogin(data: $data) {
    accessToken
    refreshToken
    refreshExpiresIn
    chatToken
    __typename
  }
}
    `;
export const CreateAdminUserDocument = gql`
    mutation createAdminUser($data: AdminUserCreateInputV2!) {
  createOneAdminUser(data: $data) {
    id
  }
}
    `;
export const CreateTopupPackageDocument = gql`
    mutation createTopupPackage($data: TopupPackageCreateInputV2!) {
  createTopupPackage(data: $data) {
    id
  }
}
    `;
export const GetDashboardEmbedUrlDocument = gql`
    query getDashboardEmbedUrl($id: String!) {
  getDashboardEmbedUrl(id: $id) {
    id
    iframeUrl
  }
}
    `;
export const RequestOtpDocument = gql`
    mutation RequestOtp($data: RequestOtpInput!) {
  requestOtp(data: $data) {
    id
    retryWaitSeconds
    consumer
  }
}
    `;
export const UpdateAdminUserDocument = gql`
    mutation updateAdminUser($data: AdminUserUpdateInputV2!, $where: AdminUserWhereUniqueInput!) {
  updateOneAdminUser(data: $data, where: $where) {
    id
  }
}
    `;
export const UpdateBookingSettingsDocument = gql`
    mutation updateBookingSettings($data: BookingSettingsInput!) {
  updateBookingSettings(data: $data) {
    id
    group
    key
    value
    description
    createdAt
    updatedAt
  }
}
    `;
export const UpdateFeeSettingsDocument = gql`
    mutation updateFeeSettings($data: FeeSettingsInput!) {
  updateFeeSettings(data: $data) {
    id
    group
    key
    value
    description
    createdAt
    updatedAt
  }
}
    `;
export const UpdateTopupPackageDocument = gql`
    mutation updateTopupPackage($data: TopupPackageUpdateInputV2!, $where: TopupPackageWhereUniqueInput!) {
  updateTopupPackage(data: $data, where: $where) {
    id
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    AdminLogin(variables: AdminLoginMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AdminLoginMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AdminLoginMutation>(AdminLoginDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AdminLogin', 'mutation');
    },
    createAdminUser(variables: CreateAdminUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateAdminUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAdminUserMutation>(CreateAdminUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAdminUser', 'mutation');
    },
    createTopupPackage(variables: CreateTopupPackageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateTopupPackageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateTopupPackageMutation>(CreateTopupPackageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createTopupPackage', 'mutation');
    },
    getDashboardEmbedUrl(variables: GetDashboardEmbedUrlQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetDashboardEmbedUrlQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDashboardEmbedUrlQuery>(GetDashboardEmbedUrlDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getDashboardEmbedUrl', 'query');
    },
    RequestOtp(variables: RequestOtpMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RequestOtpMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RequestOtpMutation>(RequestOtpDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RequestOtp', 'mutation');
    },
    updateAdminUser(variables: UpdateAdminUserMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateAdminUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateAdminUserMutation>(UpdateAdminUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAdminUser', 'mutation');
    },
    updateBookingSettings(variables: UpdateBookingSettingsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateBookingSettingsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateBookingSettingsMutation>(UpdateBookingSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateBookingSettings', 'mutation');
    },
    updateFeeSettings(variables: UpdateFeeSettingsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateFeeSettingsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateFeeSettingsMutation>(UpdateFeeSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateFeeSettings', 'mutation');
    },
    updateTopupPackage(variables: UpdateTopupPackageMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateTopupPackageMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateTopupPackageMutation>(UpdateTopupPackageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateTopupPackage', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;