import { Content, LinkToDetails, createHelpers, createResource } from '@scalingworks/refine-react-admin';
import { BusinessApplication, BusinessApplicationStatus, CountryCode } from '~/api';
import { resourceNames } from './resource-names';
import { MdOutlineEventNote } from 'react-icons/md';
import { GrAttachment, GrLink } from 'react-icons/gr';
import { BusinessApplicationStatusTag } from '~/components/business-application-status-tag';
import { Card, DescList, formatDate, getFileInfo } from '@scalingworks/react-admin-ui';
import { BusinessApplicationActions } from './business-application-resource/business-application-actions';
import { capitalCase } from 'change-case';
import { COUNTRY_CODES_TO_NAMES } from '~/utils/constant';

const { defineFields, defineShowPage } = createHelpers<BusinessApplication>({
	resourceName: resourceNames.businessApplication,
});

export const businessApplicationResource = createResource<BusinessApplication>({
  name: resourceNames.businessApplication,
  label: 'Business Applications',
  icon: <MdOutlineEventNote />,
  columns: ({ LinkToDetails }) => [
    {
      id: 'serviceName',
      accessorKey: 'service.name',
      header: 'Service name',
      cell: (data) => {
        const name = data.cell.getValue<string>();
        const providerName = data.row.original.serviceProvider.fullName;

        return (
          <LinkToDetails resourceId={data.row.original.id}>
            <span className="font-semibold">
              {name} - {data.row.original.mainFacet.name}
            </span>
            <br />
            <span className="text-gray-500">by {providerName}</span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'createdAt',
      accessorKey: 'createdAt',
      header: 'Created date',
      cell: (data) => {
        const dateValue = data.cell.getValue<string>();

        return dateValue && formatDate(dateValue);
      },
    },
    {
      id: 'country',
      accessorFn: (row) => row.countryCode,
      header: 'Country',
      cell: (data) => {
        const countryCode = data.cell.getValue<CountryCode>();
        return COUNTRY_CODES_TO_NAMES[countryCode];
      },
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: 'Status',
      cell: (data) => (
        <BusinessApplicationStatusTag status={data.cell.getValue<BusinessApplicationStatus>()} />
      ),
    },
  ],
  fields: defineFields([
    'id',
    'createdAt',
    'updatedAt',
    'status',
    'countryCode',
    {
      mainFacet: ['name'],
    },
    {
      service: ['name', 'professionName'],
    },
    {
      serviceProvider: ['fullName'],
    },
    {
      supportingDocument: ['urls', 'attachments'],
    },
    {
      workspace: ['facilities', 'attachments'],
    },
    {
      workspaceOwner: ['name', 'email', 'mobileNumber', 'identityNumber'],
    },
    {
      workspaceBusinessProfile: ['entityName', 'entityNumber', 'workspaceName', 'address'],
    },
    {
      workspaceCapacity: ['estimatedAudiences', 'estimatedPerformers'],
    },
  ]),
  allowCreate: false,
  defaultValues: {},
  list: {
    tabs: {
      options: [
        BusinessApplicationStatus.Pending,
        BusinessApplicationStatus.Approved,
        BusinessApplicationStatus.Cancelled,
      ].map((status) => ({
        label: status,
        filterValue: {
          field: 'status',
          value: status,
          operator: 'eq',
        },
      })),
    },
  },
  show: defineShowPage({
    title: (businessApplication) => (
      <>
        <div className="text-2xl">
          {businessApplication.id} ({COUNTRY_CODES_TO_NAMES[businessApplication.countryCode]})
        </div>
        <div className="text-gray-500">by {businessApplication.serviceProvider.fullName} </div>
        <LinkToDetails
          className="underline text-orange-600"
          resourceName={resourceNames.serviceProvider}
          resourceId={businessApplication.serviceProvider.id}
        >
          View profile
        </LinkToDetails>
      </>
    ),
    fields: defineFields([
      'id',
      'status',
      'createdAt',
      'updatedAt',
      'countryCode',
      {
        serviceProvider: ['id', 'fullName'],
      },
      {
        facets: ['name', 'formattedValueName'],
      },
      {
        mainFacet: ['name'],
      },
      { supportingDocument: ['urls', 'attachments'] },
      { service: ['name', 'professionName'] },
      {
        workspace: ['facilities', 'attachments'],
      },
      {
        workspaceOwner: ['name', 'email', 'mobileNumber', 'identityNumber'],
      },
      {
        workspaceBusinessProfile: ['entityName', 'entityNumber', 'workspaceName', 'address'],
      },
      {
        workspaceCapacity: ['estimatedAudiences', 'estimatedPerformers'],
      },
    ]),
    component: function BusinessApplicationShowPage({ queryResult, resourceId, title }) {
      const applicationData = queryResult.data && queryResult.data.data;
      const { attachments = [], urls = [] } = applicationData?.supportingDocument ?? {};
      const getStatusText = (status: BusinessApplicationStatus) => {
        switch (status) {
          case BusinessApplicationStatus.Approved:
          case BusinessApplicationStatus.Cancelled:
          case BusinessApplicationStatus.Rejected:
            return status;
          case BusinessApplicationStatus.Pending:
            return 'Submitted';
          case BusinessApplicationStatus.RequiresAction:
            return 'Requires action';
        }
      };

      return (
        <Content
          title={
            applicationData && (
              <div className="flex flex-col md:flex-row md:items-center gap-3">
                <h1>{title}</h1>
              </div>
            )
          }
          isLoading={queryResult.isLoading}
        >
          <div className="flex flex-col gap-3 xl:flex-row xl:items-start xl:gap-6">
            <section className="flex flex-col xl:flex-1 gap-3">
              <Card className="flex-1">
                <Card.Header bordered className="text-xl font-bold">
                  {applicationData?.service?.professionName}
                </Card.Header>
                <Card.Body>
                  <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                    <span className="text-sm text-gray-500">Instruments</span>
                    <DescList.Item
                      label={applicationData?.mainFacet.name}
                      value={<BusinessApplicationStatusTag status={applicationData?.status!} />}
                    />
                  </DescList>
                </Card.Body>
              </Card>
            </section>
            <section className="flex flex-col xl:flex-col xl:w-2/3 gap-3">
              <Card className="flex-1">
                <Card.Header bordered className="text-xl font-bold">
                  {applicationData?.mainFacet.name}
                </Card.Header>
                <Card.Body>
                  <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                    <DescList.Item
                      label="Status"
                      value={
                        <div className="flex gap-2">
                          <BusinessApplicationStatusTag status={applicationData?.status!} />
                        </div>
                      }
                    />

                    {applicationData?.facets?.map((facet, index) => (
                      <dl key={`facets-${index}`} className="grid gap-3">
                        <div className="grid grid-cols-2 justify-between items-center py-1 hover:bg-gray-50">
                          <dt className="block text-gray-500 text-sm">{facet.name}</dt>
                          <dd className="block break-words text-black text-right">
                            {facet.formattedValueName}
                          </dd>
                        </div>
                      </dl>
                    ))}
                  </DescList>
                  {(applicationData?.workspaceOwner ||
                    applicationData?.workspaceCapacity ||
                    applicationData?.workspaceBusinessProfile) && (
                    <>
                      <hr className="my-4" />
                      <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                        {applicationData?.workspaceBusinessProfile &&
                          Object.entries(applicationData.workspaceBusinessProfile).map(
                            ([key, value]) => (
                              <DescList.Item label={capitalCase(key)} value={value} />
                            )
                          )}
                        {applicationData?.workspaceCapacity &&
                          Object.entries(applicationData.workspaceCapacity).map(([key, value]) => (
                            <DescList.Item label={capitalCase(key)} value={value} />
                          ))}

                        {applicationData?.workspace?.attachments &&
                          applicationData?.workspace?.attachments.length > 0 && (
                            <DescList.Item
                              label="Entity Media"
                              value={applicationData?.workspace?.attachments.map(
                                (attachment, index) => (
                                  <span key={`attachment-index`}>
                                    <GrAttachment className="inline-block" />
                                    <a
                                      href={attachment}
                                      className="underline"
                                      target="_BLANK"
                                      rel="noreferrer"
                                    >
                                      {getFileInfo(attachment).fileName}
                                    </a>
                                    <br />
                                  </span>
                                )
                              )}
                            />
                          )}
                      </DescList>
                    </>
                  )}
                  <hr className="my-4" />

                  {attachments && (
                    <DescList itemLayout="row" isLoading={queryResult.isLoading}>
                      {attachments.length > 0 && (
                        <DescList.Item
                          label="Media"
                          value={attachments.map((attachment, index) => (
                            <span key={`attachment-index`}>
                              <GrAttachment className="inline-block" />
                              <a
                                href={attachment}
                                className="underline"
                                target="_BLANK"
                                rel="noreferrer"
                              >
                                {getFileInfo(attachment).fileName}
                              </a>
                              <br />
                            </span>
                          ))}
                        />
                      )}
                    </DescList>
                  )}

                  {urls && (
                    <DescList
                      itemLayout="row"
                      isLoading={queryResult.isLoading}
                      className="text-right"
                    >
                      {urls.length > 0 && (
                        <DescList.Item
                          label="Link"
                          value={urls.map((url, index) => (
                            <span key={`urls-${index}`}>
                              <GrLink className="inline-block" />
                              <a href={url} className="underline" target="_BLANK" rel="noreferrer">
                                {url}
                              </a>
                              <br />
                            </span>
                          ))}
                        />
                      )}
                    </DescList>
                  )}

                  {(attachments?.length ?? 0) > 0 ||
                    ((urls?.length ?? 0) > 0 && <hr className="my-4" />)}

                  {!queryResult.isLoading && applicationData && applicationData.status && (
                    <div className="block items-center xl:flex">
                      <span className="text-sm text-gray-500">
                        {`${getStatusText(applicationData.status).toString()} on 
                              ${formatDate(applicationData?.updatedAt, {
                                format: 'dd MMM yyyy, hh:mm a',
                              })}`}
                      </span>

                      {applicationData.status === BusinessApplicationStatus.Pending && (
                        <>
                          <div className="ml-auto mt-2">
                            <BusinessApplicationActions
                              applicationId={applicationData.id}
                              onSuccess={() => queryResult.refetch()}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </Card.Body>
              </Card>
            </section>
          </div>
        </Content>
      );
    },
  }),
  filterControls: {
    countryCode: {
      type: 'multiselect',
      config: {
        label: 'Country',
        options: Object.entries(CountryCode).map(([label, value]) => {
          const countryName = COUNTRY_CODES_TO_NAMES[value];
          return { label: countryName, value };
        }),
        placeholder: 'All countries',
      },
    },
  },
});