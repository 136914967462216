import { createHelpers, createResource } from '@scalingworks/refine-react-admin';
import { formatDate } from '@scalingworks/react-admin-ui';
import { MdAttachMoney } from 'react-icons/md';
import { PayoutStatus, type Payout, type Withdrawal, type WithdrawalStatus, WithdrawalType, CountryCode } from '~/api';
import { PayoutStatusTag } from '~/components/payout-status-tag';
import { WithdrawalStatusTag } from '~/components/withdrawal-status-tag';
import { resourceNames } from './resource-names';
import { PayoutRequestActions } from './payout-request-resource/payout-request-actions';
import { COUNTRY_CODES_TO_NAMES } from '~/utils/constant';

const { 
  defineFields, 
  defineShowPage, 
  defineStatsSection,
  defineCustomSection,
  defineRelatedResourceSection,
} = createHelpers<Payout>({
  resourceName: resourceNames.payoutRequests,
});

export const payoutRequetsResource = createResource<Payout>({
  name: resourceNames.payoutRequests,
  label: 'PayDay Transactions',
  icon: <MdAttachMoney />,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  columns: ({ LinkToDetails }) => [
    {
      id: 'date',
      accessorKey: 'date',
      header: 'Date',
      cell: (data) => {
        const dateValue = data.cell.getValue<string>();
        const date = dateValue && formatDate(dateValue);

        return (
          <LinkToDetails resourceId={data.row.original.id}>
            <span className="font-semibold">{date}</span>
          </LinkToDetails>
        );
      },
    },
    {
      id: 'formattedAmount',
      accessorKey: 'totalAmount.formattedAmount',
      header: 'Total Amount',
    },
    {
      id: 'country',
      accessorFn: (row) => row.countryCode,
      header: 'Country',
      cell: (data) => {
        const countryCode = data.cell.getValue<CountryCode>();
        return COUNTRY_CODES_TO_NAMES[countryCode];
      },
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: 'Status',
      cell: (data) => <PayoutStatusTag status={data.cell.getValue<PayoutStatus>()} />,
    },
  ],
  fields: defineFields([
    'id',
    'date',
    'status',
    'countryCode',
    {
      totalAmount: ['formattedAmount'],
    },
  ]),
  defaultValues: {},
  allowCreate: false,
  allowDelete: false,
  allowEdit: false,
  show: defineShowPage({
    sections: [
      defineStatsSection({
        fields: [{ totalAmount: ['formattedAmount'] }, 'date', 'status'],
        stats: [
          {
            label: 'Date',
            value: (data) => formatDate(data.date),
          },
          {
            label: 'Total Withdrawal',
            value: (data) => data.totalAmount.formattedAmount,
          },
          {
            label: 'Status',
            value: (data) => data.status,
          },
        ],
        allowChangeImage: false,
      }),
      defineRelatedResourceSection<Withdrawal>({
        relatedResourceName: resourceNames.withdrawalRequests,
        fields: [
          'id',
          'createdAt',
          'status',
          'countryCode',
          {
            amount: ['currencyCode', 'formattedAmount'],
          },
          {
            serviceProvider: ['fullName'],
          },
          {
            bankAccount: ['accountHolderName', 'bankName'],
          },
        ],
        getInitialFilter: ({ id }) => [
          {
            field: 'payoutId',
            operator: 'eq',
            value: id,
          },
          {
            field: 'type',
            operator: 'eq',
            value: WithdrawalType.Payout,
          },
        ],
        columns: ({ LinkToDetails }) => [
          {
            id: 'accountHolderName',
            accessorKey: 'bankAccount.accountHolderName',
            header: 'Account',
            cell: (data) => {
              const name = data.cell.getValue<string>();

              return (
                <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
                  {name}
                </LinkToDetails>
              );
            },
          },
          {
            id: 'fullName',
            accessorKey: 'serviceProvider.fullName',
            header: 'Service Provider',
          },
          {
            id: 'bankName',
            accessorKey: 'bankAccount.bankName',
            header: 'Bank',
          },
          {
            id: 'createdAt',
            accessorKey: 'createdAt',
            header: 'Created date',
            cell: (data) => {
              const dateValue = data.cell.getValue<string>();
              return dateValue && formatDate(dateValue);
            },
          },
          {
            id: 'amount',
            accessorKey: 'amount.formattedAmount',
            header: 'Amount',
          },
          {
            id: 'country',
            accessorFn: (row) => row.countryCode,
            header: 'Country',
            cell: (data) => {
              const countryCode = data.cell.getValue<CountryCode>();
              return COUNTRY_CODES_TO_NAMES[countryCode];
            },
          },
          {
            id: 'status',
            accessorKey: 'status',
            header: 'Status',
            cell: (data) => <WithdrawalStatusTag status={data.cell.getValue<WithdrawalStatus>()} />,
          },
        ],
      }),
      defineCustomSection({
        fields: ['id', 'status'],
        render: ({ queryResult }) => {
          const payoutData = queryResult.data && queryResult.data.data;
          const isCompleted =
            payoutData?.status === PayoutStatus.Failed || payoutData?.status === PayoutStatus.Paid;

          return (
            <div className="ml-auto">
              {!queryResult.isLoading && payoutData && !isCompleted && (
                <PayoutRequestActions
                  payoutId={queryResult.data?.data.id}
                  onSuccess={() => queryResult.refetch()}
                />
              )}
            </div>
          );
        },
      }),
    ],
  }),
  filterControls: {
    countryCode: {
      type: 'multiselect',
      config: {
        label: 'Country',
        options: Object.entries(CountryCode).map(([label, value]) => {
          const countryName = COUNTRY_CODES_TO_NAMES[value];
          return { label: countryName, value };
        }),
        placeholder: 'All countries',
      },
    },
  },
});
