import * as React from 'react';
import { Button, Card, TextField } from '@scalingworks/react-admin-ui';
import { useCustomMutation } from '@refinedev/core';

export const BusinessApplicationCancelForm = (props: { 
  applicationId: string; 
  onSuccess: () => void;
  onClose: () => void;
}) => {
  const [reasonText, setReasonText] = React.useState('');
  const mutation = useCustomMutation();

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();

        if (reasonText) {
          mutation.mutate(
            {
              method: 'post',
              url: '',
              values: {},
              metaData: {
                operation: 'rejectBusinessApplication',
                fields: ['id'],
                variables: {
                  data: {
                    type: 'AdminRejectBusinessApplicationInput',
                    required: true,
                    value: {
                      rejectReason: reasonText,
                    },
                  },
                  where: {
                    type: 'BusinessApplicationWhereUniqueInput!',
                    value: {
                      id: props.applicationId,
                    },
                  },
                },
              },
              successNotification: {
                message: 'Application rejected',
                type: 'success',
              },
            },
            {
							onSuccess: props.onSuccess,
						},
          )
        }
      }}
    >
      <Card>
        <Card.Header bordered className="text-xl font-bold">Reject application?</Card.Header>
        <Card.Body>
          <TextField label="Comment" value={reasonText} onValue={setReasonText} />
        </Card.Body>
        <Card.Footer className="text-right">
          <Button 
            className="mr-2 rounded inline-block text-sm text-red-500 border-red-500"
            type="button" 
            variant="default" 
            size="sm"
            onClick={props.onClose}
          >
            Close
          </Button>

          <Button 
            type="submit" 
            className="rounded inline-block text-sm border-orange-500"
            variant="solid" 
            size="sm" 
            disabled={!reasonText} 
            loading={mutation.isLoading}
          >
            Yes, reject
          </Button>
        </Card.Footer>
      </Card>
    </form>
  );
};
