import { AdminLoginDocument } from './api';
import logo from './assets/logo.svg';
import { LoginForm } from './modules/login/login-form';
import { DashboardPage } from './pages/dashboard';
import { QuestionairePage } from './pages/questionaire';
import { adminUserResource } from './resources/admin-user-resource';
import { businessApplicationResource } from './resources/business-application-resource';
import { customerResource } from './resources/customer-resource';
import { orderResource } from './resources/order-resource';
import { payoutRequetsResource } from './resources/payout-request-resource';
import { serviceProviderResource } from './resources/service-provider-resource';
import { systemSettingResource } from './resources/setting-resource';
import { topupPackageResource } from './resources/topup-package-resource';
import { withdrawalRequestsResource } from './resources/withdrawal-request-resource';
import routerProvider from '@refinedev/react-router-v6/legacy';
import {
  createRefineAdminApp,
  createResource,
  defineCustomPage,
  groupResources,
} from '@scalingworks/refine-react-admin';
import '@scalingworks/refine-react-admin/styles.css';
import { print } from 'graphql';
import ReactDOM from 'react-dom/client';
import { HiOutlineChartBar, HiOutlineCog, HiOutlineQuestionMarkCircle } from 'react-icons/hi';
import 'tailwindcss/tailwind.css';

const RefineApp = createRefineAdminApp({
  useUncheckedScalarInputs: true,
  loginMutation: print(AdminLoginDocument),
  graphqlUrl: `${import.meta.env.VITE_API_BASE_URL}/graphql`,
  sidebarHeader: (
    <div className="px-4 pb-3">
      <img src={logo} alt="Admin Starter" />
    </div>
  ),
  loginHeader: (
    <div className="flex flex-col items-center gap-3 pb-3">
      <img src={logo} className="invert opacity-80" />
      <h1 className="text-2xl text-center font-bold">Login to Admin Starter</h1>
    </div>
  ),
  design: {
    sidebarVariant: 'primary',
  },
  LoginPage: defineCustomPage({
    render: () => <LoginForm />,
  }),
  legacyRouterProvider: routerProvider,
  accessControlProvider: null,
  DashboardPage: defineCustomPage({
    title: 'Dashboard',
    render: () => <DashboardPage />,
    icon: <HiOutlineChartBar />,
  }),
  resources: [
    createResource({
      name: 'questionaire',
      label: 'Questionaires',
      icon: <HiOutlineQuestionMarkCircle />,
      list: defineCustomPage({
        title: 'Questionaires',
        render: () => <QuestionairePage />,
        icon: <HiOutlineChartBar />,
      }),
      columns:[] as any,
      fields:[] as any,
      defaultValues:{},
    }),
    orderResource,
    customerResource,
    serviceProviderResource,
    businessApplicationResource,
    payoutRequetsResource,
    withdrawalRequestsResource,
    ...groupResources({
      groupName: 'settings',
      icon: <HiOutlineCog />,
      label: 'Settings',
      resources: [topupPackageResource, systemSettingResource, adminUserResource],
    }),
  ],
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<RefineApp />);
