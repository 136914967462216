import { assertExists } from '@scalingworks/react-admin-ui';
import { createHelpers, createResource } from '@scalingworks/refine-react-admin';
import { FiUser } from 'react-icons/fi';
import { getSdk, Status, type AdminUser } from '~/api';

import { resourceNames } from './resource-names';

const { defineFields, defineCardSection, defineShowPage } = createHelpers<AdminUser>({
  resourceName: resourceNames.adminUser,
});

const defaultValues = {
  name: '',
  email: '',
  roleId: undefined,
};

export const adminUserResource = createResource({
  name: resourceNames.adminUser,
  label: 'Admin Users',
  icon: <FiUser />,
  fields: defineFields(['id', 'name', 'email', 'status', 'createdAt', 'updatedAt']),
  defaultValues,
  formatBeforeSubmit: (data) => ({
    ...data,
    status: Status.Active,
  }),
  dataProvider: {
    create: ({ client, variables }) =>
      getSdk(client)
        .createAdminUser({
          data: variables as Required<typeof defaultValues>,
        })
        .then((res) => ({
          data: res.createOneAdminUser,
        })),
    update: ({ client, variables, id }) =>
      getSdk(client)
        .updateAdminUser({
          data: variables,
          where: {
            id: id as string,
          },
        })
        .then((res) => {
          assertExists(res.updateOneAdminUser, 'value returned by updateOneAdminUser is undefined');

          return {
            data: res.updateOneAdminUser,
          };
        }),
  },
  controls: {
    components: ({ action }) => {
      return {
        email: {
          type: 'text',
          config: {
            label: 'Email',
            type: 'email',
          },
        },
      };
    },
    componentConfigDefaults: {
      required: true,
    },
  },
  defaultPageSize: 10,
  defaultSorter: [{ field: 'createdAt', order: 'desc' }],
  allowSearch: true,
  allowDelete: true,
  columns: ({ LinkToDetails, navigateToEdit }) => [
    {
      id: 'name',
      header: 'Name',
      accessorKey: 'name',
      cell: (data) => {
        const name = data.cell.getValue<string>();

        return (
          <LinkToDetails resourceId={data.row.original.id} className="font-semibold">
            {name}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'email',
      header: 'Email',
      accessorKey: 'email',
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      enableSorting: false,
    },
    {
      id: 'actions',
      header: () => <div className="text-right">Actions</div>,
      accessorKey: 'id',
      enableSorting: false,
      cell: (data) => {
        const userId = data.cell.getValue<string>();
        return (
          <span className="flex justify-end items-center gap-2">
            <button
              onClick={() => navigateToEdit({ id: userId })}
              className="text-primary-500 text-sm font-bold"
              type="button"
            >
              EDIT
            </button>
          </span>
        );
      },
    },
  ],
  show: defineShowPage({
    sections: [
      defineCardSection({
        title: 'General',
        fields: ['name', 'email', 'status'],
      }),
    ],
  }),
});
