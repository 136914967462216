import { Label, NumberInput } from '@scalingworks/react-admin-ui';
import { useController } from 'react-hook-form';

type NumberFieldInput = {
  label: string;
  name: string;
  control: any;
  suffix: string;
};

export const NumberFieldInput = ({ label, name, suffix, control }: NumberFieldInput) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, control, rules: { min: 0, required: 'Configuration required' } });

  return (
    <div className="flex flex-col md:items-center md:flex-row space-y-2 md:space-x-4">
      <Label className="w-full md:min-w-[250px] md:max-w-[250px]">{label}</Label>
      <NumberInput
        value={value}
        allowNegative={false}
        max={200}
        allowTrailingZero={false}
        decimalPlaces={1}
        onValue={onChange}
        suffix={suffix}
        className="md:max-w-[100px]"
      />
      {error?.message && <div className="text-red-500">{error.message}</div>}
    </div>
  );
};
