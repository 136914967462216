import { Tag, type TagProps } from '@scalingworks/react-admin-ui';

import type { BusinessApplicationStatus } from '~/api';

const colorByStatus: Record<BusinessApplicationStatus, TagProps['color']> = {
  Pending: 'amber',
  Approved: 'green',
  Cancelled: 'red',
  Rejected: 'red',
  RequiresAction: 'blue'
};

export const BusinessApplicationStatusTag = ({
	status,
	displayText = status,
}: {
	status: BusinessApplicationStatus;
	displayText?: string;
}) => <Tag color={colorByStatus[status]}>{displayText}</Tag>;
