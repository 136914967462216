export const resourceNames = {
  adminUser: 'admin-users',
  customer: 'customer',
  order: 'order',
  serviceProvider: 'service-provider',
  topupPackage: 'topup-package',
  systemSetting: 'systemSetting',
  businessApplication: 'businessApplication',
  transaction: 'transaction',
  withdrawalRequests: 'withdrawal',
  payoutRequests: 'payout',
} as const;
