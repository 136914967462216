import { createHelpers, createResource } from '@scalingworks/refine-react-admin';
import { Switch, assertExists, formatDate, formatNumber } from '@scalingworks/react-admin-ui';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import { Status, getSdk, type TopupPackage, Money, CountryCode } from '~/api';
import { resourceNames } from './resource-names';
import { COUNTRY_CODES_TO_NAMES } from '~/utils/constant';

const { 
	defineFields, 
	defineCardSection, 
	defineShowPage 
} = createHelpers<TopupPackage>({
  resourceName: resourceNames.topupPackage,
});

export const topupPackageResource = createResource({
  name: resourceNames.topupPackage,
  label: 'Pricings',
  icon: <RiMoneyDollarCircleLine />,
  fields: defineFields([
    'id',
    'status',
    'isRecommended',
    'updatedAt',
    'countryCode',
    {
      amount: ['formattedAmount', 'amount'],
    },
    {
      inAppAmount: ['formattedAmount', 'amount'],
    },
  ]),
  filterControls: {
    status: {
      type: 'multiselect',
      config: {
        label: 'Status',
        options: Object.entries(Status).map(([label, value]) => ({
          label,
          value,
        })),
        placeholder: 'All statuses',
      },
    },
    countryCode: {
      type: 'multiselect',
      config: {
        label: 'Country',
        options: Object.entries(CountryCode).map(([label, value]) => {
          const countryName = COUNTRY_CODES_TO_NAMES[value];
          return { label: countryName, value };
        }),
        placeholder: 'All countries',
      },
    },
  },
  defaultValues: {},
  allowCreate: true,
  allowEdit: false,
  allowDelete: true,
  allowSearch: false,
  defaultSorter: [{ field: 'inAppAmount', order: 'asc' }],
  columns: ({ useUpdate, LinkToDetails, navigateToEdit, invokeDelete }) => [
    {
      id: 'credit',
      accessorKey: 'inAppAmount.formattedAmount',
      header: 'Credit',
      enableSorting: false,
      cell: (data) => {
        return (
          <LinkToDetails resourceId={data.row.original.id}>
            {data.cell.getValue<string>()} {data.row.original.isRecommended ? '*' : ''}
          </LinkToDetails>
        );
      },
    },
    {
      id: 'updatedAt',
      accessorKey: 'updatedAt',
      header: 'Updated on',
      enableSorting: true,
      cell: (data) => {
        const dateValue = data.cell.getValue<string>();

        return (
          dateValue &&
          formatDate(dateValue, { formatType: 'dateAndTime', format: 'dd MMM yyyy, hh:mm a' })
        );
      },
    },
    {
      id: 'amount',
      accessorKey: 'amount.formattedAmount',
      header: 'Amount',
      enableSorting: false,
    },
    {
      id: 'country',
      accessorFn: (row) => row.countryCode,
      header: 'Country',
      cell: (data) => {
        const countryCode = data.cell.getValue<CountryCode>();
        return COUNTRY_CODES_TO_NAMES[countryCode];
      },
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      cell: (data) => {
        const status = data.cell.getValue<Status>();
        const { mutate, isLoading } = useUpdate();

        return (
          <Switch
            checked={status == Status.Active}
            onCheckedChange={(checked) => {
              mutate({
                changes: {
                  status: checked ? Status.Active : Status.Inactive,
                },
                id: data.row.original.id,
              });
            }}
            isLoading={isLoading}
          />
        );
      },
    },
    {
      id: 'actions',
      header: () => <div className="text-right">Actions</div>,
      accessorKey: 'id',
      enableSorting: false,
      cell: (data) => {
        const id = data.cell.getValue<string>();
        return (
          <span className="flex justify-end items-center gap-2">
            <button
              onClick={() => invokeDelete({ id })}
              className="text-red-500 text-sm font-bold"
              type="button"
            >
              DELETE
            </button>
          </span>
        );
      },
    },
  ],
  formatBeforeSubmit: (data) => ({
    ...data,
    inAppAmount: data.inAppAmount?.amount || 0,
    amount: data.amount?.amount || 0,
  }),
  dataProvider: {
    create: ({ client, variables }) =>
      getSdk(client)
        .createTopupPackage({ data: variables })
        .then((res) => ({
          data: res.createTopupPackage,
        })),
    update: ({ client, variables, id }) =>
      getSdk(client)
        .updateTopupPackage({
          data: variables,
          where: {
            id: id as string,
          },
        })
        .then((res) => {
          assertExists(res.updateTopupPackage);

          return {
            data: res.updateTopupPackage,
          };
        }),
  },
  controls: {
    components: ({ action }) => {
      return {
        'inAppAmount.amount': {
          type: 'number',
          config: {
            label: 'Credit',
          },
        },
        'amount.amount': {
          type: 'number',
          config: {
            label: 'Amount',
          },
        },
				countryCode: {
					type: 'select',
					config: {
						label: 'Country',
						options: Object.entries(CountryCode).map(([label, value]) => {
							const countryName = COUNTRY_CODES_TO_NAMES[value];
							return { label: countryName, value };
						}),
					},
				},
        isRecommended: {
          type: 'switch',
          config: {
            label: 'Best Value',
            defaultChecked: false,
            required: false,
          },
        },
      };
    },
    componentConfigDefaults: {
      required: true,
    },
  },
  show: defineShowPage({
    title: (data) => data.inAppAmount?.formattedAmount,
    sections: [
      defineCardSection({
        title: 'Details',
        fields: [
          {
            inAppAmount: ['formattedAmount'],
          },
          {
            amount: ['formattedAmount'],
          },
          'isRecommended',
          'countryCode',
          'status',
        ],
        displays: {
          'inAppAmount.formattedAmount': {
            label: 'Credit',
            type: 'text',
          },
          'amount.formattedAmount': {
            label: 'Amount',
            type: 'text',
          },
          isRecommended: {
            label: 'Best Value',
            render: (data) => (data.isRecommended ? 'Yes' : 'No'),
          },
          countryCode: {
            label: 'Country',
            render: (data) => COUNTRY_CODES_TO_NAMES[data.countryCode],
          },
          status: {
            label: 'Status',
            render: (data) => data.status,
          },
        },
      }),
    ],
  }),
});