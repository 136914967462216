import logo from '../../assets/logo-black.svg';
import { useLogin } from '@refinedev/core';
import { useForm } from '@refinedev/react-hook-form';
import { Button, TextInput, PinInput } from '@scalingworks/react-admin-ui';
import { useGraphQLClient } from '@scalingworks/refine-react-admin';
import { useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { getSdk, OtpPurpose } from '~/api';
import { isGraphqlError } from '~/utils/is-graphql-error';

export interface ILoginForm {
  username: string;
  otpCode: string;
}

export const LoginForm = () => {
  const client = useGraphQLClient();
  const [step, setStep] = useState(1);
  const [retrySeconds, setRetrySeconds] = useState(0);
  const [requestOtpLoading, setRequestOtpLoading] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);

  const { register, handleSubmit, formState, getValues, setError } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const {
    data,
    mutate: login,
    isLoading: loginLoading,
    error: loginError,
  } = useLogin<{ data: ILoginForm }>({
    mutationOptions: {
      onSuccess: (data) => {
        if (data?.success) {
          setIsOtpVerified(true);
          location.href = '/';
        }
      },
    },
  });

  const onResend = async () => {
    if (retrySeconds <= 0) {
      await requestOtpSubmit({ email: getValues('email'), resend: true });
    }
  };

  const onBack = () => {
    setRetrySeconds(0);
    setStep(1);
  };

  const loginSubmit = (pin: string) => {
    login({
      data: {
        username: getValues('email'),
        otpCode: pin,
      },
    });
  };

  const requestOtpSubmit = async (values: unknown) => {
    setRequestOtpLoading(true);
    if (
      values &&
      typeof values === 'object' &&
      'email' in values &&
      typeof values.email === 'string'
    ) {
      try {
        const result = await getSdk(client).RequestOtp({
          data: {
            purpose: OtpPurpose.Login,
            email: values.email,
            isResend:
              'resend' in values && typeof values.resend === 'boolean' ? values.resend : false,
          },
        });

        if (result.requestOtp.id) {
          setStep(2);
          setRetrySeconds(result.requestOtp.retryWaitSeconds);
        }
      } catch (error) {
        if (isGraphqlError(error)) {
          const message = error.response.errors[0]?.message;
          setError('email', { message });
        }
      } finally {
        setRequestOtpLoading(false);
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(
      () => setRetrySeconds((prev) => (prev > 0 ? prev - 1 : prev)),
      1000
    );

    return () => {
      clearInterval(intervalId);
    };
  }, [retrySeconds > 0]);

  const emailForm = (
    <form className="flex flex-col gap-y-6" onSubmit={handleSubmit(requestOtpSubmit)}>
      <div>
        <img src={logo} alt="Admin Starter" />
      </div>
      <div>
        <div className="text-xl font-bold text-left">Login to Cucumber admin</div>
        <div className="text-base font-light text-left text-gray-400">
          Enter your Cucumber email address to login
        </div>
      </div>
      <div>
        <label className="text-gray-400">Email</label>
        <TextInput
          type="email"
          placeholder="Enter Email Address"
          aria-errormessage={formState.errors.email?.message}
          {...register('email', { required: 'Email is required' })}
        />

        {formState.errors.email?.message && (
          <div className="text-red-300">{formState.errors.email?.message}</div>
        )}
      </div>
      <div>
        <Button
          type="submit"
          variant="solid"
          className="w-full"
          onClick={handleSubmit(requestOtpSubmit)}
          disabled={requestOtpLoading}
          loading={requestOtpLoading}
        >
          Continue
        </Button>
      </div>
    </form>
  );

  const otpForm = (
    <div className="flex flex-col gap-y-6">
      <div>
        <img src={logo} alt="Admin Starter" />
      </div>
      <div className="p-2 border-dotted border border-black w-min cursor-pointer" onClick={onBack}>
        <MdArrowBack className="" />
      </div>
      <div>
        <div className="text-xl font-bold text-left">OTP verification</div>
        <div className="text-base font-light text-left text-gray-400">
          Enter the 6 digit passcode sent to {getValues('email')}
        </div>
      </div>
      <div>
        <div className="flex justify-center">
          <PinInput
            complete={isOtpVerified}
            error={!!loginError?.message}
            onComplete={(pin) => loginSubmit(pin)}
          />
        </div>

        {data?.success === false && <div className="text-red-300">Invalid OTP</div>}
      </div>
      <div>
        <div
          onClick={onResend}
          className={`text-center ${retrySeconds <= 0 ? 'cursor-pointer' : ''}`}
        >
          Resend {retrySeconds > 0 ? `(${retrySeconds})` : ''}
        </div>
      </div>
      <div>
        <Button
          variant="solid"
          className="w-full"
          disabled={requestOtpLoading || loginLoading}
          loading={requestOtpLoading || loginLoading}
        >
          Continue
        </Button>
      </div>
    </div>
  );

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="w-full sm:w-10/12 md:w-6/12 lg:4/12 xl:w-4/12 2xl:w-3/12 2xl:max-w-[450px] shadow-lg rounded-lg bg-white p-10">
        {step === 1 && emailForm}
        {step === 2 && otpForm}
      </div>
    </div>
  );
};
